import { FC, useEffect, useRef, useState } from 'react';
import 'react-tabs/style/react-tabs.css'
import { useHistory } from 'react-router-dom'
import { WhiteListing } from './WhiteListing'
import { BlackListing } from './BlackListing'
import { Option } from '../../../components/MultiColumnSelect'
import styled from 'styled-components'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { ProductForReview } from './ProductsReview'
import { ButtonSecondary, CustomHeader } from '../../../components/CustomStyledComponents'
import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { categoryApis } from '../../../../setup/apis/category/categoryApis'
import { CategoryType } from '../../categories/CategoryList'
import { BrandType } from '../../brands/BrandList'

import { importProductsApis } from '../../../../setup/apis/product/importProductsApis'
import { FilterTypes, ListTypes, PropertyTypes } from '../../filters/importProductsFilters'
import { brandApis } from '../../../../setup/apis/brand/brandApis'
import { triggerAlert } from '../../../components/Alert'

type Props = {}

export type ProductFilters = {
  store: StoreType
  whiteList: WhiteList
  blackList: BlackList
  products: ProductForReview[]
}

export type WhiteList = {
  categories: Option[]
  brands: Option[]
  brandWords: Keyword[]
  categoryWords: Keyword[]
}

export type BlackList = {
  categories: Option[]
  brands: Option[]
  titleWords: Keyword[]
  titlePhrases: Keyword[]
  asins: Keyword[]
  brandWords: Keyword[]
  weightRange: Range
  volumeRange: Range
  priceRange: Range
}

export type Range = { from: number | ''; to: number | ''; updateDate?: Date; productCount?: number }

export type Keyword = {
  id: string
  title: string
  numberOfItems: number
  asin?: string
  updateDate: Date
  brand?: string
  category?: string
}

export const initialWhiteList = {
  categories: [],
  brands: [],
  brandWords: [],
  categoryWords: []
}

export const initialBlackList = {
  categories: [],
  brands: [],
  titleWords: [],
  titlePhrases: [],
  asins: [],
  brandWords: [],
  weightRange: { from: 0, to: 0, updateDate: new Date(), productCount: 0 },
  volumeRange: { from: 0, to: 0, updateDate: new Date(), productCount: 0 },
  priceRange: { from: 0, to: 0, updateDate: new Date(), productCount: 0 },
}

const WhiteBlackListing: FC<Props> = () => {
  const history = useHistory<ProductFilters>()
  const state = history.location.state
  const [whiteList, setWhiteList] = useState<WhiteList>(initialWhiteList)
  const [blackList, setBlackList] = useState<BlackList>(initialBlackList)
  const [optionscw, setOptionscw] = useState<Option[]>([])
  const [optionscwCur, setOptionscwCur] = useState<Option[]>([])
  const [optionscb, setOptionscb] = useState<Option[]>([])
  const [childOptionscw, setChildOptionscw] = useState<Option[]>([])
  const [childOptionscb, setChildOptionscb] = useState<Option[]>([])
  const [totalCategoryCount, setTotalCategoryCount] = useState<number>(0)
  const [optionsbw, setOptionsbw] = useState<Option[]>([])
  const [optionsbb, setOptionsbb] = useState<Option[]>([])
  const [tab, setTab] = useState<string>('white')
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [allFiltersLoading, setAllFiltersLoading] = useState<boolean>(false)
  const [searchTextcw, setSearchTextcw] = useState<string>('')
  const [searchTextcb, setSearchTextcb] = useState<string>('')
  const catTimerRef = useRef<any>()
  const rangeTimerRef = useRef<any>()
  const [totalCounts, setTotalCounts] = useState<{
    blackListCount: number
    resultCount: number
    whiteListCount: number
  }>({ blackListCount: 0, resultCount: 0, whiteListCount: 0 })

  useEffect(() => {
    getCategories(0)
    getStoreFilters(state?.store?.storeId)
  }, [])

  function _manageSearcText(value: string, listType: string) {
    switch (listType) {
      case ListTypes.WhiteList:
        setSearchTextcw(value)
        return
      case ListTypes.BlackList:
        setSearchTextcb(value)
        return
    }
  }

  async function getTotalCounts() {
    const response = await importProductsApis.getTotalCounts(state?.store?.storeId)
    setTotalCounts((prev) => response)
  }

  async function getStoreFilters(storeId: number) {
    if (
      state?.whiteList?.categories?.length ||
      state?.blackList?.categories?.length ||
      state?.blackList?.asins?.length ||
      state?.blackList?.titleWords?.length ||
      state?.blackList?.titlePhrases?.length
    ) {
      setWhiteList({
        ...state?.whiteList,
        categories: state?.whiteList?.categories || [],
        brands: state?.whiteList?.brands || [],
        categoryWords: state?.whiteList?.categoryWords || [],
        brandWords:  state?.whiteList?.brandWords || []
      })

      // getTotalCounts()
      return
    }

    try {
      setAllFiltersLoading(true)
      const response = await importProductsApis.getStoreFilterSettings(storeId, 'brand')

      setWhiteList((prev) => ({
        ...prev,
        brandWords: [...prev.brandWords, ...response.brandWhitelist.map((x: string) => ({brand: x, title: x}))],
        categoryWords: [...prev.categoryWords, ...response.catagoryNameWhitelist.map((x: string) => ({category: x, title: x}))]
      }))

      setBlackList((prev) => ({
        ...prev,
        titleWords: [...prev.titleWords, ...response.titleWordBlacklist.map((x: string) => ({title: x}))],
        titlePhrases: [...prev.titlePhrases, ...response.titlePhraseBlacklist.map((x: string) => ({title: x}))],
        asins: [...prev.asins, ...response.asinBlacklist.map((x: string) => ({title: x, asin: x}))],
        brandWords: [...prev.brandWords, ...response.brandBlacklist.map((x: string) => ({brand: x, title: x}))],
      }))

    } catch (er) {
      console.log('error', er)
    } finally {
      setAllFiltersLoading(false)
      getTotalCounts()
    }
  }

  async function getFilters(storeId: number) {
    if (
        state?.whiteList?.categories?.length ||
        state?.blackList?.categories?.length ||
        state?.blackList?.asins?.length ||
        state?.blackList?.titleWords?.length ||
        state?.blackList?.titlePhrases?.length
    ) {
      setWhiteList({
        ...state?.whiteList,
        categories: state?.whiteList?.categories || [],
        brands: state?.whiteList?.brands || [],
      })
      setBlackList({
        ...state?.blackList,
        categories: state?.blackList?.categories || [],
        brands: state?.blackList?.brands || [],
        weightRange: state?.blackList?.weightRange || initialBlackList.weightRange,
        volumeRange: state?.blackList?.volumeRange || initialBlackList.volumeRange,
        priceRange: state?.blackList?.priceRange || initialBlackList.priceRange,
      })
      getTotalCounts()
      return
    }
    try {
      setAllFiltersLoading(true)
      const response = await importProductsApis.getFilter(storeId)
      if (response?.categotyWhiteList?.length) {
        setWhiteList((prev) => ({
          ...prev,
          categories: response?.categotyWhiteList.map((item: any) => ({
            ...item,
            id: item.value,
            parentId: item?.parentCategoryId,
            parentName: item?.parentCategoryName,
            selected: false,
            title: item?.categoryName,
            category: item?.categoryName,
            amount: item?.productCount,
            visible: true,
            childCategoryCount: item?.childCategoryCount,
            updateDate: new Date(item?.updateDate),
          })),
        }))
      }
      if (response?.brandWhiteList?.length) {
        setWhiteList((prev) => ({
          ...prev,
          brands: response?.brandWhiteList.map((item: any) => ({
            ...item,
            id: item.value,
            selected: false,
            title: item?.value,
            category: item?.value,
            amount: item?.productCount,
            visible: true,
            childCategoryCount: 0,
          })),
        }))
      }
      if (response?.categotyBlackList?.length) {
        setBlackList((prev) => ({
          ...prev,
          categories: response?.categotyBlackList.map((item: any) => ({
            ...item,
            id: item.value,
            parentId: item?.parentCategoryId,
            parentName: item?.parentCategoryName,
            selected: false,
            title: item?.categoryName,
            category: item?.categoryName,
            amount: item?.productCount,
            visible: true,
            childCategoryCount: 0,
            updateDate: new Date(item?.updateDate),
          })),
        }))
      }
      if (response?.asinBlackList?.length) {
        setBlackList((prev) => ({
          ...prev,
          asins: response?.asinBlackList
              .map((item: any) => ({
                ...item,
                id: item?.value,
                title: item?.product?.title,
                numberOfItems: item?.productCount,
                asin: item?.value,
                updateDate: new Date(item?.updateDate),
              }))
              .filter((item: Keyword) => item.numberOfItems),
        }))
      }
      if (response?.titleWordBlackList?.length) {
        setBlackList((prev) => ({
          ...prev,
          titleWords: response?.titleWordBlackList.map((item: any) => ({
            ...item,
            id: item?.value,
            title: item?.value,
            numberOfItems: item?.productCount,
            asin: item?.value,
            updateDate: new Date(item?.updateDate),
          })),
        }))
      }
      if (response?.titlePhraseBlackList?.length) {
        setBlackList((prev) => ({
          ...prev,
          titlePhrases: response?.titlePhraseBlackList.map((item: any) => ({
            ...item,
            id: item?.value,
            title: item?.value,
            numberOfItems: item?.productCount,
            asin: item?.value,
            updateDate: new Date(item?.updateDate),
          })),
        }))
      }
      if (response?.price) {
        setBlackList((prev) => ({
          ...prev,
          priceRange: {
            ...response?.price,
            from: response?.price?.from || 0,
            to: response?.price?.to || 0,
          },
        }))
      }
      if (response?.volume) {
        setBlackList((prev) => ({
          ...prev,
          volumeRange: {
            ...response?.volume,
            from: response?.volume?.from || 0,
            to: response?.volume?.to || 0,
          },
        }))
      }
      if (response?.weight) {
        setBlackList((prev) => ({
          ...prev,
          weightRange: {
            ...response?.weight,
            from: response?.weight?.from || 0,
            to: response?.weight?.to || 0,
          },
        }))
      }
    } catch (er) {
      console.log('error', er)
    } finally {
      setAllFiltersLoading(false)
      getTotalCounts()
    }
  }

  useEffect(() => {
    setOptionscb((prev) =>
      whiteList?.categories?.length
        ? whiteList.categories.filter((item) => item.childCategoryCount)
        : []
    )
    // getBrands(whiteList?.categories?.map((item) => item?.id))
  }, [whiteList.categories])

  // useEffect(() => {
  //   if (blackList?.categories?.length) {
  //     getBrands(ListTypes.BlackList)
  //   }
  // }, [blackList.categories])

  async function getCategories(page: number, byScroll?: boolean) {
    setCategoryLoading((prev) => true)
    const response = await categoryApis.getAll({ page, pageSize: 50 })
    const cats = (response?.data || []).map((item: CategoryType) => ({
      ...item,
      title: [item.categoryName].join(' -> '),
      id: item.categoryId,
      parentId: item.parentCategoryId,
      parentName: item.parentCategoryName,
      amount: item.totalProductCount,
    })).sort((a: any, b: any) => {
      if(a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if(a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    }).filter((x: any) => {
      return x.parentCategoryName.toLowerCase() == '(base)'.toLowerCase()
    })

    setOptionscw((prev) => (byScroll ? [...prev, ...cats] : cats))
    setOptionscwCur((prev) => (byScroll ? [...prev, ...cats] : cats))
    setTotalCategoryCount(response?.totalCount)
    setCategoryLoading((prev) => false)
  }

  function _searchCategories(
    categoryName: string,
    listType: string,
    page: number,
    byScroll?: boolean
  ) {
    const delay = byScroll ? 0 : 1000
    if (catTimerRef.current) {
      clearTimeout(catTimerRef.current)
    }
    catTimerRef.current = setTimeout(async () => {
      if (listType === ListTypes.WhiteList) {
        if (!categoryName) {
          setOptionscwCur(optionscw)
          return
        }
        setCategoryLoading((prev) => true)
        // const response = await categoryApis.getAll({
        //   categoryName,
        //   page,
        //   pageSize: 50,
        //   storeId: state?.store?.storeId,
        //   ExcludeBlackListedCategories: true,
        // })
        // const cats = (response?.data || []).map((item: CategoryType) => ({
        //   ...item,
        //   title: item.categoryName,
        //   id: item.categoryId,
        //   parentId: item.parentCategoryId,
        //   parentName: item.parentCategoryName,
        //   amount: item.totalProductCount,
        // }))
        // setOptionscw((prev) => (byScroll ? [...prev, ...cats] : cats))
        // setTotalCategoryCount(response?.totalCount)

        setOptionscwCur(
            optionscw.filter((item: Option) =>
                item.title.toLowerCase().includes(categoryName.toLowerCase())
            )
        )
        setCategoryLoading((prev) => false)
      } else {
        setOptionscb(
          whiteList.categories.filter((item: Option) =>
            item.title.toLowerCase().includes(categoryName.toLowerCase())
          )
        )
      }
    }, delay)
  }

  async function getBrands(list: string[]) {
    const response = (
      await brandApis.getPaging({ pageNumber: 0, pageSize: 500, count: 0 }, '', list)
    )?.data?.map((item: BrandType) => ({
      title: item?.name,
      id: item?.name,
      parent: item?.categoryName,
      amount: item?.productCount,
      label: item?.name,
    })).sort((a: any, b: any) => {
      console.log(a, b)
      if(a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if(a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    });

    setOptionsbw(response?.data)
  }

  function nextStep() {
    history.push('/products/review', {
      store: state?.store || {},
      whiteList,
      blackList,
      products: state?.products,
    })
  }

  function prevStep() {
    history.push('/products/store-selection', {
      store: state?.store || {},
      whiteList,
      blackList,
      products: state?.products,
    })
  }

  async function _manageParentLists(option: Option, filterType: string, listType: string) {
    if (loading) return
    const requestData = {
      storeId: state?.store?.storeId,
      listType: listType,
      filterType: filterType,
      value: option.id,
    }
    let response: any
    try {
      setLoading(true)
      if (filterType === FilterTypes.Category) {
        if (listType === ListTypes.WhiteList) {
          const exists = whiteList.categories.findIndex((item) => item.id === option.id) !== -1
          if (exists) {
            response = await importProductsApis.removeStringFilter([requestData])
          } else {
            response = await importProductsApis.addStringFilter([requestData])
          }
          setWhiteList((prevData) => ({
            ...prevData,
            categories: exists
              ? prevData.categories.filter((item) => item.id !== option.id)
              : [...prevData.categories, { ...option, updateDate: response?.[0]?.updateDate }],
          }))
        } else if (listType === ListTypes.BlackList) {
          const exists = blackList.categories.findIndex((item) => item.id === option.id) !== -1
          if (exists) {
            response = await importProductsApis.removeStringFilter([requestData])
          } else {
            response = await importProductsApis.addStringFilter([requestData])
          }
          setBlackList((prevData) => ({
            ...prevData,
            categories: exists
              ? prevData.categories.filter((item) => item.id !== option.id)
              : [...prevData.categories, { ...option, updateDate: response?.[0]?.updateDate }],
          }))
        }
      } else if (filterType === FilterTypes.Brand) {
        if (listType === ListTypes.WhiteList) {
          const exists = whiteList.brands.findIndex((item) => item.id === option.id) !== -1
          if (exists) {
            response = await importProductsApis.removeStringFilter([requestData])
          } else {
            response = await importProductsApis.addStringFilter([requestData])
          }
          setWhiteList((prevData) => ({
            ...prevData,
            brands: exists
              ? prevData.brands.filter((item) => item.id !== option.id)
              : [...prevData.brands, { ...option, updateDate: response?.[0]?.updateDate }],
          }))
        } else if (listType === ListTypes.BlackList) {
          const exists = blackList.brands.findIndex((item) => item.id === option.id) !== -1
          if (exists) {
            response = await importProductsApis.removeStringFilter([requestData])
          } else {
            response = await importProductsApis.addStringFilter([requestData])
          }
          setBlackList((prevData) => ({
            ...prevData,
            brands: exists
              ? prevData.brands.filter((item) => item.id !== option.id)
              : [...prevData.brands, { ...option, updateDate: response?.[0]?.updateDate }],
          }))
        }
      }
      getTotalCounts()
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  async function _manageMultipleRows(options: Option[], listType: string) {
    if (loading) return
    const requestData = options.map((option) => ({
      storeId: state?.store?.storeId,
      listType: listType,
      filterType: FilterTypes.Category,
      value: option.id,
    }))
    let response: any
    try {
      setLoading(true)
      switch (listType) {
        case ListTypes.WhiteList:
          const allExist = options.every(
            (item) => whiteList.categories.findIndex((it) => it.id === item.id) !== -1
          )
          if (allExist) {
            response = await importProductsApis.removeStringFilter(requestData)
          } else {
            response = await importProductsApis.addStringFilter(requestData)
          }
          setWhiteList((prevData) => {
            let tempArr = prevData.categories
            if (allExist) {
              tempArr = options.reduce(
                (acc: Option[], item: Option) => acc.filter((it) => it.id !== item.id),
                tempArr
              )
            } else {
              tempArr = options.reduce(
                (acc: Option[], item: Option) =>
                  acc.findIndex((it) => it.id === item.id) !== -1
                    ? acc
                    : [...acc, { ...item, updateDate: response?.[0]?.updateDate }],
                tempArr
              )
            }
            return { ...prevData, categories: tempArr }
          })
          getTotalCounts()
          return
        case ListTypes.BlackList:
          const allExists = options.every(
            (item) => blackList.categories.findIndex((it) => it.id === item.id) !== -1
          )
          if (allExists) {
            response = await importProductsApis.removeStringFilter(requestData)
          } else {
            response = await importProductsApis.addStringFilter(requestData)
          }
          setBlackList((prevData) => {
            let tempArr = prevData.categories
            if (allExists) {
              tempArr = options.reduce(
                (acc: Option[], item: Option) => acc.filter((it) => it.id !== item.id),
                tempArr
              )
            } else {
              tempArr = options.reduce(
                (acc: Option[], item: Option) =>
                  acc.findIndex((it) => it.id === item.id) !== -1
                    ? acc
                    : [...acc, { ...item, updateDate: response?.[0]?.updateDate }],
                tempArr
              )
            }
            return { ...prevData, categories: tempArr }
          })
          getTotalCounts()
          return
      }
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }
  //This is only for black lists
  async function _manageParentKeywords(row: Keyword, filterType: string, remove?: boolean) {
    const requestData = {
      storeId: state?.store?.storeId || 1,
      listType: ListTypes.BlackList,
      filterType,
      value: filterType === FilterTypes.Asin ? row?.asin || '' : row?.title,
    }
    let rows: Keyword[] = [];
    if (!remove) {
      const response = (await importProductsApis.addStringFilter([requestData])) || { data: [] }
      let allItems = (response?.[0]?.value || '').split(/\r?\n|\r|\n/g); //All values to add.
      allItems.forEach((x: string) => rows.push({...row, title: x, asin: x, brand: x}))
      row = {
        ...row,
        numberOfItems: response?.[0]?.productCount || 0,
        updateDate: new Date(response?.[0]?.updateDate || ''),
      }
    } else {
      await importProductsApis.removeStringFilter([requestData])
    }
    switch (filterType) {
      case FilterTypes.TitleWord:
        setBlackList((prevData) =>
          remove
            ? {
              ...prevData,
              titleWords: prevData.titleWords.filter((item: Keyword) => item.title !== row.title),
            }
            : {
              ...prevData,
              titleWords: [...rows, ...(prevData?.titleWords || [])],
            }
        )
        return
      case FilterTypes.TitlePrase:
        setBlackList((prevData) =>
          remove
            ? {
              ...prevData,
              titlePhrases: prevData.titlePhrases.filter((item: Keyword) => item.title !== row.title),
            }
            : {
              ...prevData,
              titlePhrases: [...rows, ...(prevData?.titlePhrases || [])],
            }
        )
        return
      case FilterTypes.Asin:
        setBlackList((prevData) =>
          remove
            ? {
              ...prevData,
              asins: prevData.asins.filter((item: Keyword) => item.asin !== row.asin),
            }
            : { ...prevData, asins: [...rows, ...(prevData?.asins || [])] }
        )
        return
      case FilterTypes.Brand:
        setBlackList((prevData) =>
            remove
                ? {
                  ...prevData,
                  brandWords: prevData.brandWords.filter((item: Keyword) => item.brand !== row.brand),
                }
                : {
                  ...prevData,
                  brandWords: [...rows, ...(prevData?.brandWords || [])],
                }
        )
        return
    }
    getTotalCounts()
  }

  async function _manageParentWhitelists(row: Keyword, filterType: string, remove?: boolean) {
    const requestData = {
      storeId: state?.store?.storeId || 1,
      listType: ListTypes.WhiteList,
      filterType,
      value: filterType === FilterTypes.Asin ? row?.asin || '' : row?.title,
    }
    if (!remove) {
      const response = (await importProductsApis.addStringFilter([requestData])) || { data: [] }
      row = {
        ...row,
        numberOfItems: response?.[0]?.productCount || 0,
        updateDate: new Date(response?.[0]?.updateDate || ''),
      }
    } else {
      await importProductsApis.removeStringFilter([requestData])
    }
    switch (filterType) {
      case FilterTypes.Brand:
        setWhiteList((prevData) =>
            remove
                ? {
                  ...prevData,
                  brandWords: prevData.brandWords.filter((item: Keyword) => item.brand !== row.brand),
                }
                : {
                  ...prevData,
                  brandWords: [row, ...(prevData?.brandWords || [])],
                }
        )

        return
      case FilterTypes.Category:
        setWhiteList((prevData) =>
            remove
                ? {
                  ...prevData,
                  categoryWords: prevData.categoryWords.filter((item: Keyword) => item.category !== row.category),
                }
                : {
                  ...prevData,
                  categoryWords: [row, ...(prevData?.categoryWords || [])],
                }
        )
        return
    }
  }



  function _manageParentRanges(range: Range, propertyType: string) {
    if (rangeTimerRef.current) clearTimeout(rangeTimerRef.current)
    const requestData = {
      storeId: state?.store.storeId || 1,
      name: '',
      from: range?.from || 0,
      to: range?.to || 0,
      propertyType,
    }
    rangeTimerRef.current = setTimeout(async () => {
      const response = await importProductsApis.setRangeFilter(requestData)
      range = {
        ...range,
        updateDate: new Date(response?.updateDate),
        productCount: response?.productCount || 0,
      }
      getTotalCounts()
      switch (propertyType) {
        case PropertyTypes.Weight:
          setBlackList((prevData) => ({ ...prevData, weightRange: range }))
          return
        case PropertyTypes.Volume:
          setBlackList((prevData) => ({ ...prevData, volumeRange: range }))
          return
        case PropertyTypes.Price:
          setBlackList((prevData) => ({ ...prevData, priceRange: range }))
          return
      }
    }, 500)
  }

  async function _manageChildOptionsc(parentId: string, listType: string, option?: Option) {
    if (!parentId) {
      setChildOptionscw([])
      setChildOptionscb([])
      return
    }
    const response = await categoryApis.getAll({ parentIds: [parentId] })
    const cats = (response?.data || []).map((item: CategoryType) => ({
      ...item,
      title: item.categoryName,
      id: item.categoryId,
      parentId: item.parentCategoryId,
      parentName: item.parentCategoryName,
      amount: item.totalProductCount,
    }))
    if (listType === ListTypes.WhiteList) {
      setChildOptionscw((prev) => cats)
    } else {
      setChildOptionscb((prev) => cats)
    }
    if (!cats.length && option?.id) {
      _manageParentLists(option, FilterTypes.Category, listType)
    }
  }

  return (
    <Container className='card'>
      <div className='d-flex align-items-center justify-content-between'>
        <CustomHeader>Step 2: Whitelisting And Blacklisting</CustomHeader>
        {allFiltersLoading ? <div className='loading-info'>Filters are loading...</div> : null}
        <div className='button'>
          <ButtonSecondary className='me-5' onClick={prevStep} grow={0}>
            <FaChevronLeft /> {' Previous Step'}
          </ButtonSecondary>
          <ButtonSecondary
            onClick={nextStep}
            disabled={!whiteList.categories.length && !whiteList.brands.length}
          >
            Next Step <FaChevronRight />
          </ButtonSecondary>
        </div>
      </div>
      <hr className='m-0' />
      <div className='d-flex tab-container'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item  custom-nav' onClick={() => setTab('white')}>
            <span className={`nav-link text-active-primary me-6 ${tab === 'white' && 'active'}`}>
              Whitelisting
            </span>
          </li>
          <li className='nav-item custom-nav' onClick={() => setTab('black')}>
            <span className={`nav-link text-active-primary me-6 ${tab === 'black' && 'active'}`}>
              Blacklisting
            </span>
          </li>
        </ul>
      </div>
      <hr className='mt-0 mb-8' />
      <div className='info-container'>
        {tab === 'white' && (
          <WhiteListing
            optionsc={optionscwCur}
            childOptionsc={childOptionscw}
            manageChildOptionsc={_manageChildOptionsc}
            optionsb={optionsbw}
            manageParentLists={_manageParentLists}
            manageMultipleRows={_manageMultipleRows}
            manageParentWhitelists={_manageParentWhitelists}
            categories={whiteList.categories}
            brands={whiteList.brands}
            totalCount={totalCounts?.whiteListCount || 0}
            searchCategories={_searchCategories}
            totalCategoryCount={totalCategoryCount}
            categoryLoading={categoryLoading}
            planCount={state?.store.listings}
            searchText={searchTextcw}
            manageSearchText={_manageSearcText}
            brandWords={whiteList.brandWords}
            categoryWords={whiteList.categoryWords}
          />
        )}
        {tab === 'black' && (
          <BlackListing
            optionsc={optionscb}
            childOptionsc={childOptionscb}
            optionsb={optionsbb}
            manageChildOptionsc={_manageChildOptionsc}
            manageParentLists={_manageParentLists}
            manageMultipleRows={_manageMultipleRows}
            manageParentKeywords={_manageParentKeywords}
            manageParentRanges={_manageParentRanges}
            categories={blackList.categories}
            brands={blackList.brands}
            brandWords={blackList.brandWords}
            titleWords={blackList.titleWords}
            titlePhrases={blackList.titlePhrases}
            asins={blackList.asins}
            weightRange={blackList.weightRange}
            volumeRange={blackList.volumeRange}
            priceRange={blackList.priceRange}
            totalCount={totalCounts?.blackListCount || 0}
            searchCategories={_searchCategories}
            searchText={searchTextcb}
            manageSearchText={_manageSearcText}
          />
        )}
      </div>
    </Container>
  )
}

export { WhiteBlackListing }

const Container = styled.div`
  min-width: 1200px;
  overflow-x: auto;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);

  border-radius: 8px;

  .body-container {
    padding: 6px 24px;
  }

  .button {
    margin-inline: 24px;
  }

  .custom-nav {
    margin-top: 15px;
  }

  .tab-container {
    padding-inline: 24px;
  }

  .info-container {
    padding: 24px;
  }

  .loading-info {
    font-size: 18px;
    font-weight: 500;
  }
`
