import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

interface MunuProps {
  className?: string
  children: React.ReactNode
  'aria-labelledby'?: string
  style?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

const CustomMenu = React.forwardRef<HTMLDivElement, MunuProps>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <ul>{React.Children.toArray(children).filter((child) => child)}</ul>
      </div>
    )
  }
)

export default CustomMenu
