import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { PP } from './PrivacyPolicy'

const privacypolicyBreadCrumbs: Array<PageLink> = [
    {
        title: 'Privacy Policy',
        path: '/privacy-policy',
        isSeparator: false,
        isActive: false,
    },
]

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <Switch>
                <Route path='/privacy-policy'>
                    <PageTitle breadcrumbs={privacypolicyBreadCrumbs}>Privacy Policy</PageTitle>
                    <PP />
                </Route>
            </Switch>
        </>
    )
}

export default PrivacyPolicy
