import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { brandApis } from '../../../../setup/apis/brand/brandApis'
import { importProductsApis } from '../../../../setup/apis/product/importProductsApis'
import { PagingType } from '../../../../setup/types/request-data-types/RequestDataTypes'
import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import WhiteBlackListSelect from '../../../components/WhiteBlackListSelect'
import useWhiteBlackListSelect from '../../../hooks/useWhiteBlackListSelect'
import { getWhiteBlackListType } from '../../../utils/helperUtils'
import { FilterTypes } from '../../filters/importProductsFilters'
import { Pagination } from '../../../components/Pagination'
import View from '../../../components/View'

interface IProps {
  searchValue: string
  selectedStore: StoreType
}

export type BrandType = {
  name: string
  disqualifiedCount: number
  evaluationStatus: string
  executed: boolean
  itemCount: number
  qualifiedCount: number
  runId: number
  categoryName: string
  productCount?: number
}

const BrandsList = ({ searchValue, selectedStore }: IProps) => {
  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 500, count: 0 })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [whiteList, setWhiteList] = useState<string[]>([])
  const [blackList, setBlackList] = useState<string[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<BrandType[]>([])

  const { manageList } = useWhiteBlackListSelect(
    whiteList,
    blackList,
    selectedStore?.storeId,
    FilterTypes.Brand,
    setWhiteList,
    setBlackList
  )

  useEffect(() => {
    getBrands()
  }, [])

  useEffect(() => {
    if (selectedStore) getFilters(selectedStore.storeId)
  }, [selectedStore?.storeId])

  useEffect(() => {
    setPagingData((prev) => ({ ...prev, pageNumber: 0 }))

    getBrands(undefined, 0, searchValue)
  }, [searchValue])

  async function getBrands(pSize?: number, pNumber?: number, search?: string) {
    setLoading(true)
    try {
      const { data, totalCount }: any = await brandApis.getPaging(
        {
          ...pagingData,
          pageSize: pSize ?? pagingData.pageSize,
          pageNumber: pNumber ?? pagingData.pageNumber,
        },
        search ?? searchValue
      )
      setData(data || [])
      setTotalCount(totalCount)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  async function getFilters(storeId: number) {
    try {
      setIsLoading(true)
      const response = await importProductsApis.getStoreFilterSettings(storeId, 'brand')
      setWhiteList(response.brandWhitelist)
      setBlackList(response.brandBlacklist)
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  function handleSelect(eventKey: string | null, id: string, selectedValue: string) {
    if (eventKey !== selectedValue) {
      manageList(eventKey, id)
    }
  }

  function handlePageChange({ selected }: any) {
    setPagingData((prev) => ({ ...prev, pageNumber: selected }))
    getBrands(undefined, selected)
  }

  return (
    <Container>
      <div className='title'>Brand Name</div>

      <View isLoading={loading}>
        {data.map((item, i) => (
          <div className='body' key={i}>
            <WhiteBlackListSelect
              options={['allowed', 'blocked', 'default']}
              selectedValue={getWhiteBlackListType(item.name, whiteList, blackList)}
              id={item.name}
              handleSelect={handleSelect}
            />
            {item.name}
          </div>
        ))}

        <div className='px-8 py-5'>
          <Pagination
            currentPage={pagingData.pageNumber}
            pageSize={pagingData.pageSize}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={() => {}}
          />
        </div>
      </View>
    </Container>
  )
}

const Container = styled.div`
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 2rem;

  .body {
    padding: 9px 14px;
    background: #fff;
    border: 1px solid #edf2f9;
  }

  .title {
    color: #95aac9;
    background: #f9fbfd;
    border: 1px solid #edf2f9;
    padding: 12px 15px;
  }
`

export default BrandsList
