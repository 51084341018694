import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {useHistory, useParams} from 'react-router-dom'
import type { OrderType } from './OrderList'
import { OrderItemTable, OrderProductType } from './OrderItemTable'
import { OrderInfo } from './OrderInfo'
import { ButtonSecondary, CustomHeader } from '../../components/CustomStyledComponents'
import { FaChevronLeft } from 'react-icons/fa'
import { orderApis } from '../../../setup/apis/order/orderApis'

type Props = {}

const OrderDetails: React.FC<Props> = ({ ...props }) => {
  const history = useHistory<{ data: OrderType }>()
  const [data, setData] = useState<OrderType | null>(null)
  const [products, setProducts] = useState<OrderProductType[]>([])
  const params: {id: string} = useParams();

  useEffect(() => {
    getProducts()
  }, [])

  async function getProducts() {
    const response = await orderApis.getById(params.id)
    setData(response)

    const items = await orderApis.getItemsById(response.storeMicroOrderId);

    setProducts(items)
  }

  return (

      <Container className='card mb-5 mb-xl-10'>
        <div className='d-flex justify-content-between align-items-center'>
          <CustomHeader>Order Details</CustomHeader>
          <ButtonSecondary className='mx-8' onClick={() => history.push('/orders/order-list')}>
            <FaChevronLeft /> Back To Orders
          </ButtonSecondary>
        </div>
        <hr className='m-0' />
        {data && (<OrderInfo data={data} />)}
        <hr className='m-0' />
        <OrderItemTable data={products} header='Items' />
      </Container>
  )
}

export { OrderDetails }

const Container = styled.div`
  min-width: 1000px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
`
