import {FC, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {ButtonSecondary} from '../../../components/CustomStyledComponents'
import Select from '../../../components/Select'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {CountryType} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import {AccountType} from './AccountSettings'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {limitateValueLength, nameValidation, preventSpaces} from '../../../utils/inputFieldUtils'
import {accountApis} from '../../../../setup/apis/account/accountApis'
import {triggerAlert} from '../../../components/Alert'
import _ from 'lodash'

const accountSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols'),
  username: Yup.string()
    .trim()
    .required('Username is required')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols'),
  // countryId: Yup.string().trim().required('Country is required'),
  email: Yup.string()
    .trim()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  currentPassword: Yup.string().trim().required('Please fill out current password'),
  // .when('password', {
  //   is: (val: string) => (val && val.length > 0 ? true : false),
  //   then: Yup.string().trim().oneOf([Yup.ref('password')], 'Current password is not correct'),
  // })
  newPassword: Yup.string()
    .trim()
    .min(8, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('New password is required')
    .test('isValidPass', ' is not valid', (value: any) => {
      const hasUpperCase = /[A-Z]/.test(value)
      const hasLowerCase = /[a-z]/.test(value)
      const hasNumber = /[0-9]/.test(value)
      const hasSymbole = /[$&+,:;=?@#|'<>.^*()%!-!_]/.test(value)
      let validConditions = 0
      const numberOfMustBeValidConditions = 4
      const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole]
      conditions.forEach((condition) => (condition ? validConditions++ : null))
      if (validConditions >= numberOfMustBeValidConditions) {
        return true
      }
      return false
    }),
})

type Props = {
  manageEditMode: () => void
  data: AccountType
  country: CountryType
  manageCountry: (country: CountryType, values: AccountType) => void
  editPassword: boolean
  manageEditPassword: () => void
  saveChanges: (data: AccountType) => void
  countryOptions: CountryType[]
  closeEditMode: () => void
}

const AccountSettingsForm: FC<Props> = ({
  data,
  manageEditMode,
  country,
  manageCountry,
  editPassword,
  manageEditPassword,
  saveChanges,
  countryOptions,
  closeEditMode,
}) => {
  const [currentEye, setCurrentEye] = useState<boolean>(false)
  const [newEye, setNewEye] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const initDataRef = useRef<any>(null)
  const formik = useFormik({
    initialValues: JSON.parse(JSON.stringify(initDataRef.current)),
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {},
  })

  useEffect(() => {
    initDataRef.current = JSON.parse(JSON.stringify(data))
  }, [])

  function _updatePassword() {
    if (!editPassword) {
      manageEditPassword()
    }
  }

  async function _saveChanges() {
    try {
      const requestData = JSON.parse(JSON.stringify(formik?.values))
      if (!editPassword) {
        delete requestData.currentPassword
        delete requestData.newPassword
      } else {
        if (requestData.currentPassword === requestData.newPassword) {
          triggerAlert(
            'Create a new password. The new password is the same as the current one.',
            'error'
          )
          return
        }
      }
      setLoading(true)
      const response = await accountApis.updateUser(requestData)
      saveChanges({...formik?.values, currentPassword: '', newPassword: ''})
      closeEditMode()
      formik.values.currentPassword = ''
      formik.values.newPassword = ''
    } catch (err: any) {
      if (err?.response?.status === 400) {
        const res: any = Object.values(err?.response?.data?.errors || {})?.[0]
        const message = Array.isArray(res)
          ? res[0]
          : typeof res === 'string'
          ? res
          : res?.description || ''
        triggerAlert(`${message}`, 'error')
        return
      }
    } finally {
      setLoading(false)
    }
  }

  function _manageCountry(option: CountryType) {
    formik.values.countryId = option?.countryId
    formik.values.countryName = option?.label
    manageCountry(option, formik?.values)
  }

  function isValidForm() {
    if (_.isEqual(data, initDataRef.current) && _.isEqual(data, formik.values)) return false
    const values = Object.values(formik?.values || {})
    const errors = Object.values(formik?.errors || {})
    const keys = Object.keys(formik?.values || {})
    if (editPassword) {
      return (
        values.every((item: any) => item?.toString().length && item !== -1) &&
        errors.length === 0 &&
        keys.findIndex((key) => key === 'currentPassword') !== -1
      )
    } else {
      const cloneValues = JSON.parse(JSON.stringify(formik?.values || {}))
      const cloneErrors = JSON.parse(JSON.stringify(formik.errors))
      delete cloneValues?.currentPassword
      delete cloneValues?.newPassword
      delete cloneErrors?.currentPassword
      delete cloneErrors?.newPassword

      return (
        Object.values(cloneValues).every((item: any) => item?.toString().length && item !== -1) &&
        Object.values(cloneErrors).length === 0
      )
    }
  }

  return (
    <Container>
      <div className='form'>
        <div className='form-row'>
          <div className='form-item'>
            <label className='input-label'>First Name</label>
            <input
              type='text'
              placeholder='Insert your first name'
              autoComplete='off'
              onKeyDown={(e) => {
                preventSpaces(e)
                nameValidation(e)
              }}
              onPaste={(e) => {
                preventSpaces(e)
                nameValidation(e)
              }}
              {...formik.getFieldProps('firstName')}
              className={clsx(
                'input-value input-cap',
                {'input-invalid': formik.touched.firstName && formik.errors.firstName},
                {
                  'is-valid': formik.touched.firstName && !formik.errors.firstName,
                }
              )}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='form-item'>
            <label className='input-label'>Last Name</label>
            <input
              type='text'
              autoComplete='off'
              onKeyDown={(e) => {
                preventSpaces(e)
                nameValidation(e)
              }}
              onPaste={(e) => {
                preventSpaces(e)
                nameValidation(e)
              }}
              placeholder='Insert your last name'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'input-value input-cap',
                {'input-invalid': formik.touched.lastName && formik.errors.lastName},
                {
                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                }
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item'>
            <label className='input-label'>Email</label>
            <input
              type='email'
              autoComplete='off'
              disabled
              placeholder='Insert your email'
              {...formik.getFieldProps('email')}
              className={clsx('input-value text-muted')}
            />
          </div>
          <div className='form-item'>
            <label className='input-label'>Password</label>
            <div className='input-button'>
              <input
                type='password'
                autoComplete='off'
                readOnly
                {...formik.getFieldProps('password')}
                className={clsx('input-button-value')}
              />
              <ButtonSecondary
                className='inner-button'
                bordertl={0}
                borderbl={0}
                bordertr={8}
                borderbr={8}
                onClick={() => _updatePassword()}
              >
                Update Password
              </ButtonSecondary>
            </div>
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item-left'>
            <label className='input-label'>Username</label>
            <input
              type='username'
              autoComplete='off'
              disabled
              placeholder='Insert username'
              {...formik.getFieldProps('username')}
              className={clsx('input-value text-muted')}
            />
          </div>
          {/* <div className='form-item'>
            <label className='input-label'>Country</label>
            <Select
              options={countryOptions}
              value={country}
              onChange={(option) => _manageCountry(option)}
              withRs
              placeholder='Select country...'
            />
          </div> */}
        </div>
        {editPassword ? (
          <div>
            <div className='d-flex'>
              <div className='info-text'>
                <img
                  src={toAbsoluteUrl('/media/svg/misc/warning-info.svg')}
                  className='mb-1 me-3'
                />
                <span>To change the password, please fill out the current one</span>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-item-end'>
                <label className='input-label'>Current Password</label>
                <div className='input-wrapper'>
                  <input
                    type={currentEye ? 'text' : 'password'}
                    autoComplete='new-password'
                    onKeyDown={(e) => limitateValueLength(e, 20)}
                    {...formik.getFieldProps('currentPassword')}
                    className={clsx(
                      'input-value',

                      {
                        'is-valid':
                          formik.values?.currentPassword &&
                          formik.touched.currentPassword &&
                          !formik.errors.currentPassword,
                      }
                    )}
                  />
                  <div className='eye' onClick={() => setCurrentEye((prev) => !prev)}>
                    {formik.values?.currentPassword?.length ? (
                      <img
                        src={toAbsoluteUrl(`/media/svg/misc/eye${currentEye ? 'Slashed' : ''}.svg`)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='form-item-end'>
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block d-flex flex-column label-alert'>
                      <span role='alert'>
                        Your password should include at least 8 characters with small
                      </span>
                      <span role='alert'>
                        and capital letters, numbers, and special characters.
                      </span>
                    </div>
                  </div>
                ) : (
                  <label className='input-label'>New Password</label>
                )}
                <div className='input-button'>
                  <div className='w-100 input-wrapper'>
                    <input
                      type={newEye ? 'text' : 'password'}
                      autoComplete='new-password'
                      onKeyDown={(e) => limitateValueLength(e, 20)}
                      {...formik.getFieldProps('newPassword')}
                      className={clsx(
                        'input-button-value',
                        {
                          'input-invalid':
                            !formik.values?.newPassword ||
                            (formik.touched.newPassword && formik.errors.newPassword),
                        },
                        {
                          'is-valid':
                            formik.values?.newPassword &&
                            formik.touched.newPassword &&
                            !formik.errors.newPassword,
                        }
                      )}
                    />
                    <div className='new-eye' onClick={() => setNewEye((prev) => !prev)}>
                      {formik.values?.newPassword?.length ? (
                        <img
                          src={toAbsoluteUrl(`/media/svg/misc/eye${newEye ? 'Slashed' : ''}.svg`)}
                        />
                      ) : null}
                    </div>
                  </div>
                  {/* <ButtonSecondary
                      className='inner-button'
                      bordertl={0}
                      borderbl={0}
                      bordertr={8}
                      borderbr={8}
                      disabled={disableSave}
                      onClick={() => !disableSave && _saveNewPassword()}
                    >
                      Save New Password
                    </ButtonSecondary> */}
                </div>
              </div>
            </div>
            {formik.touched.currentPassword && formik.errors.currentPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.currentPassword}</span>
                </div>
              </div>
            )}
            {/* <div className='forgot-password'>
              <span>Forgot password? You can reset it by </span>
              <span className='forgot-password-link'>following this link</span>
            </div> */}
          </div>
        ) : null}
      </div>
      <hr className='m-0' />
      <div className='save-or-cancel'>
        <ButtonSecondary bgColor='#e4e6ef' className='me-5' color='#000000' onClick={closeEditMode}>
          Cancel
        </ButtonSecondary>
        <ButtonSecondary onClick={_saveChanges} disabled={!isValidForm()}>
          Save Changes
          {loading ? (
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          ) : null}
        </ButtonSecondary>
      </div>
    </Container>
  )
}

export {AccountSettingsForm}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  justify-content: start;

  .plan-info {
    display: flex;
    flex-direction: column;
  }

  .plan-label {
    font-size: 15px;
    font-weight: 700;
    color: #cccccc;
  }

  .plan-title {
    font-size: 24px;
    font-weight: 700;
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;
    width: 100%;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .form-item,
  .form-item-end,
  .form-item-single {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 60px;
    padding: 12px 0;
  }

  .form-item-end {
    align-self: flex-end;
    padding-bottom: 12px;
  }

  .form-item-single {
    width: 100%;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value,
  .input-button-value {
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    width: 100%;

    ::-ms-reveal {
      display: none;
    }
  }

  .input-value:focus,
  .input-button-value:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 3px #c0e7fd;
  }

  .input-button,
  .input-error {
    position: relative;
    display: flex;
    white-space: nowrap;
  }

  .inner-button {
    position: absolute;
    right: 1px;
    height: 95%;
    top: 50%;
    transform: translateY(-50%);
  }

  .input-error {
    flex-direction: column;
  }

  .input-wrapper {
    position: relative;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .verified {
    position: absolute;
    padding: 5px 15px;
    top: 8px;
    right: 10px;
    background-color: #50cd89;
    color: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  .eye,
  .new-eye {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
  }

  .new-eye {
    right: 13px;
  }

  .info-text {
    display: inline-block;
    border-radius: 8px;
    background-color: #fff4cc;
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    border: 1px solid #ffeeb3;
    white-space: nowrap;
  }

  .forgot-password {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #abadb8;
    white-space: nowrap;
  }

  .forgot-password-link {
    color: #009ef7;
    cursor: pointer;
  }

  .forgot-password-link:hover {
    color: #55bef9;
  }

  .label-alert {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #f1416c;
  }

  .input-cap {
    text-transform: capitalize;

    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder {
      text-transform: none;
    }
    ::-moz-placeholder {
      text-transform: none;
    }
    :-ms-input-placeholder {
      text-transform: none;
    }
    ::placeholder {
      text-transform: none;
    }
  }

  .save-or-cancel {
    padding: 24px 0 0 24px;
    display: flex;
  }
`
