import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListByTab } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import RemoveButton from '../../../components/RemoveButton'
import AddListModal from '../../../components/AddListModal'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import { TabTypes } from '../../../utils/types'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {Pagination} from "../../../components/Pagination";
import {PagingType} from "../../../../setup/types/request-data-types/RequestDataTypes";
import debounce from 'lodash/debounce';

const ASINS = () => {
  const storeOptions = useSelector(activeStoreSelector)

  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 500, count: 0 })
  const [show, setShow] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [tab, setTab] = useState<TabTypes>('black')
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const history = useHistory<any>()
  const params: { storeId: string } = useParams();

  const { isLoading, getAsins, asinsWhiteList, asinsBlackList, setAsinsWhiteList, setAsinsBlackList, paging } = useGetStoreFilterSettings<string>('asinWhitelist', 'asinBlacklist', selectedStore?.storeId)

  const { isLoading: addIsLoading, addFilter } = useAddFilter(selectedStore?.storeId, setShow, () => getAsins(pagingData, searchValue))

    useEffect(() => {
        if (storeOptions.length === 0) return;

        const paramsStoreId = parseInt(params.storeId);
        const storedStoreId = parseInt(sessionStorage.getItem('storeId') || '0');

        const selectedStoreId = paramsStoreId || storedStoreId || storeOptions[0]?.storeId;
        const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];

        setSelectedStore(selectedStore);
        sessionStorage.setItem('storeId', String(selectedStoreId));
        history.push(`/inventory/asins/${selectedStore.storeId}`);
    }, [storeOptions]);


    function handleStoreSelect(option: StoreType) {
        setSelectedStore(option);
        sessionStorage.setItem('storeId', String(option.storeId));
        history.push(`/inventory/asins/${option.storeId}`);
    }

    function handlePageChange({ selected }: any) {
        setPagingData((prev) => ({ ...prev, pageNumber: selected }))
    }

    useEffect(() => {
        let paramStoreId = parseInt(params.storeId);

        if (selectedStore?.storeId == paramStoreId) {
            getAsins(pagingData, searchValue)
        }
    }, [selectedStore?.storeId, pagingData])

    useEffect(() => {
        let paramStoreId = parseInt(params.storeId);

        if (selectedStore?.storeId == paramStoreId) {
            const debouncedSearch = debounce(() => getAsins(pagingData, searchValue), 300);
            debouncedSearch();

            return () => {
                debouncedSearch.cancel();
            };
        }
    }, [searchValue]);


  function handleSearchValueChange(value: string) {
    setSearchValue(value)
  }

  function search(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    handleSearchValueChange(value)
  }

  const tableData = useMemo(() => {
      // console.log(paging);
    const list = tab === 'white' ? asinsWhiteList : asinsBlackList

    return list
      ?.filter((el) => filterBySearchValue(el, searchValue))
      .map((item) => ({
        name: item,
        button: (
          <RemoveButton
            value={item}
            selectedId={selectedId}
            listType={ListByTab[tab]}
            filterType={FilterTypes.Asin}
            storeId={selectedStore?.storeId}
            setSelectedId={setSelectedId}
            setWhiteList={setAsinsWhiteList}
            setBlackList={setAsinsBlackList}
          />
        ),
      }))
  }, [tab, asinsBlackList, asinsWhiteList, searchValue, paging])

  return (
    <div style={{paddingBlockEnd: '100px'}}>
      <CustomHeader marginl='0'>ASIN List</CustomHeader>

      <div className='d-flex justify-content-end'>
        <StoreSelect
          selectedStore={selectedStore}
          handleSelect={(option: StoreType) => handleStoreSelect(option)}
        />

        <ButtonPrimary
          onClick={() => setShow(true)}
          bgColor='#037FC5'
          paddingx={22}
          paddingy={12}
          fontSize={13}
          marginl={12}
        >
          Add to list
        </ButtonPrimary>
      </div>

      <Tabs
        tabData={[
          { title: 'Blocked', value: 'black' },
        ]}
        tab={tab}
        setTab={setTab}
      />

      <SearchBar handleChange={search} page='ASINS' />

      <View isLoading={isLoading}>
        <InventoryTable tHeader={['Asins', '']} data={tableData} />
          <Pagination
              currentPage={pagingData.pageNumber}
              pageSize={pagingData.pageSize}
              totalCount={paging?.blackList?.count}
              onPageChange={handlePageChange}
              onPageSizeChange={() => {}}
          />
      </View>

      <AddListModal
        show={show}
        page='asin'
        setShow={setShow}
        addFilter={addFilter}
        isLoading={addIsLoading}
        listType={ListByTab[tab]}
        selectedStore={selectedStore}
        filterType={FilterTypes.Asin}
        handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
      />
    </div>
  )
}

export default ASINS
