import store from '../../setup/redux/Store'
import { deleteToken } from '../modules/redux-toolkit/userSlicer'

interface MyWindow extends Window {
  productFruits: any;
}

declare let window: MyWindow;

export const getUserId = (token: any) => {
  return parseJwt(token || '')?.sub
}

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const logout = () => {
  store.dispatch(deleteToken())
  localStorage.setItem('authStatus', AUTH_STATUS.loggedOut)
  window.productFruits.services.destroy()
}

export const getConfig = (token: string) => {
  return {
    headers: {
      'Access-Control-Allow-Origin': true,
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
}

export const deleteUserDataForReg = () => {
  sessionStorage.removeItem('userDetails')
  sessionStorage.removeItem('login_data')
}

export const METADATA_OIDC = {
  authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/authorize',
  token_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/token',
  userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/userinfo',
  end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/endsession',
  check_session_iframe: process.env.REACT_APP_AUTH_URL + '/connect/checksession',
  revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/revocation',
  introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/introspect',
}

export const AUTH_STATUS = {
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
}