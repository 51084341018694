import React, { useEffect, useState } from 'react'
import Input from '../../../components/Input'
import moment from 'moment'
import styled from 'styled-components'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import { DateTimePicker } from '../../../components/DateTimePicker'
import 'react-datepicker/dist/react-datepicker.css'
import MaskedInput from 'react-text-mask'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { storeSettingsApis } from '../../../../setup/apis/store/storeSettingsApis'
import { StorePropertiesType } from '../../../../setup/types/request-data-types/RequestDataTypes'
import { initialData } from '../../settings/account-settings/AccountSettings'
import { triggerAlert } from '../../../components/Alert'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { capitalize } from '../../../utils/inputFieldUtils'
import { SelectContainer } from './StoreSettings'
import Select from '../../../components/Select'
import {useSelector} from "react-redux";
import {activeStoreSelector} from "../../../modules/selectors/reduxSelectors";
import store from "../../../../setup/redux/Store";
import AdditionalSettingsSelect from "../../../components/AdditionalSettingsSelect";

const initialValues: StorePropertiesType = {
  repricerRuleName: '',
  fbmLeadTime: '',
  fbaLeadTime: '',
  addOzToQuote: 0,
  pauseLoweringListingPricingFrom: new Date(),
  pauseLoweringListingPricingUntil: new Date(new Date().getTime() + 60000),
  pauseLoweringListingPricing: false,
  pkgMaxHeight: '',
  pkgMaxWidth: '',
  pkgMaxLength: '',
  pkgMaxWeight: 0,
  priceAmountMin: 0,
  priceAmountMax: 0,
  listingQty: 0,
  currencyExchange: {
    currency: '',
    amount: 0,
    systemRate: 0,
    add: 0,
    finalRate: 0,
  }
}

type Props = {
  storeId: number
}

export type SelectOptions = {
  value: string
  label: string
}

const numberOfDaysOptions = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '3',
    value: '72',
  },
  {
    label: '5',
    value: '120',
  },
  {
    label: '10',
    value: '240',
  },
]

const AdditionalSettings: React.FC<Props> = ({ storeId }) => {
  const stores = useSelector(activeStoreSelector);
  const [data, setData] = useState<StorePropertiesType>(initialValues);
  const [storeData, setStoreData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<number>(0.0)


  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const notify = () =>
    toast('Date range is invalid', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })

  useEffect(() => {
    getData()
  }, [storeId])


  useEffect(() => {
    if (stores.length) {
        setStoreData(stores.find((item) => item.storeId === storeId))
    }
  });

  async function getData() {
    const response = await storeSettingsApis.getStoreProperties(storeId);

    setData(
      {
        ...response,
        repricerRuleName: response?.repricerRuleName || '',
        pauseLoweringListingPricingFrom:
          response?.pauseLoweringListingPricingFrom ||
          initialValues.pauseLoweringListingPricingFrom,
        pauseLoweringListingPricingUntil:
          response?.pauseLoweringListingPricingUntil ||
          initialValues.pauseLoweringListingPricingUntil,
      } || initialData,
    )

    console.log(response)

    setInputValue(response.currencyExchange?.add);
  }

  function _onSubmit(e: any) {
    e.preventDefault()
  }

  function _onChange({ target }: any) {
    setData((prev) => ({ ...prev, [target.name]: target.value }))
  }

  function _onChangeFBM(option: SelectOptions) {
    setData((prev) => ({ ...prev, fbmLeadTime: option.value }))
  }

  function _onChangeFBA(option: SelectOptions) {
    setData((prev) => ({ ...prev, fbaLeadTime: option.value }))
  }

  function _onChangeDate(date: any, name: keyof StorePropertiesType) {
    if (
      (name === 'pauseLoweringListingPricingUntil' &&
        new Date(data.pauseLoweringListingPricingFrom || new Date()) >= new Date(date)) ||
      (name === 'pauseLoweringListingPricingFrom' &&
        new Date(date) >= new Date(data.pauseLoweringListingPricingUntil || new Date()))
    ) {
      date = data?.[name]
    }
    setData((prev) => ({ ...prev, [name]: date }))
  }

  function _onChangeChecked() {
    setData((prev) => ({
      ...prev,
      pauseLoweringListingPricing: !prev.pauseLoweringListingPricing,
    }))
  }

  function calculatePercentageIncrease(number: number, percentage: number) {
    const actualNumber = Number(number);
    const actualPercentage = Number(percentage);

    let increasedNumber = actualNumber + ((actualNumber * actualPercentage) / 100);

    return Math.round(increasedNumber * 1000) / 1000;
  }

  async function saveChanges() {
    try {
      setLoading(true);
      const response = await storeSettingsApis.updateStoreProperties(storeId, {
        ...data,
        repricerRuleName: capitalize(data.repricerRuleName),
        pauseLoweringListingPricingFrom:
          data?.pauseLoweringListingPricingFrom || initialValues.pauseLoweringListingPricingFrom,
        pauseLoweringListingPricingUntil:
          data?.pauseLoweringListingPricingUntil || initialValues.pauseLoweringListingPricingUntil,
        currencyExchange: {
            currency: data.currencyExchange?.currency,
            amount: data.currencyExchange?.amount,
            systemRate: data.currencyExchange?.systemRate,
            add: inputValue,
            finalRate: calculatePercentageIncrease(data.currencyExchange?.systemRate, inputValue ? inputValue : 0),
        }
      })


      if (response?.status === 200) {
        triggerAlert('Changes saved successfully!')
      }
    } catch (e) {
      console.log('error', e)
      triggerAlert('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  function isInValidForm() {
    return Object.values(data).some((item) => typeof item !== 'boolean' && !item)
  }

  function isVisible(name: keyof StorePropertiesType) {
    return data?.[name]
  }

  function clearInput(name: keyof StorePropertiesType) {
    setData((prev) => ({ ...prev, [name]: '' }))
  }

  return (
    <Container className='card mb-5 mb-xl-10'>
      <CustomHeader>Additional Settings</CustomHeader>
      <div>
        <form onSubmit={_onSubmit} noValidate className='settings-container'>
          <div>
            <h2>Lead times</h2>
            <div className='d-flex justify-content-start'>
              <SelectContainer className='currency-select currency-select-additional-settings currency-select-additional-settings-dropdown'>
                <label className='form-label fw-bold'>FBM</label>
                <AdditionalSettingsSelect
                    name='layout-builder[layout][header][width]'
                    value={numberOfDaysOptions.find((x) => x.value == data.fbmLeadTime)}
                    onChange={(option) => _onChangeFBM(option)}
                    options={numberOfDaysOptions}
                    withRs
                    placeholder='Number of Days'
                />
              </SelectContainer>
              <SelectContainer className='currency-select currency-select-additional-settings currency-select-additional-settings-dropdown'>
                <label className='form-label fw-bold'>FBA</label>
                <AdditionalSettingsSelect
                    name='layout-builder[layout][header][width]'
                    value={numberOfDaysOptions.find((x) => x.value == data.fbaLeadTime)}
                    onChange={(option) => _onChangeFBA(option)}
                    options={numberOfDaysOptions}
                    withRs
                    placeholder='Number of Days'
                />
              </SelectContainer>
            </div>

            <div className='switch-header'>
              <h2 className='mt-5'>
                Limit Purchase Price Range
              </h2>
              {/*<div className='form-check form-check-solid form-switch fv-row switch'>*/}
              {/*  <Input*/}
              {/*      name='active'*/}
              {/*      className='form-check-input w-45px h-30px '*/}
              {/*      type='checkbox'*/}
              {/*      id='allowmarketing'*/}
              {/*      checked={data?.pauseLoweringListingPricing}*/}
              {/*      onChange={_onChangeChecked}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>

            <div className="d-flex justify-content-start">
              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Min </label>
                <Input
                    id={`priceAmountMin`}
                    type='number'
                    name='priceAmountMin'
                    value={data?.priceAmountMin}
                    placeholder='Package Max Height'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>

              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Max</label>
                <Input
                    id={`priceAmountMax`}
                    type='number'
                    name='priceAmountMax'
                    value={data?.priceAmountMax}
                    placeholder='Width (in inches)'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>
            </div>

            <h2 className='mt-5'>Limit Item Weight and Dimensions</h2>
            <div className="d-flex justify-content-start">
              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Height </label>
                <Input
                    id={`pkgMaxHeight`}
                    type='number'
                    name='pkgMaxHeight'
                    value={data?.pkgMaxHeight}
                    placeholder='Package Max Height'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>

              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Width</label>
                <Input
                    id={`pkgMaxWidth`}
                    type='number'
                    name='pkgMaxWidth'
                    value={data?.pkgMaxWidth}
                    placeholder='Width (in inches)'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>

              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Length</label>
                <Input
                    id={`pkgMaxLength`}
                    type='number'
                    name='pkgMaxLength'
                    value={data?.pkgMaxLength}
                    placeholder='Length (in inches)'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>

              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Weight</label>
                <Input
                    id={`pkgMaxWeight`}
                    type='number'
                    name='pkgMaxWeight'
                    value={data?.pkgMaxWeight}
                    placeholder='Weight (in ounces)'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>
            </div>

            <h2 className='mt-5'>Listing Defaults</h2>
            <div className="d-flex justify-content-start">
              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Ounces to Add to Quoted Weight</label>
                <MaskedInput
                    mask={[/\d/, /\d/, /\d/]}
                    guide={false}
                    id='addOzToQuote'
                    name='addOzToQuote'
                    type='text'
                    className='input-value'
                    placeholder='Oz'
                    value={data?.addOzToQuote}
                    onChange={_onChange}
                    min={0}
                ></MaskedInput>
              </SelectContainer>
              <SelectContainer className='currency-select currency-select-additional-settings'>
                <label className='form-label fw-bold'>Default Stock Quantity</label>
                <Input
                    id={`listingQty`}
                    type='number'
                    name='listingQty'
                    value={data?.listingQty}
                    placeholder='Listing (in inches)'
                    onChange={_onChange}
                    className='input-value'
                    min={0}
                />
              </SelectContainer>
            </div>


            <div className='currency-container'>
              <div className='info-label'>
                <span>Currency Exchange:</span>
              </div>
              <div className='currency-container-content'>
                <div className='currency-container-item'>
                  <span>Currency</span>
                  <span>USD</span>
                </div>
                <div className='currency-container-item'>
                  <span>Amount</span>
                  <span>{data.currencyExchange?.amount}</span>
                </div>
                <div className='currency-container-item'>
                  <span>System Rate</span>
                  <span>{data.currencyExchange?.systemRate}</span>
                </div>
                <div className='currency-container-item no-margin'>
                  <span>Add %</span>
                  <span>
                    <input
                      type='number'
                      className='input-value input-cap currency-exchange-add-input'
                      value={inputValue}
                      defaultValue={0}
                      onChange={handleInputChange}
                    />
                  </span>
                </div>
                <div className='currency-container-item no-margin'>
                  <span>=</span>
                </div>
                <div className='currency-container-item'>
                  <span>{data.currencyExchange?.currency}</span>
                  <span>{calculatePercentageIncrease(data.currencyExchange?.systemRate, inputValue ? inputValue : 0)}</span>
                </div>
              </div>
            </div>

            <div className='setting-container'>
              <div className='info-label'>
                <span>Pause lowering listing pricing</span>
              </div>
              <div className='input-container'>
                <div className='d-flex align-items-center custom-date-container'>
                  <DateTimePicker
                    className='input-value'
                    value={moment(
                      new Date(data?.pauseLoweringListingPricingFrom || new Date())
                    ).format('DD/MM/YYYY hh:mm A')}
                    selected={new Date(data?.pauseLoweringListingPricingFrom || new Date())}
                    onChange={(date) => _onChangeDate(date, 'pauseLoweringListingPricingFrom')}
                    showTimeSelect
                  />
                </div>
                <div className='d-flex align-items-center custom-date-container'>
                  <DateTimePicker
                    className='input-value'
                    value={moment(
                      new Date(data?.pauseLoweringListingPricingUntil || new Date())
                    ).format('DD/MM/YYYY hh:mm A')}
                    selected={new Date(data?.pauseLoweringListingPricingUntil || new Date())}
                    onChange={(date) => _onChangeDate(date, 'pauseLoweringListingPricingUntil')}
                    showTimeSelect
                  />
                </div>
                <div className='d-flex justify-content-end ms-2'>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <Input
                      name='active'
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id='allowmarketing'
                      checked={data?.pauseLoweringListingPricing}
                      onChange={_onChangeChecked}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr className='custom-hr' />
        <div className='apply-button'>
          <ButtonPrimary disabled={loading} onClick={saveChanges}>
            Apply changes
          </ButtonPrimary>
        </div>
      </div>
      <ToastContainer />
    </Container>
  )
}

export { AdditionalSettings }

const Container = styled.div`
  .custom-hr {
    margin: 0;
  }
  
  .switch-header {
    display: flex;
    flex-direction: row;
    
    .header {
      display: inline-block;
    }

    .switch {
      display: inline-block;
      margin-top: 14px;
      margin-left: 16px;
    }
  }

  .settings-container {
    display: flex;
    padding: 24px;
  }

  .apply-button {
    margin: 24px;
  }

  .info-label {
    font-size: 14px;
    font-weight: 600;
    min-width: 400px;
    margin-bottom: 4px;
  }

  .currency-container {
    padding: 30px 0px 0px 0px;

    .info-label {
      margin-bottom: 20px;
    }
  }

  .currency-container-content {
    display: flex;
  }

  .currency-container-item {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-inline-end: 30px;
    &.no-margin {
      margin-inline-end: 10px;
    }

    input {
      height: 45px;
      width: 170px;
    }

    span:last-child {
      font-weight: bold;
      margin-bottom: 10px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .setting-container {
    padding-block: 12px;
    width: 500px;
  }

  .setting-container {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 750px;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .custom-date-container {
    margin-right: 24px;
    width: 700px;
  }

  .input-value {
    border: 2px solid #e3e4ea;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
  }

  .input-value:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }

  .input-cap {
    text-transform: capitalize;

    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }

    :-moz-placeholder {
      text-transform: none;
    }

    ::-moz-placeholder {
      text-transform: none;
    }

    :-ms-input-placeholder {
      text-transform: none;
    }

    ::placeholder {
      text-transform: none;
    }
  }

  .currency-exchange-add-input {
    width:  80px !important;
    height: 30px !important;
    
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    [type=number] {
      -moz-appearance:textfield; /* Firefox */
    }
  }
`
