import React from 'react'
import { Switch, Route } from 'react-router-dom'

import CategoriesPage from './categories/CategoriesPage'
import Brands from './brands/Brands'

const Discover: React.FC = () => {
  return (
    <Switch>
      <Route path='/discover/categories/:storeId?' component={CategoriesPage} />
      <Route path='/discover/brands/:storeId?' component={Brands} />
    </Switch>
  )
}

export default Discover
