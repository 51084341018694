  import React, { FC, useEffect, useRef, useState } from 'react'
import { OrderTable } from './OrderTable'
import { Pagination } from '../../components/Pagination'
import Select from '../../components/Select'
import { SelectContainer } from '../stores/store-settings/StoreSettings'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { activeStoreSelector } from '../../modules/selectors/reduxSelectors'
import { useSelector } from 'react-redux'
import { StoreType } from '../../../setup/types/response-data-types/ResponseDataTypes'
import { orderApis } from '../../../setup/apis/order/orderApis'
import { PagingType } from '../../../setup/types/request-data-types/RequestDataTypes'
  import {ButtonPrimary, CustomHeader} from "../../components/CustomStyledComponents";
  import StoreSelect from "../../components/StoreSelect";

type Props = {}

export type OrderType = {
  buyerName: string | null
  createdOn: Date
  deleted: boolean
  externalSellerOrderIdentifier: string | null
  latestShipDate: string | null
  note: string | null
  orderExternalIdentifier: string
  orderStatus: keyof OrderStatusType
  orderTotal: number
  orderedOn: Date
  quantity: number
  shipToAddress1: string | null
  shipToAddress2: string | null
  shipToAddress3: string | null
  shipToCity: string | null
  shipToCountryCode: string | null
  shipToName: string | null
  shipToPostalCode: string | null
  shipToRegion: string | null
  shippedItemsCount: number
  shippingTotal: number
  storeId: number
  storeMicroOrderId: number
  storeSKUs: string[]
  unshippedItemsCount: number
  updatedOn: Date
}

export enum SortEnum {
  OrderedOn,
  OrderTotal,
  ShippingTotal,
  StoreMicroOrderId,
}

export type OrderStatusType = {
  Pending: string
  Shipped: string
  Unshipped: string
  Unknown: string
  PendingAvailability: string
  Canceled: string
  Unfulfillable: string
  PartiallyShipped: string
}

export type OrderFiltersType = {
  page: number | undefined
  pageSize: number | undefined
  sort: SortEnum | undefined
  orderIdFilter: number | undefined
  orderStatusFilters: (keyof OrderStatusType)[] | undefined
  orderedOnFromFilter: Date | undefined
  orderedOnToFilter: Date | undefined
  priceFromFilter: number | undefined
  priceToFilter: number | undefined
  quantityFromFilter: number | undefined
  quantityToFilter: number | undefined
  skuFilter: string | undefined
  shippingTotalFromFilter: number | undefined
  shippingTotalToFilter: number | undefined
  notesFilter: string | undefined
  storeIdFilter: number | undefined
}

const initialFilters = {
  page: 0,
  pageSize: 50,
  sort: 1,
  orderIdFilter: undefined,
  orderStatusFilters: undefined,
  orderedOnFromFilter: undefined,
  orderedOnToFilter: undefined,
  priceFromFilter: undefined,
  priceToFilter: undefined,
  quantityFromFilter: undefined,
  quantityToFilter: undefined,
  skuFilter: undefined,
  shippingTotalFromFilter: undefined,
  shippingTotalToFilter: undefined,
  notesFilter: undefined,
  storeIdFilter: undefined,
}

const OrderList: FC<Props> = () => {
  const storeOptions = useSelector(activeStoreSelector)
  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 50, count: 0 })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [data, setData] = useState<OrderType[]>([] as OrderType[])
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const [selectedRow, setSelectedRow] = useState<OrderType | null | undefined>()
  const [statusFilter, setStatusFilter] =
    useState<{ title: keyof OrderStatusType; selected: boolean }[]>(statusFilterData)
  const [filters, setFilters] = useState<OrderFiltersType>(initialFilters)
  const history = useHistory<{ data: OrderType | StoreType | null | undefined }>()
  const [loading, setLoading] = useState<boolean>(false)
  const stringFilterRef = useRef<any>(null)
  const numberFilterRef = useRef<any>(null)

  useEffect(() => {
    const { state } = history?.location;
    if (state?.data?.storeId && storeOptions.length) {
      setSelectedStore(
        storeOptions?.find((store) => store.storeId === state?.data?.storeId) ||
        storeOptions[0]
      )
      getOrders(
        { ...initialFilters, storeIdFilter: state.data.storeId },
        'storeIdFilter'
      )
    } else {
      if (storeOptions.length) {
        setSelectedStore(storeOptions[0])
        getOrders({ ...initialFilters, storeIdFilter: storeOptions[0]?.storeId }, 'storeIdFilter')
      }
    }
  }, [storeOptions])

  async function getOrders(filterData: OrderFiltersType, key?: string) {
    let requestData = {
      page: filterData.page ?? filters.page,
      pageSize: filterData.pageSize ?? filters.pageSize,
      sort: filterData.sort ?? filters.sort,
      orderIdFilter: key === 'orderIdFilter' ? filterData.orderIdFilter : filters.orderIdFilter,
      orderStatusFilters:
        key === 'orderStatusFilter' ? filterData.orderStatusFilters : filters.orderStatusFilters,
      orderedOnFromFilter:
        key === 'bothDates'
          ? filterData.orderedOnFromFilter
          : key === 'noDate'
            ? undefined
            : filters.orderedOnFromFilter,
      orderedOnToFilter:
        key === 'bothDates'
          ? filterData.orderedOnToFilter
          : key === 'noDate'
            ? undefined
            : filters.orderedOnToFilter,
      priceFromFilter:
        key === 'priceFromFilter' ? filterData.priceFromFilter : filters.priceFromFilter,
      priceToFilter: key === 'priceToFilter' ? filterData.priceToFilter : filters.priceToFilter,
      quantityFromFilter:
        key === 'quantityFromFilter' ? filterData.quantityFromFilter : filters.quantityFromFilter,
      quantityToFilter:
        key === 'quantityToFilter' ? filterData.quantityToFilter : filters.quantityToFilter,
      skuFilter: key === 'skuFilter' ? filterData.skuFilter : filters.skuFilter,
      shippingTotalFromFilter:
        key === 'shippingTotalFromFilter'
          ? filterData.shippingTotalFromFilter
          : filters.shippingTotalFromFilter,
      shippingTotalToFilter:
        key === 'shippingTotalToFilter'
          ? filterData.shippingTotalToFilter
          : filters.shippingTotalToFilter,
      notesFilter: key === 'notesFilter' ? filterData.notesFilter : filters.notesFilter,
      storeIdFilter: key === 'storeIdFilter' ? filterData.storeIdFilter : selectedStore?.storeId,
    }

    if (!requestData.orderedOnToFilter) {
      requestData.orderedOnFromFilter = undefined
    }

    try {
      setLoading(true)
      const {
        data = [],
        page,
        pageSize,
        totalCount = 0,
      } = (await orderApis.getPaging(requestData)) || {}
      setData(data)
      setTotalCount(totalCount)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function _onPageChange({ selected }: any) {
    setPagingData((prev) => ({ ...prev, pageNumber: selected + 1 }))
    getOrders({ ...filters, page: selected + 1 })
  }

  function goToSingleOrder(order: OrderType) {
    setSelectedRow(order)
    history.push(`/orders/order-details/${order?.orderExternalIdentifier}`)
  }

  async function _manageNotes(orderId: number, note: string) {
    await orderApis.updateNote(orderId, note)
    getOrders(filters)
  }

  function _manageStatusFilters(status: keyof OrderStatusType) {
    const statusValues = filters.orderStatusFilters?.includes(status)
      ? filters?.orderStatusFilters?.filter((item: keyof OrderStatusType) => item !== status)
      : [...(filters.orderStatusFilters || []), status]
    setFilters((prev: OrderFiltersType) => ({
      ...prev,
      orderStatusFilters: statusValues,
    }))
    setStatusFilter((prev) =>
      statusFilterData?.map((item) => ({ ...item, selected: statusValues.includes(item.title) }))
    )
    getOrders({ ...filters, orderStatusFilters: statusValues, page: 0 }, 'orderStatusFilter')
  }

  function _manageStringFilters(filterName: keyof OrderFiltersType, value: string) {
    setFilters((prev: OrderFiltersType) => ({
      ...prev,
      [filterName]: value || undefined,
    }))
    if (stringFilterRef.current) {
      clearTimeout(stringFilterRef.current)
    }
    stringFilterRef.current = setTimeout(() => {
      getOrders({ ...filters, [filterName]: value || undefined, page: 0 }, filterName)
    }, 1000)
  }

  function _manageNumberFilters(filterName: keyof OrderFiltersType, value: number) {
    setFilters((prev: OrderFiltersType) => ({
      ...prev,
      [filterName]: value === 0 ? undefined : value,
    }))
    if (numberFilterRef.current) {
      clearTimeout(numberFilterRef.current)
    }
    numberFilterRef.current = setTimeout(() => {
      getOrders({ ...filters, [filterName]: value === 0 ? undefined : value, page: 0 }, filterName)
    }, 1000)
  }

  function _manageDateFilters(dates: any) {
    const [start, end] = dates
    setFilters((prev: OrderFiltersType) => ({
      ...prev,
      orderedOnFromFilter: start,
      orderedOnToFilter: end,
    }))
    if (end) {
      getOrders(
        { ...filters, orderedOnFromFilter: start, orderedOnToFilter: end, page: 0 },
        'bothDates'
      )
    } else {
      getOrders(
        { ...filters, orderedOnFromFilter: undefined, orderedOnToFilter: undefined, page: 0 },
        'noDate'
      )
    }
  }

  function _onSelectStore(option: StoreType) {
    setSelectedStore(option)
    getOrders({ ...filters, storeIdFilter: option.storeId, page: 0 }, 'storeIdFilter')
  }

  function _renderTableData(data: OrderType[]) {
    return data
  }

  return (
    <Container>

      <div style={{paddingBlockEnd: '20px'}}>
          <CustomHeader marginl='0'>Orders List</CustomHeader>

          <div className='d-flex justify-content-end'>
            <StoreSelect
                selectedStore={selectedStore}
                handleSelect={(option: StoreType) => _onSelectStore(option)}
            />
          </div>
      </div>

      <div className='card mb-5 mb-xl-8 paging-table-wrapper'>
        <OrderTable
          data={_renderTableData(data)}
          filterData={filters}
          statusFilterData={statusFilter}
          manageStatusFilters={_manageStatusFilters}
          manageStringFilters={_manageStringFilters}
          manageNumberFilters={_manageNumberFilters}
          manageDateFilters={_manageDateFilters}
          manageNotes={_manageNotes}
          goToSingleOrder={goToSingleOrder}
          loading={loading}
        />
        <div className='paging-wrapper'>
          <Pagination
            currentPage={pagingData.pageNumber}
            pageSize={pagingData.pageSize}
            totalCount={totalCount}
            onPageChange={_onPageChange}
            onPageSizeChange={() => { }}
          />
        </div>
      </div>
    </Container>
  )
}

export default OrderList

const Container = styled.div`
  margin-bottom:48px;
  min-width: 1200px;
  .paging-table-wrapper {
    border-radius: 8px;
    box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  }

  .paging-wrapper {
    padding: 24px;
  }
`

export const OrderStatus: OrderStatusType = {
  Pending: 'Pending',
  Shipped: 'Shipped',
  Unshipped: 'Unshipped',
  Unknown: 'Unknown',
  PendingAvailability: 'PendingAvailability',
  Canceled: 'Canceled',
  Unfulfillable: 'Unfulfillable',
  PartiallyShipped: 'PartiallyShipped',
}

const statusFilterData: { title: keyof OrderStatusType; selected: boolean }[] = [
  {
    title: 'Shipped',
    selected: false,
  },
  {
    title: 'Unshipped',
    selected: false,
  },
  {
    title: 'Pending',
    selected: false,
  },
  {
    title: 'Unknown',
    selected: false,
  },
  {
    title: 'PendingAvailability',
    selected: false,
  },
  {
    title: 'Canceled',
    selected: false,
  },
  {
    title: 'Unfulfillable',
    selected: false,
  },
  {
    title: 'PartiallyShipped',
    selected: false,
  },
]
