import { FC } from 'react';
import styled from 'styled-components'
import Input from '../../../components/Input'
import { MarkupRow } from './PricingSettings'
type Props = {
  onChange: (e: any, which: keyof MarkupRow, index: number) => void
  markup: {
    cash: number | ''
    percent: number | ''
  }
  value: keyof MarkupRow
  index: number
  currencyOptions: Array<{ value: string; label: string }>
  inputClassName?: string
  selectClassName?: string
  disabled?: boolean
  cashOnly?: boolean
  percentOnly?: boolean
  hidePlus?: boolean
}

type PercentProps = {
  onChange: (e: any, which: keyof MarkupRow, index: number) => void
  markup: number | ''
  value: keyof MarkupRow
  index: number
  currencyOptions: Array<{ value: string; label: string }>
  inputClassName?: string
  selectClassName?: string
  disabled?: boolean
}

const PricingCell: FC<Props> = ({
  onChange,
  markup,
  value,
  index,
  currencyOptions,
  inputClassName,
  selectClassName,
  disabled,
  percentOnly = false,
  cashOnly = false,
  hidePlus = false,
  ...props
}) => {
  return (
    <Container>
    {percentOnly ? (<></>): (<>
          <Container className='d-flex justify-content-start input-group'>
            <Input
              onChange={(e) => onChange(e, value, index)}
              id={`cash${index}`}
              name='cash'
              type='number'
              value={markup.cash}
              placeholder='0.00'
              className='bordered-right form-control'
              min={0}
              disabled={disabled}
            />
          </Container>
        </>)}
    {!hidePlus && <span>+</span>}
    {cashOnly ? (<></>): <Container className='d-flex justify-content-start input-group'>
        <Input
          id={`percent${index}`}
          onChange={(e) => onChange(e, value, index)}
          name='percent'
          type='number'
          value={markup?.percent}
          placeholder='0.00'
          className={'form-control'}
          min={0}
          max={100}
          disabled={disabled}
        />
        <Input
          className={`sign form-control ${inputClassName}`}
          id={`percentsign${index}`}
          name='percent'
          type='text'
          value={'%'}
          disabled
        />
      </Container>
        }
    </Container>
  )
}

const PercentCell: FC<PercentProps> = ({
                                  onChange,
                                  markup,
                                  value,
                                  index,
                                  currencyOptions,
                                  inputClassName,
                                  selectClassName,
                                  disabled,
                                  ...props
                                }) => {
  return (
      <Container>
        <Container className='d-flex justify-content-start input-group'>
          <Input
              id={`percent${index}`}
              onChange={(e) => onChange(e, value, index)}
              name='percent'
              type='number'
              value={markup}
              placeholder='0.00'
              className={'form-control'}
              min={0}
              max={100}
              disabled={disabled}
          />
          <Input
              className={`sign form-control ${inputClassName}`}
              id={`percentsign${index}`}
              name='percent'
              type='text'
              value={'%'}
              disabled
          />
        </Container>
      </Container>
  )
}

export { PricingCell, PercentCell }

const Container = styled.div`
  display: flex;
  .sign {
    max-width: 50px;
    text-align: center;
  }

  input {
    font-size: 14px;
    padding: 3px;
  }

  span {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-inline: 5px;
  }
`
