import { FC, useState, useEffect } from 'react';
import { PricingTable } from './PricingTable'
import { ShippingTable } from './ShippingTable'
import { AdditionalInfo } from './AdditionalInfo'
import { CustomHeader } from '../../../components/CustomStyledComponents'
import styled from 'styled-components'
import { storeSettingsApis } from '../../../../setup/apis/store/storeSettingsApis'
import {
  PricingMarkupType,
  ShippingType,
  StorePropertiesType,
} from '../../../../setup/types/request-data-types/RequestDataTypes'
import { triggerAlert } from '../../../components/Alert'
type Props = {
  storeId: number
  changeTo: (tab: string) => void
  storeCurrency: string
}

const Review: FC<Props> = ({ storeId, changeTo, storeCurrency }) => {
  const [pricingMarkups, setPricingMarkups] = useState<PricingMarkupType>()
  const [shippingPricings, setShippingPricings] = useState<ShippingType>()
  const [additionalInfo, setAdditionalInfo] = useState<StorePropertiesType>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getSummaryReview()
  }, [storeId])

  async function getSummaryReview() {
    try {
      setLoading(true)
      const response = await storeSettingsApis.getSummaryReview(storeId)
      setPricingMarkups(response?.pricingMarkup)
      setShippingPricings(response?.shippingPricing)
      setAdditionalInfo(response?.additionalSettings)
    } catch (e) {
      triggerAlert('Something went wrong...', 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container className={`card mb-5 mb-xl-8`}>
      <CustomHeader>Summary Review</CustomHeader>
      {loading ? (
        <h2 className='blank-data h-300px'>Loading...</h2>
      ) : (
        <div>
          <PricingTable className='mb-5 mb-xl-8' data={pricingMarkups} changeTo={changeTo} storeCurrency={storeCurrency} />
          <ShippingTable className='mb-5 mb-xl-8' data={shippingPricings} changeTo={changeTo} storeCurrency={storeCurrency} />
          <AdditionalInfo data={additionalInfo} />
        </div>
      )}
    </Container>
  )
}

export { Review }

const Container = styled.div`
  min-height: 60vh;

  .blank-data {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
