import { Route, Switch } from 'react-router-dom'

import ASINS from './asins/ASINS'
import Brands from './brands/Brands'
import Categories from './categories/Categories'
import RestrictedPhrases from './restricted-phrases/RestrictedPhrases'
import RestrictedKeywords from './restricted-keywords/RestrictedKeywords'
import ProductList from '../products/ProductList'
import { PageTitle } from '../../../_metronic/layout/core'

const Inventory = () => {
  return (
    <Switch>
      <Route path='/inventory/brands/:storeId?' component={Brands} />
      <Route path='/inventory/categories/:storeId?' component={Categories} />
      <Route path='/inventory/asins/:storeId?' component={ASINS} />
      <Route path='/inventory/restricted-keywords/:storeId?' component={RestrictedKeywords} />
      <Route path='/inventory/restricted-phrases/:storeId?' component={RestrictedPhrases} />
      <Route path='/inventory/current-listing/:storeId?'>
        <PageTitle>Product List</PageTitle>
        <ProductList />
      </Route>
    </Switch>
  )
}

export default Inventory
