import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {FaChevronDown, FaSearch, FaCheck} from 'react-icons/fa'
import {RiCloseLine} from 'react-icons/ri'
import styled from 'styled-components'
import {useOnClickOutside} from '../utils/customHooks'

type Props = {
  className?: string
  label: string
  placeHolder: string
  options: Option[]
  childOptions: Option[]
  getChildOptions: (parentId: string, listType: string, option?: Option) => void
  manageTableRow: (item: Option, filterType: string, listType: string) => void
  manageMultipleRows: (items: Option[], info: string) => void
  searchCategories: (name: string, listType: string, pageNumber: number, byScroll?: boolean) => void
  tableRows: Option[]
  filterType: string
  listType: string
  totalCategoryCount?: number
  loading?: boolean
  searchText: string
  manageSearchText: (val: string, listType: string) => void
}

export type Option = {
  id: string
  parentId?: string
  parentName?: string
  selected: boolean
  title: string
  category?: string
  brand?: string
  amount: number
  visible?: boolean
  childCategoryCount?: number
  updateDate?: Date
}

const MultiColumnSelect: FC<Props> = ({
  className,
  label,
  placeHolder,
  options,
  childOptions,
  getChildOptions,
  manageTableRow,
  manageMultipleRows,
  tableRows,
  searchCategories,
  filterType,
  listType,
  totalCategoryCount,
  loading,
  searchText,
  manageSearchText,
}) => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false)
  const ref = useRef()
  const inputRef = useRef<any>()
  const [selectedId, setSelectedId] = useState<string>('')
  const [pageNumber, setPageNumber] = useState<number>(0)
  const observer = useRef<any>()
  const lastRowRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          _getCategoriesByScroll(pageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading]
  )

  function _getCategoriesByScroll(page: number) {
    if (options.length < (totalCategoryCount || 0)) {
      searchCategories(searchText, listType, page, true)
    }
  }

  useOnClickOutside(ref, () => {
    setOptionsVisible(false)
    getChildOptions('', listType)
  })

  function toggleOptions() {
    setOptionsVisible(true)
  }

  function _search(value: string) {
    setPageNumber(0)
    manageSearchText(value, listType)
    searchCategories(value, listType, 0)
  }

  function _close() {
    _search('')
    inputRef.current.focus()
  }

  function toggleCheckbox(item: Option, gen: string) {
    setSelectedId(item.id)
    if (gen === 'parent') {
      getChildOptions(item.id, listType, item)
    } else if (gen === 'child') {
      manageTableRow(item, filterType, listType)
    }
  }

  function isSelected(item: Option, gen: string) {
    return (
      tableRows?.findIndex((it: Option) =>
        gen === 'child' ? item.id === it.id : item.id === it.parentId
      ) !== -1
    )
  }

  function getOptions(opts: Option[], gen: string) {
    const filteredOptions = opts.map((item: Option) => ({
      ...item,
      selected: isSelected(item, gen),
    }))

    return filteredOptions.map((item: Option, index: number) => (
      <div
        key={index}
        className={`option ${(item.selected || item.id === selectedId) && 'selected'}`}
        onClick={() => toggleCheckbox(item, gen)}
        ref={filteredOptions.length === index + 1 && gen === 'parent' ? lastRowRef : null}
      >
        <div className='form-check form-check-sm form-check-custom form-check-solid check'>
          <input
            id={`selected${index}`}
            name='selected'
            className='form-check-input custom-check'
            type='checkbox'
            value='1'
            checked={item.selected || (item.id === selectedId && gen === 'parent')}
            onChange={() => {}}
            autoComplete='off'
          />
        </div>
        <span>{`${item.title}`}</span>
      </div>
    ))
  }

  return (
    <Container ref={ref}>
      <InputContainer>
        <div className='label'>
          <Label htmlFor='multicolumn'>{label}</Label>
        </div>
        <InputWrap onClick={toggleOptions}>
          <input
            ref={inputRef}
            type='text'
            name='multicolumn'
            className='search-input'
            placeholder={placeHolder}
            value={searchText}
            onChange={(e) => _search(e.target.value)}
          />
          <IconWrap type='chevron'>
            {/* <RiCloseLine
              color={optionsVisible ? '#666666' : '#cccccc'}
              fontSize={18}
              className='me-2'
              onClick={_close}
            /> */}
            <FaChevronDown fontSize={13} color={optionsVisible ? '#666666' : '#cccccc'} />
          </IconWrap>
          <IconWrap type='search'>
            <FaSearch color='#a1a5bd' />
          </IconWrap>
        </InputWrap>
      </InputContainer>
      {optionsVisible && (
        <TableWrap>
          <LeftOptionColumn>
            <OptionContainer>
              {getOptions(options, 'parent')}
              {loading ? (
                <div className='h-100 d-flex justify-content-center align-items-center'>
                  <span>Loading...</span>
                </div>
              ) : null}
            </OptionContainer>
          </LeftOptionColumn>
          <RightOptionColumn>
            {childOptions.length ? (
              <div
                className='button-all'
                onClick={() => manageMultipleRows(childOptions, listType)}
              >
                <FaCheck />{' '}
                {childOptions.some((item) => !isSelected(item, 'child'))
                  ? ' Select all'
                  : 'Deselect all'}
              </div>
            ) : null}
            <OptionContainer>{getOptions(childOptions, 'child')}</OptionContainer>
          </RightOptionColumn>
        </TableWrap>
      )}
    </Container>
  )
}

export {MultiColumnSelect}

const Container = styled.div<any>`
  position: relative;
  z-index: 3;

  input {
    cursor: pointer;
    margin: 0;
    text-indent: 25px;
  }

  .custom-check {
    border: 1px solid black;
  }
`

const TableWrap = styled.div<any>`
  position: absolute;
  display: flex;
  background-color: #ffffff;
  border: 2px solid #dfdfdf;
  border-radius: 5px;
  top: 57px;
  left: 270px;
  width: calc(80% - 270px);
  max-height: 350px;
  height: 300px;
  @media screen and (max-width: 680px) {
    top: 60px !important;
    left: -20px !important;
    width: calc(100% + 40px);
  }
`

const InputContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 80%;
  flex-grow: 1;
  .label {
    display: flex;
    justify-content: start;
    min-width: 270px;
  }
`

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
`

const LeftOptionColumn = styled.div`
  width: 50%;
  height: 100%;
  border-right: 2px solid #dfdfdf;
`

const RightOptionColumn = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .button-all {
    margin:15.5px 20px -5px 0;
    align-self:flex-end;
    border-radius: 4px;
    text-align-center;
    box-shadow:none;
    border-none;
    outline: none;
    background-color: #e4e6ef;
    color: #000000;
    padding: 5px 15px;
  }
`

export const OptionContainer = styled.div`
  margin-top: 20px;
  height: 275px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  .option {
    width: 100%;
    padding: 10px 10px 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .option:hover {
    background-color: #e6f5fe;
  }

  .check {
    margin: 0 10px 0 10px;
  }

  .selected {
    background-color: #f0f4f8;
    // background-color: #0095e8;
  }

  //-------------scrollbar------------

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #e4e6ef;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #40b6f9;
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: #40b6f9;
  }

  scrollbar-width: 6px;
  scrollbar-color: #40b6f9 #e4e6ef;
`

const IconWrap = styled.div<any>((props) =>
  props.type === 'chevron'
    ? `
    position:absolute;
    top:14px;
    right:11px;
    cursor:pointer;
`
    : `
    position:absolute;
    top: 15px;
    left: 12px;
    cursor:pointer;
`
)

const InputWrap = styled.div`
  position: relative;
  width: 100%;

  .search-input {
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #cdcfda;
    padding: 11px 8px;
    outline: none;
    color: #a1a5bd;
    text-indent: 25px;
    width: 100%;
    color: #3f4254;
  }

  .search-input:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 3px #c0e7fd;
  }
`
