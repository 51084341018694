/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import moment from 'moment'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa';
import { CustomHeader } from '../../components/CustomStyledComponents'
import { DateTimePicker } from '../../components/DateTimePicker'
import { DateFilter } from '../filters/FilterTypes'
import { FilterData } from '../filters/FilterTypes'
import { useOnClickOutside } from '../../utils/customHooks'
import { formatMoney } from '../../utils/helperUtils'

type Props = {
  className?: string
  header: string
  info: string
  data?: Array<CurrentListingProduct>
  searchText: string
  manageSearchText: (text: string) => void
  manageFilters: (name: string, index: number) => void
  manageDates: (dates: any, name: string) => void
  statusFilterData: FilterData[]
  pricingFilterData: FilterData[]
  invFilterData: FilterData[]
  dateFilters: DateFilters
  manageSelectedRow: (product: CurrentListingProduct) => void
  loading: boolean
}


export type CurrentListingProduct = {
  amazonProductId: number
  asin: string
  handelingTimeHours: string
  imgUrl: string
  lastSoldOn: Date
  listedOn: Date
  listingExternalIdentifier: string
  maxPrice: number
  minPrice: number
  modifiedOn: Date
  price: number
  soldCount: number
  storeId: number
  priceCurrency: string
}

export type DateFilters = {
  pricingDate: DateFilter
  invDate: DateFilter
}

const CurrentListingsTable: React.FC<Props> = ({
  header,
  info,
  data,
  manageDates,
  manageFilters,
  statusFilterData,
  pricingFilterData,
  invFilterData,
  dateFilters,
  manageSelectedRow,
  searchText,
  manageSearchText,
  loading,
}) => {
  const [activeCol, setActiveCol] = useState<string>('')

  const tHeadRef = useRef<any>()

  useOnClickOutside(tHeadRef, () => setActiveCol(''))

  function _manageActiveCol(filter: string) {
    setActiveCol(filter)
  }

  function _formatMoney(price: number, currency: string) {
    return formatMoney(price, currency);
  }

  function _getFilters(filterData: FilterData[], name: string) {
    return filterData.map((item, index) => (
      <div
        className='d-flex justify-content-start align-items-center fs-6 fw-bold mt-3 px-2'
        key={index}
      >
        <div className='form-check me-2 form-check-sm form-check-solid '>
          <input
            id={`selected${index}`}
            name='selected'
            className='form-check-input'
            type='checkbox'
            value='1'
            checked={item.selected}
            onChange={() => manageFilters(name, index)}
          />
        </div>
        <div>
          <span>{item.title}</span>
        </div>
      </div>
    ))
  }

  function getTableRows() {
    return data?.map((item: CurrentListingProduct, index: number) => (
      <tr key={index} className='custom-tbl-row' onClick={() => manageSelectedRow(item)}>
        <td className='ps-4'>
          <div className='symbol symbol-50px me-5'>
            <img src={item?.imgUrl} className='' alt='' />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center text-wrap w-150px'>
            <b>ASIN: </b><span className='custom-tbl-data'> {item?.asin}</span>
          </div>
          <div className='d-flex align-items-center text-wrap w-150px'>
            <b>PID: </b><div className='custom-tbl-data'> {item?.amazonProductId}</div>
          </div>
        </td>
        <td className='px-7'>
          <div className={`status-custom-tbl-data`}>
            <span className='custom-tbl-data'>{_formatMoney(item.price, item.priceCurrency)}</span>
          </div>
          <div className={`status-custom-tbl-data`}>
            <span className='custom-tbl-data'>Min: {_formatMoney(item.minPrice, item.priceCurrency)}</span>
          </div>
          <div className={`status-custom-tbl-data`}>
            <span className='custom-tbl-data'>Max {_formatMoney(item.maxPrice, item.priceCurrency)}</span>
          </div>
        </td>
        <td>
          <span className='custom-tbl-data'>{item.soldCount}</span>
        </td>
        <td>
          <span className='custom-tbl-data'>
            {!item.lastSoldOn ? 'N/A' : moment(new Date(item.lastSoldOn)).format('lll')}
          </span>
        </td>
        <td>
          <div className={`status-custom-tbl-data`}>
            <span className='custom-tbl-data'>Listed:  {moment(new Date(item.listedOn)).format('lll')}</span>
          </div>
          <div className={`status-custom-tbl-data`}>
            <span className='custom-tbl-data'>Modified:  {moment(new Date(item.modifiedOn)).format('lll')}</span>
          </div>
        </td>
        <td>
          <span className='custom-tbl-data'>
            {item.handelingTimeHours}
          </span>
        </td>
      </tr>
    ))
  }

  return (
    <Container>
      {/* begin::Header */}
      <CustomHeader>Products</CustomHeader>
      <hr className='m-0' />
      {/* end::Header */}
      {/* begin::Body */}
      {/* begin::Table container */}
      <div className='table-responsive table-wrapper '>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-6 text-nowrap '>
          {/* begin::Table head */}
          <thead className='sticky-top' ref={tHeadRef}>
            <tr className='custom-tbl-head'>
              <th className='ps-4 w-100px rounded-top-start'>Image</th>
              <th className='w-150px'>
                <div className='d-flex filter-parent'>
                  <span>Product</span>
                  <div onClick={() => _manageActiveCol('title')}>
                    {searchText ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'title' ? (
                    <div className='filter-table filter-title'>
                      <input
                        type='text'
                        className='form-control'
                        value={searchText}
                        onChange={(e) => manageSearchText(e.target.value)}
                      />
                      <div className='search-icon'>
                        <FaSearch />
                      </div>
                      <div className='mt-4'>
                        <span>Insert the title you are looking for</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='w-125px'>
                <div className='d-flex filter-parent ps-5'>
                  <span>Price</span>
                  <div onClick={() => _manageActiveCol('status')}>
                    {Object.values(statusFilterData).find((item) => item.selected) ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'status' ? (
                    <div className='filter-table filter-status'>
                      {_getFilters(statusFilterData, 'status')}
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='w-175px'>
                <div className='d-flex filter-parent'>
                  <span>Sold</span>
                  <div onClick={() => _manageActiveCol('priceInfo')}>
                    {Object.values(pricingFilterData).find((item) => item.selected) ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'priceInfo' ? (
                    <div className='filter-table filter-price-info'>
                      {_getFilters(pricingFilterData, 'pricing')}
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='w-200px'>
                <div className='d-flex'>
                  <span>Last Sold</span>
                  <div className='filter-parent' onClick={() => _manageActiveCol('priceDate')}>
                    {dateFilters.pricingDate.from && dateFilters.pricingDate.to ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/calendar-fill.svg')}
                        className='icon-date'
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/calendar.svg')}
                        className='icon-date'
                      />
                    )}
                    <div className='date-container'>
                      <DateTimePicker
                        className='w-20px h-20px'
                        startDate={dateFilters?.pricingDate?.from}
                        endDate={dateFilters?.pricingDate?.to}
                        onChange={(dates) => manageDates(dates, 'pricingDate')}
                        showPopperArrow={false}
                        forFilter
                        selectsRange
                      />
                    </div>
                  </div>
                </div>
              </th>
              <th className='w-150px'>
                <div className='d-flex filter-parent'>
                  <span>Listed / Modified</span>
                  <div onClick={() => _manageActiveCol('invInfo')}>
                    {Object.values(invFilterData).find((item) => item.selected) ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'invInfo' ? (
                    <div className='filter-table filter-inv-info'>
                      {_getFilters(invFilterData, 'inv')}
                    </div>
                  ) : null}
                </div>
              </th>
              <th className='w-200px'>
                <div className='d-flex'>
                  <span>Handling Time</span>
                  <div className='filter-parent' onClick={() => _manageActiveCol('invDate')}>
                    {dateFilters.invDate.from && dateFilters.invDate.to ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/calendar-fill.svg')}
                        className='icon-date'
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/calendar.svg')}
                        className='icon-date'
                      />
                    )}
                    <div className='date-container'>
                      <DateTimePicker
                        className='w-20px h-20px'
                        startDate={dateFilters.invDate.from}
                        endDate={dateFilters.invDate.to}
                        onChange={(dates) => manageDates(dates, 'invDate')}
                        showPopperArrow={false}
                        forFilter
                        selectsRange
                      />
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          {loading ? (
            <tbody className='loading'>
              <tr>
                <td>Loading...</td>
              </tr>
            </tbody>
          ) : data?.length ? (
            <tbody>{getTableRows()}</tbody>
          ) : (
            <tbody className='loading'>
              <tr>
                <td>There are no products in this store...</td>
              </tr>
            </tbody>
          )}
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
      {/* begin::Body */}
    </Container>
  )
}

export { CurrentListingsTable }

const Container = styled.div`
  thead {
    z-index: 1;
  }

  .loading {
    font-size: 18px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .table-wrapper {
    position: relative;
    height: 52vh;
    overflow: auto;
    margin: 24px 12px;
    padding-inline: 12px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .custom-tbl-head {
    background-color: #d9f1fe;
    color: #525869;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
  }

  .custom-tbl-data {
    font-size: 14px;
  }

  .custom-tbl-row {
  }

  .custom-tbl-row:hover {
    background-color: #f3faff;
    .status-finished {
      border: 1px solid #50cd89;
    }

    .status-failed {
      border: 1px solid #f1416c;
    }

    .status-process {
      border: 1px solid #ffc700;
    }
  }

  .status-finished,
  .status-failed,
  .status-process {
    padding: 5px;
    border-radius: 5px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .status-finished {
    background-color: #e8fff3;
    color: #50cd89;
  }

  .status-failed {
    background-color: #fff5f8;
    color: #f1416c;
  }

  .status-process {
    background-color: #fff8dd;
    color: #ffc700;
  }

  .icon,
  .icon-date {
    margin: 0 0 2px 2px;
    cursor: pointer;
  }

  .icon-date {
    margin-left: 4px;
  }

  .date-container {
    position: absolute;
    top: -2px;
    left: 4px;
    cursor: pointer;
  }

  .filter-parent {
    position: relative;
  }

  .filter-table {
    position: absolute;
    top: 45px;
    left: 0px;
    width: 300px;
    height: 150px;
    display: flex;
    background-color: white;
    border: 2px solid #a1a5b7;
    border-radius: 5px;
  }

  .filter-title {
    flex-direction: column;
    height: 100px;
    padding-inline: 16px;
    input {
      margin-top: 20px;
      height: 30px;
      text-indent: 20px;
    }

    input:focus {
      border: 1px solid #2684ff;
      box-shadow: 0 0 1px 2px #c0e7fd;
    }

    .search-icon {
      position: absolute;
      top: 24px;
      left: 30px;
    }
  }

  .filter-status,
  .filter-price-info,
  .filter-inv-info {
    width: 150px;
    height: 110px;
    display: flex;
    flex-direction: column;
  }

  .filter-price-info {
  }

  .filter-price-date {
  }

  .filter-inv-info {
  }

  .filter-inv-date {
  }

  .rounded-top-start {
    border-top-left-radius: 8px;
  }

  .rounded-top-end {
    border-top-right-radius: 8px;
  }
`
