import { useState } from 'react'

import { importProductsApis } from '../../setup/apis/product/importProductsApis'
import {PagingType} from "../../setup/types/request-data-types/RequestDataTypes";

function useGetStoreFilterSettings<T>(
  whiteKey: string,
  blackKey: string,
  storeId: number,
  isCategory?: boolean
) {
  const [category, setCategory] = useState<{ whiteList: T[], blackList: T[] }>( { whiteList: [], blackList: []});

  const [brandsWhiteList, setBrandsWhiteList] = useState<T[]>([]);
  const [brandsBlackList, setBrandsBlackList] = useState<T[]>([]);

  const [asinsWhiteList, setAsinsWhiteList ] = useState<T[]>([]);
  const [asinsBlackList, setAsinsBlackList ] = useState<T[]>([]);

  const [keywordsWhiteList, setKeywordsWhiteList] = useState<T[]>([]);
  const [keywordsBlackList, setKeywordsBlackList] = useState<T[]>([]);

  const [phrasesWhiteList, setPhrasesWhiteList] = useState<T[]>([]);
  const [phrasesBlackList, setPhrasesBlackList] = useState<T[]>([]);

  const [listingsWhiteList, setListingsWhiteList] = useState<T[]>([]);
  const [listingsBlackList, setListingsBlackList] = useState<T[]>([]);

  const [paging, setPaging] = useState<{ whiteList: PagingType, blackList: PagingType }>( { whiteList: { pageNumber: 1, pageSize: 500, count: 0 }, blackList: { pageNumber: 1, pageSize: 500, count: 0 }});

  const [isLoading, setIsLoading] = useState(false)


  async function getCategories() {
    try {
      setIsLoading(true)
      const response = await importProductsApis.getFilter(storeId)
      setCategory({
          whiteList: response[whiteKey],
          blackList: response[blackKey]
      })
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  async function getBrands(pagination: PagingType, searchValue: string) {
    try {
      setIsLoading(true)
      const response = await importProductsApis.getStoreFilterSettingsPagination(pagination, searchValue, storeId, 'brand');
        console.log(response);
        setBrandsBlackList(response[blackKey]);
        setBrandsWhiteList(response[whiteKey]);
        setPaging({
            whiteList: response.whitelistPaging,
            blackList: response.blackLististPaging
        })
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  async function getAsins(pagination: PagingType, searchValue: string) {
    try {
      setIsLoading(true)
        const response = await importProductsApis.getStoreFilterSettingsPagination(pagination, searchValue, storeId, 'asins')
        setAsinsBlackList(response[blackKey]);
        setAsinsWhiteList(response[whiteKey]);
        setPaging({
            whiteList: response.whitelistPaging,
            blackList: response.blackLististPaging
        })
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  async function getKeywords(pagination: PagingType, searchValue: string) {
    try {
      setIsLoading(true)
        const response = await importProductsApis.getStoreFilterSettingsPagination(pagination, searchValue, storeId, 'keywords')
        setKeywordsWhiteList(response[whiteKey]);
        setKeywordsBlackList(response[blackKey]);
        setPaging({
            whiteList: response.whitelistPaging,
            blackList: response.blackLististPaging
        })
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  async function getPhrases(pagination: PagingType, searchValue: string) {
    try {
      setIsLoading(true)
        const response = await importProductsApis.getStoreFilterSettingsPagination(pagination, searchValue, storeId, 'phrases');
        setPhrasesBlackList(response[blackKey]);
        setPhrasesWhiteList(response[whiteKey]);
        setPaging({
            whiteList: response.whitelistPaging,
            blackList: response.blackLististPaging
        })
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  async function getListings(pagination: PagingType, searchValue: string) {
    try {
      setIsLoading(true)
        const response = await importProductsApis.getStoreFilterSettingsPagination(pagination, searchValue, storeId, 'listings')
        setListingsBlackList(response[blackKey]);
        setListingsWhiteList(response[whiteKey]);
        setPaging({
            whiteList: response.whitelistPaging,
            blackList: response.blackLististPaging
        })
    } catch (err) {
      console.log('error', err)
    } finally {
      setIsLoading(false)
    }
  }

  return {
      getCategories,
      getBrands,
      getAsins,
      getPhrases,
      getKeywords,
      isLoading,
      category,
      brandsBlackList,
      brandsWhiteList,
      asinsBlackList,
      asinsWhiteList,
      phrasesBlackList,
      phrasesWhiteList,
      keywordsBlackList,
      keywordsWhiteList,
      listingsBlackList,
      listingsWhiteList,
      setCategory,
      setBrandsBlackList,
      setBrandsWhiteList,
      setAsinsWhiteList,
      setAsinsBlackList,
      setPhrasesWhiteList,
      setPhrasesBlackList,
      setKeywordsBlackList,
      setKeywordsWhiteList,
      setListingsBlackList,
      setListingsWhiteList,
      paging,
      setPaging
  }
}

export default useGetStoreFilterSettings
