import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { twj } from 'tw-to-css'
import { MobileMenu } from './MobileMenu'

type Props = {
  homeHandler: () => void
  featuresHandler: () => void
  pricingHandler: () => void
}

export const Header: FC<Props> = ({ homeHandler, featuresHandler, pricingHandler }) => {
  const history = useHistory()
  const [scrolled, setScrolled] = useState<Boolean>(false)
  const MIN_SCROLL = window.innerHeight / 2
  
  useEffect(() => {
    function handleScroll() {
      const nextScrolled = window.scrollY > MIN_SCROLL
      const darkHeader = document.getElementById('darkHeader')
  
      if (scrolled && !nextScrolled) {
        // remove Header
        if (darkHeader?.classList.contains('animated-header-in')) {
          darkHeader?.classList.remove('animated-header-in')
          darkHeader?.classList.add('animated-header-out')
        }
        setScrolled(prev => nextScrolled)
      } else if (!scrolled && nextScrolled) {
        // add Header
        darkHeader?.classList.remove('hidden')
        darkHeader?.classList.remove('animated-header-out')
        darkHeader?.classList.add('animated-header-in')
        setScrolled(prev => nextScrolled)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrolled])

  
    

  return (
    <>
      {/* Dark version – fixed to the window top  */}
      {true && (
        <Animated>
        <header id="darkHeader" className='hidden fixed inset-x-0 top-0 z-50 bg-[#1D1D25] text-white shadow-xl bg-opacity-90'>
          <nav
            className='mx-auto max-w-[1464px] flex items-center justify-between p-6 lg:px-8'
            aria-label='Global'
          >
            <div className='flex lg:flex-1'>
              <a href='#' className='-m-1.5 p-1.5'>
                <span className='sr-only'>MarketGap</span>
                <img
                  className='h-8 w-auto'
                  src='/assets/img/MarketGap-logo-dark.png'
                  alt='MarketGap'
                />
              </a>
            </div>
            {/* <!-- Mobile menu --> */}
            <MobileMenu theme="dark" homeHandler={homeHandler} featuresHandler={featuresHandler} pricingHandler={pricingHandler}/>
            {/* <!-- Main navigation menu --> */}
              <div className='hidden lg:flex lg:gap-x-12 lg:items-center'>
                <button
                  onClick={homeHandler}
                  className=
                    "cursor-pointer text-sm uppercase font-medium leading-6 text-white font-['Nunito_Sans']"
                >
                  Home
                </button>
                <button
                  onClick={featuresHandler}
                  className="cursor-pointer text-sm uppercase font-medium leading-6 text-white font-['Nunito_Sans']"
                >
                  Features
                </button>
                <button
                  onClick={pricingHandler}
                  className="cursor-pointer text-sm uppercase font-medium leading-6 text-white font-['Nunito_Sans']"
                >
                  Pricing
                </button>
                <div className='lg:flex lg:gap-x-2 lg:items-center'>
                  <button
                    type='button'
                    onClick={() => history.push('/auth/login')}
                    style={twj("font-['Inter'] text-sm uppercase font-medium leading-6 rounded-3xl text-white border border-[#009EF7] inline-flex items-center justify-center px-8 py-2.5")}
                  >
                    Login
                  </button>
                  <button
                    type='button'
                    onClick={() => history.push('/registration/create-account')}
                    style={twj("font-['Inter'] text-sm uppercase font-medium leading-6 rounded-3xl bg-white text-zinc-900 border border-gray-100 inline-flex items-center justify-center px-6 py-2.5")}
                  >
                    Get started
                  </button>
                </div>
              </div>
          </nav>
        </header>
        </Animated>
      )}

      {/* Light version – fixed */}
      {!scrolled && (
        <header className='absolute inset-x-0 top-0 z-50'>
          <nav
            className='mx-auto max-w-[1464px] flex items-center justify-between p-6 lg:px-8)'
            aria-label='Global'
          >
            <div className='flex lg:flex-1'>
              <a href='#' className='-m-1.5 p-1.5'>
                <span className='sr-only'>MarketGap</span>
                <img className='h-8 w-auto' src='/assets/img/MarketGap-logo.png' alt='MarketGap' />
              </a>
            </div>
            {/* <!-- Mobile menu --> */}
            <MobileMenu theme="light" homeHandler={homeHandler} featuresHandler={featuresHandler} pricingHandler={pricingHandler} />
            {/* <!-- Main navigation menu --> */}
            <div className='hidden lg:flex lg:gap-x-12 lg:items-center'>
              <button
                onClick={homeHandler}
                className=" cursor-pointer text-sm uppercase font-medium leading-6 text-[#20202D] font-['Nunito_Sans']"
              >
                Home
              </button>
              <button
                onClick={featuresHandler}
                className=" cursor-pointer text-sm uppercase font-medium leading-6 text-[#20202D] font-['Nunito_Sans']"
              >
                Features
              </button>
              <button
                onClick={pricingHandler}
                className=" cursor-pointer text-sm uppercase font-medium leading-6 text-[#20202D] font-['Nunito_Sans']"
              >
                Pricing
              </button>
              <div className='lg:flex lg:gap-x-2 lg:items-center'>
                <button
                  type='button'
                  onClick={() => history.push('/auth/login')}
                  style={twj("font-['Inter'] text-sm uppercase font-medium leading-6 rounded-3xl bg-white text-gray-900 border border-[#009EF7] inline-flex items-center justify-center px-8 py-2.5")}
                >
                  Login
                </button>
                <button
                  type='button'
                  onClick={() => history.push('/registration/create-account')}
                  style={twj("font-['Inter'] text-sm uppercase font-medium leading-6 rounded-3xl bg-[#1D1D25] text-white border border-gray-100 inline-flex items-center justify-center px-6 py-2.5")}
                >
                  Get started
                </button>
              </div>
            </div>
          </nav>
        </header>
      )}
    </>
  )
}

const Animated = styled.div`
 .animated-header-in-new { 
    animation: show-animate 0.3s ease-in-out;
 }
 .animated-header-in {
  display: block;
  animation: fade-in 0.7s ease-in forwards;
}
 .animated-header-out { 
  animation: fade-out 0.7s ease-out forwards;
}
  @keyframes show-animate {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      display: none;
    }
  
    100% {
      opacity: 1;
      display: block;
    }
  }
  
  @keyframes fade-out {
    0% {
      opacity: 1;
      display: block;
    }
  
    100% {
      opacity: 0;
      display: none;
    }
  }
`

// const MenuWrapper = styled.div`
// display: none; 

// @media (min-width: 1024px) { 
//   display: flex; 
// column-gap: 3rem; 
// align-items: center; 
//  }
// `
