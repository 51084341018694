import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FaChevronRight } from 'react-icons/fa'
import { AiOutlineAmazon } from 'react-icons/ai'
import clsx from 'clsx'

import WhiteBlackListSelect from './WhiteBlackListSelect'
import { getWhiteBlackListType } from '../utils/helperUtils'
import { ICategories } from '../layger/discover/categories/CategoriesPage'

interface IProps {
  isSearching: boolean
  categoryWhiteList: string[]
  categoryBlackList: string[]
  accordionData: ICategories[]
  handleSelect: (eventKey: string | null, id: string, selectedValue: string) => void
}

const SubLevelComp = ({
  indent,
  category,
  isSearching,
  handleSelect,
  childIsBlocked,
  categoryBlackList,
  categoryWhiteList,
  renderNestedLevels,
}: {
  indent: number
  isSearching: boolean
  category: ICategories
  renderNestedLevels: any
  categoryWhiteList: string[]
  categoryBlackList: string[]
  childIsBlocked: boolean | undefined
  handleSelect: (eventKey: string | null, id: string, selectedValue: string) => void
}) => {
  const [selected, setSelected] = React.useState(false)

  useEffect(() => {
    setSelected(isSearching)
  }, [isSearching])

  const handleClick = () => setSelected(!selected)

  const isBlocked = categoryBlackList.includes(category.categoryId)
  const hasChildren = Array.isArray(category.children) && category.children.length > 0
  const selectedValue = !childIsBlocked
    ? getWhiteBlackListType(category.categoryId, categoryWhiteList, categoryBlackList)
    : 'blocked'

  return (
    <Row indent={indent}>
      <div className='accordion-item'>
        <div className={clsx('d-flex align-items-center', { 'cursor-pointer': hasChildren })}>
          <span className='title'>
            {hasChildren && (
              <FaChevronRight
                onClick={handleClick}
                className={clsx('transition', { rotate: !!selected })}
              />
            )}
          </span>

          <span className={clsx({ title: !hasChildren, 'margin-left': !hasChildren })}>
            <WhiteBlackListSelect
              id={category.categoryId}
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              childIsBlocked={childIsBlocked}
              options={['allowed', 'blocked', 'default']}
            />
          </span>

          <div className='w-100' onClick={(handleClick)}>
            {category.categoryName} ({category.categoryId}) <Href href={`https://www.amazon.com/b?node=${category.categoryId}`} target="_blank"><AiOutlineAmazon /></Href>
          </div>
        </div>
      </div>
      {selected && hasChildren && (
        <div>{renderNestedLevels(category.children, indent, isBlocked || childIsBlocked)}</div>
      )}
    </Row>
  )
}

const Accordion: React.FC<IProps> = ({
  isSearching,
  handleSelect,
  accordionData,
  categoryWhiteList,
  categoryBlackList,
}) => {
  const renderNestedLevels = (data: any, indent: number, childIsBlocked?: boolean) => {
    indent += 20
    return data.map((category: ICategories, itemIndex: string) => (
      <SubLevelComp
        indent={indent}
        key={itemIndex}
        category={category}
        isSearching={isSearching}
        handleSelect={handleSelect}
        childIsBlocked={childIsBlocked}
        renderNestedLevels={renderNestedLevels}
        categoryWhiteList={categoryWhiteList}
        categoryBlackList={categoryBlackList}
      />
    ))
  }

  return renderNestedLevels(accordionData, 4)
}

const Row = styled.div<any>(
  ({ indent }) => `
  .accordion-item {
    padding: 9px 0;
    background-color: #fff;
    width: 100%;
    text-align: start;
    border: 1px solid #edf2f9;
  }

  .title{
    text-indent: ${indent}px;
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .margin-left{
    margin-left: 14px;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .transition {
    transition: 0.5s;
  }
`
)

const Href = styled.a`
  color: #000000;
`

export default Accordion
