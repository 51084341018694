import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'

import {StoreType} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import {ButtonPrimary, CustomHeader} from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import {activeStoreSelector} from '../../../modules/selectors/reduxSelectors'
import {FilterTypes, ListByTab} from '../../filters/importProductsFilters'
import {filterBySearchValue} from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import RemoveButton from '../../../components/RemoveButton'
import AddListModal from '../../../components/AddListModal'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import {TabTypes} from '../../../utils/types'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {StyledDiv90} from "../Onboarding.styles";
import {useHistory, useParams} from "react-router-dom";

type Props = {
    store: StoreType | undefined;
}

const OnboardingInventoryAsins = (props: Props) => {
    const storeOptions = useSelector(activeStoreSelector)

    const [show, setShow] = useState(false)
    const [selectedId, setSelectedId] = useState('')
    const [tab, setTab] = useState<TabTypes>('white')
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStore, setSelectedStore] = useState<StoreType>( props.store ? props.store : storeOptions[0])

    const history = useHistory();
    const params: { storeId: string } = useParams();

    const { isLoading, getAsins } =
        useGetStoreFilterSettings<string>('asinWhitelist', 'asinBlacklist', selectedStore?.storeId)

    const {isLoading: addIsLoading, addFilter} = useAddFilter(
        selectedStore?.storeId,
        setShow,
        getAsins
    )

    useEffect(() => {
        // if (selectedStore) getAsins()
    }, [selectedStore?.storeId])

    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {value} = e.target

        setSearchValue(value)
    }

    // const tableData = useMemo(() => {
    //     const list = tab === 'white' ? asins['whiteList'] : asins['blackList']
    //
    // //     return list
    // //         ?.filter((el) => filterBySearchValue(el, searchValue))
    // //         .map((item) => ({
    // //             name: item,
    // //             button: (
    // //                 <RemoveButton
    // //                     value={item}
    // //                     selectedId={selectedId}
    // //                     listType={ListByTab[tab]}
    // //                     filterType={FilterTypes.Asin}
    // //                     storeId={selectedStore?.storeId}
    // //                     setSelectedId={setSelectedId}
    // //                     setWhiteList={(val) => {
    // //                         // @ts-ignore
    // //                         setAsins(prevCategory => ({
    // //                             ...prevCategory,
    // //                             whiteList: val
    // //                         }));
    // //                     }}
    // //                     setBlackList={(val) => {
    // //                         // @ts-ignore
    // //                         setAsins(prevCategory => ({
    // //                             ...prevCategory,
    // //                             blackList: val
    // //                         }));
    // //                     }}
    // //                 />
    // //             ),
    // //         }))
    // // }, [tab, asins, searchValue])

    return (
        <StyledDiv90>
            <CustomHeader marginl='0'>ASIN List</CustomHeader>

            {/*<div className='d-flex justify-content-end'>*/}
            {/*    <StoreSelect*/}
            {/*        selectedStore={selectedStore}*/}
            {/*        handleSelect={(option: StoreType) => setSelectedStore(option)}*/}
            {/*    />*/}

            {/*    <ButtonPrimary*/}
            {/*        onClick={() => setShow(true)}*/}
            {/*        bgColor='#037FC5'*/}
            {/*        paddingx={22}*/}
            {/*        paddingy={12}*/}
            {/*        fontSize={13}*/}
            {/*        marginl={12}*/}
            {/*    >*/}
            {/*        Add to list*/}
            {/*    </ButtonPrimary>*/}
            {/*</div>*/}

            <Tabs
                tabData={[
                    {title: 'Blocked', value: 'black'},
                ]}
                tab={tab}
                setTab={setTab}
            />

            <SearchBar handleChange={handleSearchValueChange} page='ASINS'/>

            <View isLoading={isLoading}>
                {/*<InventoryTable tHeader={['ASIN List', '']} data={tableData}/>*/}
            </View>

            <AddListModal
                show={show}
                page='asin'
                setShow={setShow}
                addFilter={addFilter}
                isLoading={addIsLoading}
                listType={ListByTab[tab]}
                selectedStore={selectedStore}
                filterType={FilterTypes.Asin}
                handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
            />
        </StyledDiv90>
    )
}

export default OnboardingInventoryAsins
