import React, { useEffect, useMemo, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListByTab } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import RemoveButton from '../../../components/RemoveButton'
import AddListModal from '../../../components/AddListModal'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {PagingType} from "../../../../setup/types/request-data-types/RequestDataTypes";
import {Pagination} from "../../../components/Pagination";
import debounce from "lodash/debounce";

const Brands = () => {
  const storeOptions = useSelector(activeStoreSelector);

  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 500, count: 0 })
  const [show, setShow] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [tab, setTab] = useState<'white' | 'black'>('white')
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedStore, setSelectedStore] = useState<any>()
  const history = useHistory<any>()
  const params: { storeId: string } = useParams();

  const { isLoading, getBrands, brandsWhiteList, brandsBlackList, setBrandsWhiteList, setBrandsBlackList, paging, setPaging } =
    useGetStoreFilterSettings<string>('brandWhitelist', 'brandBlacklist', selectedStore?.storeId)

  const { isLoading: addIsLoading, addFilter } = useAddFilter(
    selectedStore?.storeId,
    setShow,
      () => getBrands(pagingData, searchValue)
  )

    useEffect(() => {
        if (storeOptions.length === 0) return;

        const paramsStoreId = parseInt(params.storeId);
        const storedStoreId = parseInt(sessionStorage.getItem('storeId') || '0');

        const selectedStoreId = paramsStoreId || storedStoreId || storeOptions[0]?.storeId;
        const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];

        setSelectedStore(selectedStore);
        sessionStorage.setItem('storeId', String(selectedStoreId));
        history.push(`/inventory/brands/${selectedStore.storeId}`);
    }, [storeOptions]);


    function handleStoreSelect(option: StoreType) {
      setSelectedStore(option)
      sessionStorage.setItem('storeId', String(option.storeId));
      history.push(`/inventory/brands/${option.storeId}`);
  }

    function handlePageChange({ selected }: any) {
        setPagingData((prev) => ({ ...prev, pageNumber: selected }))
    }

    useEffect(() => {
        let paramStoreId = parseInt(params.storeId);

        if (selectedStore?.storeId == paramStoreId) {
            const debouncedSearch = debounce(() => getBrands(pagingData, searchValue), 300);
            debouncedSearch();

            return () => {
                debouncedSearch.cancel();
            };
        }
    }, [searchValue]);

  useEffect(() => {
    let storeParam = parseInt(params.storeId);
    if (selectedStore?.storeId == storeParam) getBrands(pagingData, searchValue)
  }, [selectedStore?.storeId, pagingData])

    function handleSearchValueChange(value: string) {
        setSearchValue(value)
    }

    function search(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        handleSearchValueChange(value)
    }


    const tableData = useMemo(() => {
    const list = tab === 'white' ? brandsWhiteList: brandsBlackList

    return list
      ?.filter((el) => filterBySearchValue(el, searchValue))
      .map((item) => ({
        name: item,
        button: (
          <RemoveButton
            value={item}
            selectedId={selectedId}
            listType={ListByTab[tab]}
            filterType={FilterTypes.Brand}
            storeId={selectedStore?.storeId}
            setSelectedId={setSelectedId}
            setWhiteList={setBrandsWhiteList}
            setBlackList={setBrandsBlackList}
          />
        ),
      }))
  }, [tab, brandsBlackList, brandsWhiteList, searchValue])

  return (
    <div style={{paddingBlockEnd: '100px'}}>
      <CustomHeader marginl='0'>Brand List</CustomHeader>

      <div className='d-flex justify-content-end'>
        <StoreSelect
          selectedStore={selectedStore}
          handleSelect={(option: StoreType) => handleStoreSelect(option)}
        />

        <ButtonPrimary
          onClick={() => setShow(true)}
          bgColor='#037FC5'
          paddingx={22}
          paddingy={12}
          fontSize={13}
          marginl={12}
        >
          Add to list
        </ButtonPrimary>
      </div>

      <Tabs
        tabData={[
          { title: 'Allowed', value: 'white' },
          { title: 'Blocked', value: 'black' },
        ]}
        tab={tab}
        setTab={(tab) => {
            setPagingData({ pageNumber: 0, pageSize: 500, count: 0 });
            setTab(tab);
        }}

      />


      <SearchBar handleChange={search} page='Brands' />

      <View isLoading={isLoading}>
        <InventoryTable tHeader={['Brand List', '']} data={tableData} />
          <Pagination
              currentPage={pagingData.pageNumber}
              pageSize={pagingData.pageSize}
              totalCount={tab === 'white' ? paging?.whiteList?.count : paging?.blackList?.count}
              onPageChange={handlePageChange}
              onPageSizeChange={() => {}}
          />
      </View>

      <AddListModal
        show={show}
        page='brand'
        setShow={setShow}
        addFilter={addFilter}
        isLoading={addIsLoading}
        listType={ListByTab[tab]}
        selectedStore={selectedStore}
        filterType={FilterTypes.Brand}
        handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
      />
    </div>
  )
}

export default Brands
