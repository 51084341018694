import React from 'react';
import moment from 'moment'
import styled from 'styled-components'
import { CustomHeader } from '../../../components/CustomStyledComponents'
import { StorePropertiesType } from '../../../../setup/types/request-data-types/RequestDataTypes'

type Props = {
  data: StorePropertiesType | undefined
}

const AdditionalInfo: React.FC<Props> = ({ data }) => {
  function convertLeadTimeFromHoursToDays(val: any) {
    if(val && val > 0) {
      return val / 24
    }
    return 0
  }

  return (
    <Container className='card mb-5 mb-xl-10'>
      <CustomHeader fontSize={18}>ADDITIONAL SETTINGS </CustomHeader>
      <div className='info-wrapper'>
        {/*<div className='row mb-6'>*/}
        {/*  <label className='col-lg-4 col-form-label fw-bold fs-6 custom-label'>*/}
        {/*    <span>Repricer Rule Name</span>*/}
        {/*  </label>*/}
        {/*  <div className='col-lg-8 fv-row d-flex align-items-center'>*/}
        {/*    <span>{data?.repricerRuleName || 'Not selected'}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6 custom-label'>
            <span>Lead Time For FBM Offers</span>
          </label>
          <div className='col-lg-8 fv-row  d-flex align-items-center'>
            <span>{`${convertLeadTimeFromHoursToDays(data?.fbmLeadTime)} Days`}</span>
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6 custom-label'>
            <span>Lead Time For FBA Offers</span>
          </label>

          <div className='col-lg-8 fv-row  d-flex align-items-center'>
            <span>{`${convertLeadTimeFromHoursToDays(data?.fbaLeadTime)} Days`}</span>
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bold fs-6 custom-label'>
            <span>Ounces to add to quote weight</span>
          </label>

          <div className='col-lg-8 fv-row  d-flex align-items-center'>
            <span>{`${data?.addOzToQuote} Oz`}</span>
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 custom-label'>
            <span>Pause Lowering Listing Pricing</span>
          </label>
          <div className='col-lg-8 d-flex align-items-center justify-content-between'>
            <div className='d-flex justify-content-between align-items-center'>
              {data?.pauseLoweringListingPricingFrom ? (
                <div className='text-start'>
                  <span>{`From ${moment(data?.pauseLoweringListingPricingFrom || new Date()).format(
                    'LLL'
                  )}`}</span>
                </div>
              ) : null}
              {data?.pauseLoweringListingPricingUntil ? (
                <div className='text-center mx-10'>
                  <span>{`To ${moment(data?.pauseLoweringListingPricingUntil || new Date()).format(
                    'LLL'
                  )}`}</span>
                </div>
              ) : null}
              <div
                className={`custom-badge-${data?.pauseLoweringListingPricing ? 'active' : 'passive'
                  }`}
              >
                <span className='badge-label'>
                  {data?.pauseLoweringListingPricing ? 'Enabled' : 'Disabled'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { AdditionalInfo }

const Container = styled.div`
  .info-wrapper {
    padding: 24px;
  }

  span {
    font-size: 16px;
    white-space: nowrap;
  }

  .custom-label {
    color: #7b7d8a;
  }

  .custom-badge-active,
  .custom-badge-passive {
    padding: 6px 16px;
    border-radius: 50px;
  }

  .custom-badge-active {
    background-color: #ccecfd;
    color: #037fc5;
  }

  .custom-badge-passive {
    background-color: #e4e6ef;
    color: #babdd0;
  }

  .badge-label {
    font-size: 14px;
    font-weight: 500;
  }
`
