import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from '../layger/registration/Registration'
import {ContactUs} from '../layger/contact-us/ContactUs'
import {AuthPage} from '../modules/auth'
import {PublicPage} from '../layger/public-page/PublicPage'
import TermsAndConditions from '../layger/terms-conditions/TermsAndConditionsPage'
import PrivacyPolicy from '../layger/privacy-policy/PrivacyPolicyPage'

export function PublicRoutes() {
  return (
    <Switch>
      <Route path='/auth' component={AuthPage} />
      <Route path='/registration' component={Registration} />
      <Route path='/terms-conditions' component={TermsAndConditions} />
      <Route path='/privacy-policy' component={PrivacyPolicy} />
      <Route path='/contact-us' component={ContactUs} />
      <Route exact path='/Arbitrage' component={PublicPage} />
      <Route exact path='/Dropshipping' component={PublicPage} />
      <Route exact path='/' component={PublicPage} />
      <Redirect to='/' />
    </Switch>
  )
}
