import React, {FC, useEffect} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {countryApis} from '../../setup/apis/country/countryApis'
import {getCountries} from '../modules/redux-toolkit/countrySlicer'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Alert} from '../components/Alert'
import {RootState} from '../../setup'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {PublicRoutes} from './PublicRoutes'
import {StripeRedirection} from '../layger/account-creation/StripeRedirection'
import {PlanOptionType} from "../layger/account-creation/AccountCreation";
import {planApis} from "../../setup/apis/subscription-plan/planApis";
import {getPlans} from '../modules/redux-toolkit/planSlicer'
import {ProductFruits} from "react-product-fruits";
import {accountApis} from "../../setup/apis/account/accountApis";
import {getUserId} from "../utils/authUtils";

type ProductFruitsUserInfo = {
    username: string,
    email: string,
    firstname: string,
    lastname: string,
}

const Routes: FC = () => {
    const dispatch = useDispatch()
    const alert = useSelector((state: RootState) => state.alert)
    const isAuthorized = useSelector<RootState>(({user}) => user.access_token, shallowEqual)
    const token = useSelector<RootState>(({user}) => user.access_token)

    const [userInfo, setUserInfo] = React.useState<ProductFruitsUserInfo>({
        username: 'None',
        email: '',
        firstname: '',
        lastname: '',
    });

    useEffect(() => {
        _getCountries();
        _getPlans();

        if(isAuthorized) {
            accountApis.getUserById(getUserId(token)).then((x) => {
                setUserInfo({
                    username: x.username,
                    email: x.email,
                    firstname: x.firstName,
                    lastname: x.lastName,
                })
            })
        }
    }, [])

    async function _getCountries() {
        const response = await countryApis.getAll()
        dispatch(getCountries(response?.data));
    }

    async function _getPlans() {
        let response: PlanOptionType[] = []
        response = ((await planApis.getAll()) || [])?.sort(
            (item1: PlanOptionType, item2: PlanOptionType) => item1.priceInCents - item2.priceInCents
        )
        dispatch(getPlans(response))
    }
    

    return (
        <>
            <Switch>
                {!isAuthorized ? <PublicRoutes/> : <Redirect from='/auth' to='/'/>}

                <Route exact path='/error' component={ErrorsPage}/>
                <Route exact path='/stripe-checkout' component={StripeRedirection}/>
                {!isAuthorized ? (
                    <Redirect to='/auth/login'/>
                ) : (
                    <MasterLayout>
                        <PrivateRoutes/>
                    </MasterLayout>
                )}
            </Switch>
            <Alert {...alert} />
            {isAuthorized && <ProductFruits workspaceCode="JYAQ4zyhztjBY65j" language="en" user={userInfo} />}
        </>
    )
}

export {Routes}
