import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {StoreType} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import {activeStoreSelector} from '../../../modules/selectors/reduxSelectors'
import {CustomHeader} from '../../../components/CustomStyledComponents'
import StoreSelect from '../../../components/StoreSelect'
import SearchBar from '../../../components/SearchBar'
import BrandsList from './BrandsList'
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import { debounce } from 'lodash';

export interface Brand {
    runId: number
    name: string
    categoryName: string | null
    itemCount: number
    qualifiedCount: number
    disqualifiedCount: number
    executed: boolean
}

const Brands = () => {
    const storeOptions = useSelector(activeStoreSelector)
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
    const history = useHistory<any>()
    const params: { storeId: string } = useParams();

    useEffect(() => {
        if (storeOptions.length) {
            let selectedStoreId: number;
            if (params.storeId) {
                selectedStoreId = parseInt(params.storeId);
            } else if (sessionStorage.getItem('storeId')) {
                selectedStoreId = parseInt(sessionStorage.getItem('storeId') ?? '0');
                history.push(`/inventory/categories/${storeOptions.find(item => item.storeId === selectedStoreId)?.storeId}`);
            } else {
                selectedStoreId = storeOptions[0].storeId;
            }
            const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];
            setSelectedStore(selectedStore);
            history.push(`/discover/brands/${selectedStore.storeId}`);
        }
    }, [storeOptions]);

    function handleStoreSelect(option: StoreType) {
        setSelectedStore(option)
        history.push(`/discover/brands/${option.storeId}`);
    }

    const debouncedSetSearchValue = debounce(value => setSearchValue(value), 500);
    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {value} = e.target

        debouncedSetSearchValue(value);
    }

    return (
        <div>
            <CustomHeader marginl='0'>Discover Brands</CustomHeader>

            <div className='d-flex justify-content-end mb-5'>
                <StoreSelect
                    selectedStore={selectedStore}
                    handleSelect={(option: StoreType) => handleStoreSelect(option)}
                />
            </div>

            <hr/>

            <SearchBar handleChange={handleSearchValueChange} page='Brands'/>
            <BrandsList searchValue={searchValue} selectedStore={selectedStore}/>
        </div>
    )
}

export default Brands
