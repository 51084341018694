import {FC, useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import {ButtonSecondary} from '../../components/CustomStyledComponents'
import Select from '../../components/Select'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useDispatch, useSelector} from 'react-redux'
import {accountApis} from '../../../setup/apis/account/accountApis'
import {CountryType} from '../../../setup/types/response-data-types/ResponseDataTypes'
import {RegistrationType} from '../registration/SubscriptionPlan'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PaymentMethod} from './PaymentMethod'
import {triggerAlert} from '../../components/Alert'
import {DetailsType} from './AccountCreation'
import {capitalize, nameValidation, preventSpaces} from '../../utils/inputFieldUtils'
import {AUTH_STATUS, deleteUserDataForReg, getUserId} from '../../utils/authUtils'
import {loadFromSessionStorage, saveToSessionStorage} from '../../utils/sessionStorageUtils'
import {countrySelector} from '../../modules/selectors/reduxSelectors'
import {getToken} from "../../modules/redux-toolkit/userSlicer";

const phoneRegExp = /^^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

const regSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols'),
  email: Yup.string()
    .trim()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .trim()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (!!(val && val.length > 0)),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('password')], "Passwords don't match"),
    }),
})

type Props = {
  handleSaved: (saved: boolean, values?: DetailsType) => void
  values: DetailsType
  token: string
  manageToken: (token: string) => void
}

const CreateAccount: FC<Props> = ({handleSaved, values, token, manageToken}) => {
  const history = useHistory<RegistrationType>()
  const state = history.location?.state
  const data = useRef<DetailsType>(values)
  const [cleared, setCleared] = useState<boolean>(false)
  const countryOptions = useSelector(countrySelector)
  const [loading, setLoading] = useState<boolean>(false)
  const [isVisiblePass, setIsVisiblePass] = useState<boolean>()
  const dispatch = useDispatch();
  const [isVisibleConfPass, SetIsVisibleConfPass] = useState<boolean>()
  const formik = useFormik({
    initialValues: data.current,
    validationSchema: regSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      _createOrUpdateAccount(values)
    },
  })
  const currentPasswordRef = useRef<string>(loadFromSessionStorage('userDetails')?.password || '')

  function _isValidForm() {
    if(!formik.values.firstName || !formik.values.firstName.toString().length) {
      return false;
    }
    if(!formik.values.lastName || !formik.values.lastName.toString().length) {
      return false;
    }
    if(!formik.values.email || !formik.values.email.toString().length) {
      return false;
    }
    if(!formik.values.password || !formik.values.password.toString().length) {
      return false;
    }
    if(!formik.values.confirmPassword || !formik.values.confirmPassword.toString().length) {
      return false;
    }

    return true;
  }


  async function _createOrUpdateAccount(values: any) {
    values = {
      ...values,
      firstName: capitalize(values.firstName),
      lastName: capitalize(values.lastName),
      username: values.email
    }
    try {
      if (!_isValidForm()) return
      setLoading(true)
      if (!token) {
        currentPasswordRef.current = values.password
      }
      const response = token
        ? await accountApis.updateUser(
            {
              ...values,
              currentPassword: currentPasswordRef.current,
              newPassword: values?.password,
              userId: getUserId(token),
              password: undefined,
              confirmPassword: undefined,
            },
            token
          )
        : await accountApis.register({
              ...values,
              username: values.email,
            });


      if (response?.status === 200 || response?.status === 204) {

        signIn(formik.values.email, formik.values.password || '')
        triggerAlert('Your info has been saved')
        handleSaved(true, values)
      }
    } catch (err: any) {
      if (err?.response?.status === 400) {
        const res: any = Object.values(err?.response?.data?.errors || {})?.[0]
        const message = Array.isArray(res)
          ? res[0]
          : typeof res === 'string'
          ? res
          : res?.description || ''
        triggerAlert(`${message}`, 'error')
      }
    } finally {
      setLoading(false)
    }
  }

  console.log(formik.values);

  async function signIn(username: string, password: string) {
    console.log('here from signin')
    try {
      const response = await accountApis.login(username, password);
      if (response?.data?.access_token) {
        manageToken(response?.data?.access_token);
        dispatch(getToken(response?.data))
        deleteUserDataForReg()
        localStorage.setItem('authStatus', AUTH_STATUS.loggedIn);
        saveToSessionStorage('login_data', response?.data);
        history.push('/stores/store-list');
      }
    } catch (err: any) {
      if (err?.response?.status === 400) {
        triggerAlert(
          `${err?.response?.data?.errors?.map((item: any) => `\n${item?.description}`)}`.replaceAll(
            ',',
            ''
          ),
          'error'
        )
      }
    }
  }

  function _handleSubmit(e: any) {
    e.preventDefault()
    formik.handleSubmit()
  }

  function isVisible(name: keyof DetailsType) {
    return formik.values[name]
  }

  function clearInput(name: keyof DetailsType) {
    setCleared((prev) => !prev)
    switch (name) {
      case 'firstName':
        formik.values.firstName = ''
        return
      case 'lastName':
        formik.values.lastName = ''
        return
      case 'email':
        formik.values.email = ''
        return
      case 'username':
        formik.values.username = ''
        return
    }
  }

  return (
    <Container className='details-body' onSubmit={(e) => _handleSubmit(e)}>
      <div>
        <div className='form-row flex-wrap flex-sm-nowrap'>
          <div className='form-item-left flex-grow-1 me-0 me-sm-10'>
            <label className='input-label'>First Name</label>
            <div className='icon-container'>
              <input
                type='text'
                placeholder='Insert your first name'
                autoComplete='off'
                onKeyDown={(e) => {
                  // nameValidation(e)
                }}
                onPaste={(e) => {
                  // nameValidation(e)
                }}
                {...formik.getFieldProps('firstName')}
                className={clsx(
                  'input-value input-cap',
                  {'input-invalid': formik.touched.firstName && formik.errors.firstName},
                  {
                    'input-valid': formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />
              {isVisible('firstName') ? (
                <div className='icon' onClick={() => clearInput('firstName')}>
                  <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                </div>
              ) : null}
            </div>
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block info-color'>
                  <span role='alert'>{formik.errors.firstName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='form-item flex-grow-1'>
            <label className='input-label'>Last Name</label>
            <div className='icon-container'>
              <input
                type='text'
                autoComplete='off'
                onKeyDown={(e) => {
                  // nameValidation(e)
                }}
                onPaste={(e) => {
                  // nameValidation(e)
                }}
                placeholder='Insert your last name'
                {...formik.getFieldProps('lastName')}
                className={clsx(
                  'input-value input-cap',
                  {'input-invalid': formik.touched.lastName && formik.errors.lastName},
                  {
                    'input-valid': formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
              {isVisible('lastName') ? (
                <div className='icon' onClick={() => clearInput('lastName')}>
                  <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                </div>
              ) : null}
            </div>
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block info-color'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item-single'>
            <label className='input-label'>Email</label>
            <div className='icon-container'>
              <input
                type='email'
                autoComplete='new-password'
                required
                placeholder='Insert your email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'input-value',
                  {'input-invalid': formik.touched.email && formik.errors.email},
                  {
                    'input-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {isVisible('email') ? (
                <div className='icon' onClick={() => clearInput('email')}>
                  <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                </div>
              ) : null}
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block info-color'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-row flex-wrap flex-sm-nowrap'>
          <div className='form-item-left flex-grow-1 me-0 me-sm-10'>
            <label className='input-label'>Password</label>
            <div className='icon-container'>
              <input
                type={isVisiblePass ? 'text' : 'password'}
                autoComplete='new-password'
                placeholder='Insert a password'
                onKeyDown={(e) => preventSpaces(e)}
                {...formik.getFieldProps('password')}
                onPaste={(e) => {
                  preventSpaces(e)
                }}
                className={clsx(
                  'input-value',
                  {'input-invalid': formik.touched.password && formik.errors.password},
                  {
                    'input-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <div className='icon' onClick={() => setIsVisiblePass((prev) => !prev)}>
                <img
                  src={toAbsoluteUrl(`/media/svg/misc/eye${isVisiblePass ? 'Slashed' : ''}.svg`)}
                />
              </div>
            </div>
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block info-color mt-1'>
                <span role='alert'>{`Your password should include at least 8 characters with small and capital letters, numbers, and special characters.`}</span>
              </div>
            </div>
          </div>
          <div className='form-item flex-grow-1'>
            <label className='input-label'>Confirm Password</label>
            <div className='icon-container'>
              <input
                type={isVisibleConfPass ? 'text' : 'password'}
                autoComplete='new-password'
                placeholder='Confirm your password'
                onKeyDown={(e) => preventSpaces(e)}
                onPaste={(e) => {
                  preventSpaces(e)
                }}
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'input-value',
                  {
                    'input-invalid':
                      formik.touched.confirmPassword && formik.errors.confirmPassword,
                  },
                  {
                    'input-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                  }
                )}
              />
              <div className='icon' onClick={() => SetIsVisibleConfPass((prev) => !prev)}>
                <img
                  src={toAbsoluteUrl(
                    `/media/svg/misc/eye${isVisibleConfPass ? 'Slashed' : ''}.svg`
                  )}
                />
              </div>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block info-color'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='save-button'>
        <ButtonSecondary className='w-full px-10' type='submit' disabled={!_isValidForm()}>
          Create Account
          {loading ? (
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          ) : null}
        </ButtonSecondary>
      </div>
    </Container>
  )
}

export {CreateAccount}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: start;
  width: 100%;
  .header-buttons {
    padding: 0 24px;
    display: flex;

    div {
      margin-left: 12px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
  }

  .info-color {
    color: #a1a5bd;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .form-item,
  .form-item-single,
  .form-item-left {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 6px 0;
    margin-bottom: 4px;
  }

  .form-item-left {
    margin-right: 30px;
  }

  .form-item-single {
    width: 100%;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;

    ::-ms-reveal {
      display: none;
    }
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #cdcfda;
  }

  .create-account {
    margin: 24px;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .save-button {
    margin-block: 24px;
  }

  .input-cap {
    text-transform: capitalize;

    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder {
      text-transform: none;
    }
    ::-moz-placeholder {
      text-transform: none;
    }
    :-ms-input-placeholder {
      text-transform: none;
    }
    ::placeholder {
      text-transform: none;
    }
  }
`
