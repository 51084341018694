import React from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import clsx from 'clsx'

const Breadcrumb: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <Container className='d-flex align-items-center ml-3'>
      {pathname.split('/').map((item, i, arr) => {
        if (!item) return null

        const isLastEl = i === arr.length - 1

        return (
          <span className={clsx('text-capitalize gray-text', { 'text-dark': isLastEl })} key={i}>
            {item} {!isLastEl && ' / '}
          </span>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  aligh-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 4rem;

  .gray-text {
    color: #abadb8;
  }
`

export default Breadcrumb
