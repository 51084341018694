import {FC, useRef, useState} from 'react';
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { MultiColumnSelect, Option } from '../../../components/MultiColumnSelect'
import { FilterTypes, ListTypes } from '../../filters/importProductsFilters'
import { ListsTable } from './ListsTable'
import {FaSearch} from "react-icons/fa";
import {RiCloseLine} from "react-icons/ri";
import {KeywordTable} from "./KeywordTable";
import {Keyword} from "./WhiteBlackListing";
import {triggerAlert} from "../../../components/Alert";
import * as uuid from "uuid";

type Props = {
  optionsc: Option[]
  childOptionsc: Option[]
  optionsb: Option[]
  manageParentLists: (option: Option, filterType: string, listType: string) => void
  manageChildOptionsc: (parent: string, listType: string, option?: Option) => void
  searchCategories: (name: string, listType: string, pageNumber: number, byScroll?: boolean) => void
  manageMultipleRows: (items: Option[], listType: string) => void
  manageParentWhitelists: (row: Keyword, title: string, remove?: boolean) => void
  categories: Option[]
  brands: Option[]
  brandWords: Keyword[]
  categoryWords: Keyword[]
  totalCount: number
  totalCategoryCount: number
  categoryLoading?: boolean
  planCount: number
  searchText: string
  manageSearchText: (val: string, listType: string) => void
}

const WhiteListing: FC<Props> = ({
  optionsc,
  childOptionsc,
  optionsb,
  manageChildOptionsc,
  manageParentLists,
  manageParentWhitelists,
  manageMultipleRows,
  searchCategories,
  categories,
  brands,
  totalCount,
  totalCategoryCount,
  categoryLoading,
  planCount,
  searchText,
  manageSearchText,
  brandWords,
  categoryWords
}) => {

  const brandRef = useRef<any>()
  const [brandWord, setBrandWord] = useState<string>('')
  const categoryRef = useRef<any>()
  const [categoryWord, setCategoryWord] = useState<string>('')

  function _removeRow(item: Option, filterType: string) {
    manageParentLists(item, filterType, ListTypes.WhiteList)
  }

  function _removeKeyword(row: Keyword, filterType: string) {
    manageParentWhitelists(row, filterType, true)
  }

  function _addRow(e: any, filterType: string, inputValue: string) {
    if (e.key === 'Enter' && inputValue.length) {
      switch (filterType) {
        case FilterTypes.Brand:
          if (brandWords.some((item) => item.brand === inputValue)) {
            triggerAlert('Brand already added...', 'error')
            return
          }
          setBrandWord('')
          break
        case FilterTypes.Category:
          if (categoryWords.some((item) => item.category === inputValue)) {
            triggerAlert('Category already added...', 'error')
            return
          }
          setCategoryWord('')
          break
      }

      manageParentWhitelists(
          {
            id: uuid.v1().toString(),
            title: inputValue,
            numberOfItems: Math.floor(Math.random() * 1000),
            asin: inputValue,
            updateDate: new Date(),
            brand: inputValue,
            category: inputValue
          },
          filterType
      )
    }
  }

  return (
    <Container>
      {/*<div className={`min-height-40`}>*/}
        <div className='header-two'>
          <span>WHITELISTING</span>
        </div>
        {/*<MultiColumnSelect*/}
        {/*  label='ASIN WHITELIST'*/}
        {/*  filterType={FilterTypes.Category}*/}
        {/*  listType={ListTypes.WhiteList}*/}
        {/*  placeHolder='Select a category'*/}
        {/*  options={optionsc}*/}
        {/*  childOptions={childOptionsc}*/}
        {/*  getChildOptions={manageChildOptionsc}*/}
        {/*  manageTableRow={manageParentLists}*/}
        {/*  manageMultipleRows={manageMultipleRows}*/}
        {/*  tableRows={categories}*/}
        {/*  searchCategories={searchCategories}*/}
        {/*  totalCategoryCount={totalCategoryCount}*/}
        {/*  loading={categoryLoading}*/}
        {/*  searchText={searchText}*/}
        {/*  manageSearchText={manageSearchText}*/}
        {/*/>*/}
        {/*{categories?.length ? (*/}
        {/*  <ListsTable*/}
        {/*    className=''*/}
        {/*    data={categories}*/}
        {/*    title='Category'*/}
        {/*    filterType={FilterTypes.Category}*/}
        {/*    removeRow={_removeRow}*/}
        {/*  />*/}
        {/*) : null}*/}
        {/*<hr className='my-10' />*/}
        {/*{categories?.length ? (*/}
        {/*  <div className='brands-select-wrapper'>*/}
        {/*    <label className='custom-label'>BRAND WHITELIST</label>*/}
        {/*    <div className='brands-select'>*/}
        {/*      <select*/}

        {/*      />*/}
        {/*      <div className='search-icon'>*/}
        {/*        <FaSearch color='#a1a5bd' />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*) : null}*/}
        {/*{brands?.length && categories?.length ? (*/}
        {/*  <ListsTable*/}
        {/*    className=''*/}
        {/*    data={brands}*/}
        {/*    title='Brand'*/}
        {/*    filterType={FilterTypes.Brand}*/}
        {/*    removeRow={_removeRow}*/}
        {/*  />*/}
        {/*) : null}*/}
      {/*</div>*/}
      {/*<hr className='my-1' />*/}
      <InputWrap>
        <div className='label'>
          <label className='custom-label'>BRAND WHITELIST</label>
        </div>
        <div className='input-icon-wrapper'>
          <input
              ref={brandRef}
              type='text'
              name='multicolumn'
              className='search-input'
              placeholder='Insert Brand'
              value={brandWord}
              onChange={(e) => setBrandWord(e.target.value)}
              onKeyDown={(e) => _addRow(e, FilterTypes.Brand, brandWord)}
          />
          <div className='icon-wrap'>
            <FaSearch color={'#a1a5bd'} />
          </div>
          <div
              className='clear-icon'
              onClick={() => {
                setBrandWord('')
                brandRef.current.focus()
              }}
          >
            <RiCloseLine color={'#a1a5bd'} fontSize={18} />
          </div>
        </div>
      </InputWrap>
      {brandWords?.length ? (
          <KeywordTable
              className=''
              data={brandWords}
              title='titleWord'
              removeRow={_removeKeyword}
              filterType={FilterTypes.Brand}
          />
      ) : null}
      <hr className='my-8' />
      <InputWrap>
        <div className='label'>
          <label className='custom-label'>CATEGORY WHITELIST</label>
        </div>
        <div className='input-icon-wrapper'>
          <input
              ref={categoryRef}
              type='text'
              name='multicolumn'
              className='search-input'
              placeholder='Insert Category'
              value={categoryWord}
              onChange={(e) => setCategoryWord(e.target.value)}
              onKeyDown={(e) => _addRow(e, FilterTypes.Category, categoryWord)}
          />
          <div className='icon-wrap'>
            <FaSearch color={'#a1a5bd'} />
          </div>
          <div
              className='clear-icon'
              onClick={() => {
                setCategoryWord('')
                categoryRef.current.focus()
              }}
          >
            <RiCloseLine color={'#a1a5bd'} fontSize={18} />
          </div>
        </div>
      </InputWrap>
      {categoryWords?.length ? (
          <KeywordTable
              className=''
              data={categoryWords}
              title='titleWord'
              removeRow={_removeKeyword}
              filterType={FilterTypes.Category}
          />
      ) : null}
      <hr className='my-8' />
      <div className='footer-content'>
        <h2>{`TOTAL AMOUNT OF PRODUCTS:  ${totalCount}`}</h2>
        {totalCount > planCount ? (
          <div className='info-text'>
            <img src={toAbsoluteUrl('/media/svg/misc/warning-info.svg')} className='mb-1 me-3' />
            <div className='d-flex flex-column'>
              <span>The amount of products to import exceeds your current subsription plan.</span>
              <span>Please, consider upgrading the plan to sell more items.</span>
            </div>
          </div>
        ) : null}
      </div>
    </Container>
  )
}

export { WhiteListing }

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .min-height-40 {
    min-height: 40vh;
  }

  .min-height-100 {
    min-height: 100vh;
  }

  .header-two {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .brands-select-wrapper {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
  }

  .brands-select {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .search-icon {
    position: absolute;
    top: 16px;
    left: 12px;
    cursor: pointer;
    z-index: 4;
  }

  .custom-label {
    min-width: 270px;
    font-size: 14px;
    font-weight: 600;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .info-text {
    border-radius: 8px;
    background-color: #fff4cc;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    padding: 12px;
    border: 1px solid #ffeeb3;
    flex-shrink: 1;
  }
`

const InputWrap = styled.div`
  margin-top: 60px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  width: 80%;

  .label {
    display: flex;
    justify-content: start;
    min-width: 270px;
  }

  .input-icon-wrapper {
    position: relative;
    width: 100%;
  }

  .icon-wrap {
    position: absolute;
    top: 15px;
    left: 11px;
  }

  .search-input {
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #cdcfda;
    padding: 11px 8px;
    outline: none;
    color: #a1a5bd;
    text-indent: 25px;
    width: 100%;
    color: #3f4254;
  }

  .search-input:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 3px #c0e7fd;
  }

  .clear-icon {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }
`
