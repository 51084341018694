import {FC, useContext, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {CustomHeader} from '../../components/CustomStyledComponents'
import {Pagination2} from '../../components/Pagination2'
import {PagingType} from '../../../setup/types/request-data-types/RequestDataTypes'
import {useSelector} from 'react-redux'
import {allStoreSelector, tokenSelector} from '../../modules/selectors/reduxSelectors'
import {
  NotificationType,
  StoreType,
} from '../../../setup/types/response-data-types/ResponseDataTypes'
import {notificationApis} from '../../../setup/apis/notification/notificationApis'
import {StoreSelect} from './StoreSelect'
import {NotificationContext} from '../../../_metronic/layout/MasterLayout'

type Props = {}

const notificationsTypes = {
  OrderNotifications: 'OrderNotifications',
  OtherUpdates: 'OtherUpdates',
}

const Notifications: FC<Props> = () => {
  const storeOptions = useSelector(allStoreSelector)
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const [selectedUpdate, setSelectedUpdate] = useState<string>(
    notificationsTypes.OrderNotifications
  )
  const [data, setData] = useState<NotificationType[]>([])
  const [totalCount, setTotalCount] = useState<number>(30)
  const [orderCount, setOrderCount] = useState<number>(0)
  const [orderUpdCount, setOrderUpdCount] = useState<number>(0)
  const [pagingData, setPagingData] = useState<PagingType>({pageNumber: 0, pageSize: 50, count: 0})
  const [loading, setLoading] = useState<boolean>()
  const notifications = useContext(NotificationContext)
  const token = useSelector(tokenSelector)
  const tokenRef = useRef<string>(token)
  const nTypeRef = useRef<string>(selectedUpdate)
  const storeIdRef = useRef<number>(selectedStore?.storeId)

  useEffect(() => {
    return () => {
      markAsRead(nTypeRef?.current, tokenRef?.current, storeIdRef?.current)
    }
  }, [])

  useEffect(() => {
    tokenRef.current = token
  }, [token])

  useEffect(() => {
    nTypeRef.current = selectedUpdate
  }, [selectedUpdate])

  useEffect(() => {
    storeIdRef.current = selectedStore?.storeId
  }, [selectedStore])

  useEffect(() => {
    if (storeOptions.length) {
      setSelectedStore(storeOptions[0])
      setPagingData((prev) => ({...prev, pageNumber: 0}))
      getNotifications(undefined, 0, storeOptions[0]?.storeId)
      getCounts(storeOptions[0]?.storeId)
    }
  }, [storeOptions])

  async function getNotifications(
    pSize?: number,
    pNumber?: number,
    storeId?: number,
    nType?: string
  ) {
    setLoading(true)
    try {
      const {data, totalCount}: any = await notificationApis.getPaging(
        {
          ...pagingData,
          pageSize: pSize ?? pagingData.pageSize,
          pageNumber: pNumber ?? pagingData.pageNumber,
        },
        storeId ?? (selectedStore?.storeId || 0),
        nType ?? selectedUpdate
      )
      setData(data || [])
      setTotalCount(totalCount)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  async function markAsRead(nType: string, token: string, stId?: number) {
    let storeId = (stId ?? selectedStore?.storeId) || 0
    try {
      await notificationApis.markAsRead(storeId, nType, token)
      switch (nType) {
        case notificationsTypes.OrderNotifications:
          setOrderCount(0)
          return
        case notificationsTypes.OtherUpdates:
          setOrderUpdCount(0)
          return
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getCounts(storeId?: number) {
    try {
      const response = await notificationApis.getUnreadCount(
        storeId ?? (selectedStore?.storeId || 0)
      )
      const callbackOrder = (item: any) =>
        item?.notificationType === notificationsTypes.OrderNotifications
      const callbackOther = (item: any) =>
        item?.notificationType === notificationsTypes.OtherUpdates
      setOrderCount((prev) => response?.find(callbackOrder)?.count || 0)
      setOrderUpdCount((prev) => response?.find(callbackOther)?.count || 0)
    } catch (e) {
      console.log(e)
    }
  }

  function _onPageSizeChange(pageSize: number) {
    setPagingData((prev) => ({...prev, pageSize, pageNumber: 0}))
    getNotifications(pageSize, 0)
  }

  function _onPageChange({selected}: any) {
    setPagingData((prev) => ({...prev, pageNumber: selected}))
    getNotifications(pagingData?.pageSize, selected)
  }

  async function _onStoreChange(option: StoreType) {
    await markAsRead(selectedUpdate, token)
    setSelectedStore((prev) => option)
    setPagingData((prev) => ({...prev, pageNumber: 0}))
    getNotifications(pagingData?.pageSize, 0, option?.storeId)
    getCounts(option?.storeId)
  }

  function _onTypeChange(nType: string) {
    setSelectedUpdate((prev) => {
      markAsRead(prev, token)
      return nType
    })
    setPagingData((prev) => ({...prev, pageNumber: 0}))
    getNotifications(pagingData?.pageSize, 0, selectedStore?.storeId, nType)
  }

  function renderNotifications(array: NotificationType[]) {
    return array.map((item: NotificationType, index) => (
      <div key={index} className='notification-container'>
        <div className='notification-message'>
          <div className='bulletos' />
          <span>{item.content}</span>
        </div>
        <div className='notification-time'>
          <span>{item.date}</span>
        </div>
      </div>
    ))
  }

  return (
    <Container>
      <div className='store-select'>
        <label className='form-label fw-bold'>Select Store</label>
        <StoreSelect
          value={selectedStore}
          onChange={(option) => _onStoreChange(option)}
          options={storeOptions}
          inputClassName={'input-value-store'}
          storesWithRedDot={notifications?.unreadNotificationCountByStoreModel}
        />
      </div>
      <div className='d-flex align-items-start'>
        <div className='left-side'>
          <div className='left-menu'>
            <div
              className={`orders-container ${
                selectedUpdate === notificationsTypes.OrderNotifications ? 'active-update' : ''
              }`}
              onClick={() => _onTypeChange(notificationsTypes.OrderNotifications)}
            >
              <div className='d-flex'>
                <img src={toAbsoluteUrl('/media/svg/misc/cart.svg')} className='icon' />
                <div>
                  <span className='update-header'>Orders</span>
                  <span className='update-info'>Updates concerning latest orders</span>
                </div>
              </div>
              {orderCount ? <div className='num-of-updates'>{orderCount}</div> : null}
            </div>
            <hr className='custom-hr' />
            <div
              className={`other-updates-container ${
                selectedUpdate === notificationsTypes.OtherUpdates ? 'active-update' : ''
              }`}
              onClick={() => _onTypeChange(notificationsTypes.OtherUpdates)}
            >
              <div className='d-flex'>
                <img src={toAbsoluteUrl('/media/svg/misc/folder.svg')} className='icon' />
                <div>
                  <span className='update-header'>Other Updates</span>
                  <span className='update-info'>Latest Updates</span>
                </div>
              </div>
              {orderUpdCount ? <div className='num-of-updates'>{orderUpdCount}</div> : null}
            </div>
          </div>
          <div className='image'>
            <img
              src={toAbsoluteUrl('/media/downloaded-images/Notification.png')}
              width={350}
              height={350}
            />
          </div>
        </div>
        <div className='right-side'>
          <CustomHeader>Notifications</CustomHeader>
          <hr className='m-0' />
          <div className='notifications-wrapper'>
            {loading ? <span className='information'>Loading...</span> : null}
            <div className='notifications'>
              {data?.length && !loading ? renderNotifications(data) : null}
            </div>
            {!data?.length && !loading ? (
              <span className='information'>There are no notifications for now</span>
            ) : null}
            <div>
              <Pagination2
                currentPage={pagingData?.pageNumber}
                pageSize={pagingData?.pageSize}
                totalCount={totalCount}
                onPageChange={_onPageChange}
                withPageSizeSelect
                onPageSizeChange={_onPageSizeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export {Notifications}

const Container = styled.div`
  display: flex;
  flex-direction:column;
  min-width:1200px;
  margin-bottom:48px;

  .pagination-container {
    border-top: 1px solid #80cffb;
    border-bottom: 1px solid #80cffb;
    padding-block:5px;
  }

  .notifications-wrapper {
    padding:24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    width:100%;
    flex:1;
  }

  .notifications {
    padding-right: 10px;
    margin-bottom: 10px;
    height: 350px;
    overflow: auto;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
    }
  
    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }
  
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }
  
    :hover::-webkit-scrollbar-thumb {
      background-color: #40b6f9;
    }
  
    scrollbar-width: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .notifications-header {
    color:#A1A5BD;
    font-size:14px; 
    font-weight:500;
  }

  .notification-container {
    display:flex;
    padding-block:12px;
    border-bottom:1px dashed #cdcfda; 
    justify-content:space-between;
    align-items:center;
  }
  
  .notification-container:last-child {
    border-bottom:none;
  }
  
  .notification-message {
    display:flex;
    align-items:center;
    color:#3F4254;
    font-size:14px; 
    font-weight:400;
  }

  .notification-time {
    color:#A1A5BD;
    font-size:14px; 
    font-weight:500;
    margin-right:48px;
  }
  
  .bulletos {
    width:6px;
    height:6px;
    background-color:#50CD89;
    border-radius:50%;
    margin-right:5px;
  }
  
  
  .store-select {
    margin:0 0 24px 424px;
    width:500px;
  }
  
  .left-side {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  
  .left-menu {
    display: flex;
    flex-direction: column;
    width:400px;
    box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
    cursor: pointer;
  }

  .right-side {
    position:relative;
    display: flex;
    flex-direction: column;
    box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
    background-color:#ffffff;
    border-radius:8px;
    width:836px;
    margin-left:24px;
  }
  
  .update-header {
    font-size: 18px;
    font-weight: 500;
    color: #3f4254;
    display:block;
  }

  .update-info {
    font-size:12px
    font-weight:500;
    color:#A1A5BD;
    display:block;
  }

  .orders-container,
  .other-updates-container {
    padding:19px 24px;
    background-color: #ffffff;
    display: flex;
    align-items:center;
    justify-content: space-between;
  }


  .orders-container {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .other-updates-container {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .active-update {
    background-color: #E6F5FE;
  }

  .custom-hr {
    margin: 0;
    border-top: 1px dashed #cdcfda;
  }

  .image {
    margin-top: 40px;
  }

  .num-of-updates {
    background-color: #009EF7;
    color:#ffffff;
    font-size:18px;
    font-weight:500;
    padding:0 12px;
    border-radius:50px;
  }

  .icon {
    margin-right:10px;
  }

  .information {
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    font-size:16px;
    font-weight:500;
    color: #A1A5BD;
  }

  .input-value,
  .input-value-store {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;
  }

  .input-value-store {
    font-size:16px;
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #2684ff;
  }

`
