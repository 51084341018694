/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useHistory} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import Breadcrumb from './Breadcrumb'
import {getUserId, logout} from "../../../../app/utils/authUtils";
import {AsideMenuItem} from "../aside/AsideMenuItem";
import styled from "styled-components";

import {accountApis} from '../../../../setup/apis/account/accountApis'
import {tokenSelector} from '../../../../app/modules/selectors/reduxSelectors'

export type AccountType = {
  firstName: string
  lastName: string
  username: string
  email: string
  password: string
  newPassword: string
  currentPassword: string
  countryId: number
  countryName: string
}

export const initialData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '------',
  newPassword: '',
  currentPassword: '',
  countryId: -1,
  countryName: '',
  username: '',
}

export type MenuItemType = {
  icon: string
  title: string
  link: string
  description?: string
}
const dropDownMenu: MenuItemType[] = [
  {
    icon: '/media/icons/duotone/General/Settings-2.svg',
    title: 'Account settings',
    link: '/settings/account-settings',
    description: 'Account settings (name, password and more)',
  },
]

export function HeaderWrapper() {
  const {pathname} = useLocation()
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const token = useSelector(tokenSelector)
  const [data, setData] = useState<AccountType>(initialData)
  const history = useHistory()

  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  async function getData() {
    const response = await accountApis.getUserById(getUserId(token))
    setData(
      {
        ...response,
      } || initialData
    )
  }

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotone/Text/Menu.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/assets/img/MarketGap-Logo-Icon.png')}
                className='h-30px'
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/assets/img/MarketGap-Logo-Icon.png')}
                className='h-30px'
              />
            </Link>
          </div>
        )}

        <Breadcrumb />

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              {/* <Header /> */}
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              {/* <DefaultTitle /> */}
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}

        {/* <div className='align-self-center'>
          <span className='me-3 '>
            {data.firstName} ({data.email})
          </span>
        </div> */}

        <div onClick={() => setMenuOpen((prev) => !prev)} className='dropdown align-self-center btn btn-icon w-auto btn-active-light d-flex align-items-center btn-lg px-2'>
          <HiButton className='d-none d-md-inline me-1'>Hi,</HiButton>{' '}
          <NameButton className='d-none d-md-inline me-3'>
            {data.firstName} {data.lastName}
          </NameButton>
          <span className='symbol symbol-35px'>
            <span className='symbol-label fs-5 fw-semibold bg-light-success text-success'>
              {data.firstName.slice(0, 1)}
            </span>
          </span>

          {menuOpen && <DropDownContainer
          x-placement='bottom-end'
          aria-labelledby=''
        >
          <DropDownHeader
            className='d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top'
          >
            <div className='symbol bg-white-o-15 me-3'>
              <span className='symbol-label text-success fw-semibold fs-3'>{data.firstName.slice(0, 1)}</span>
            </div>
            <div className='text-white m-0 flex-grow-1 me-3 flex-column'><div className="fs-3">{data.firstName} {data.lastName}</div><div className="fw-semibold fs-5">{data.email}</div></div>
            {/* <span className='label label-success label-lg fw-semibold label-inline'>
              3 messages
            </span> */}
          </DropDownHeader>
          <NaviContainer>

            { dropDownMenu.map((item, index) => (
            <div onClick={() => history.push(item.link)} className='navi-item px-8 cursor-pointer'>
              <DropDownMenuItem className='navi-link'>
                <NaviIcon>
                  <KTSVG path={item.icon} className='svg-icon-2' />
                </NaviIcon>
                <div className='navi-text'>
                  <div className='fw-bold cursor-pointer'>{item.title}</div>
                  <div className='text-muted'>
                    {item.description}
                  </div>
                </div>
              </DropDownMenuItem>
            </div>
            ))}

            <NaviSeparator />
            <div className='navi-footer px-8 py-5'>
              <a
                className='btn btn-light-primary fw-bold'
                onClick={() => logout()} 
              >
                Sign Out
              </a>
              {/* <a href='#' className='btn btn-clean font-weight-bold'>
                Upgrade Plan
              </a> */}
            </div>
          </NaviContainer>
        </DropDownContainer>}
        </div>

      

        {/* <SignOutButton onClick={() => logout()} className='align-self-center'>
          <a href='#' className='btn btn-success'>
            Sign out
          </a>
        </SignOutButton> */}
      </div>
    </div>
  )
}

const NaviIcon = styled.div`
flex: 0 0 2rem;
margin-right: 0.5rem!important;
`;
const DropDownMenuItem = styled.div`
transition: all .15s ease;
color: #3f4254;
display: flex;
font-size: 1rem;
align-items: center;
padding-top: 0.75rem;
padding-right: 0rem;
padding-bottom: 0.75rem;
padding-left: 0rem;
`;

const NaviSeparator = styled.div`
margin: 0;
margin-top: 0.75rem!important;
padding: 0;
height: 0;
display: block;
border-bottom: 1px solid #ebedf3;
`
const NaviContainer = styled.div`
padding: 0;
display: block;
list-style: none;
margin: 0;
padding-top: 1.25rem!important;
`;

const DropDownHeader = styled.div`
background-image: url("/assets/img/bg-dropdown.jpeg");
`;

const DropDownContainer = styled.div`
position: absolute; 
inset: 65px 0px auto 0px; 
margin: 0px; 
left: auto;
width: 400px;
display: block;
padding: 0!important;
margin: 0!important;
z-index: 96;
min-width: 10rem;
font-size: 1rem;
color: #3f4254;
text-align: left;
list-style: none;
background-color: #fff;
background-clip: padding-box;
border: 0 solid rgba(0,0,0,.15);
border-radius: 0.42rem;
box-shadow: 0 0 50px 0 rgba(82,63,105,.15);
`;
const HiButton = styled.span`
  color: #b5b5c3!important; 
  font-size: 1rem!important;
  font-weight: 500!important;

`;

const NameButton = styled.span`
  color: #7e8299!important;
  font-size: 1rem!important;
  font-weight: 600!important;
`;

const SignOutButton = styled.div`
background-image: url("/metronic/react/demo1/media/misc/bg-1.jpg");
`;

// const SignOutButton = styled.div`
//   display: flex;
//   align-items: center;
//   span {
//     color: #009EF7;
//     margin-inline-end: 30px;
//   }
// `;