import styled from 'styled-components'

export const Container = styled.div`
  min-width: 1260px;
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-height: 80vh;
  margin-bottom: 48px;

  .stores-wrapper {
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  .list-wrapper {
    position: relative;
  }

  .no-stores {
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .store {
    width: 40%;
    margin: 20px 60px;

    .data-container {
      margin-top: -150px;
    }

    .upper-part {
      background-color: #d9f1fe;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
    }

    .action-button {
      padding: 5px 20px;
      background-color: #e4e6ef;
      color: #3f4254;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }

    .action-button:hover {
      background-color: #e4e6ef;
      color: #3f4254;
    }

    .action-button:active {
      background-color: #e4e6ef;
      color: #3f4254;
    }

    .prop-name {
      font-size: 16px;
      color: #707070;
    }
  }

  .button-create {
    margin-inline: 24px;
  }

  .icon {
    margin-bottom: 2px;
  }

  .bulletos {
    width: 5px;
    height: 5px;
    background-color: #707070;
    border-radius: 50%;
    margin-right: 3px;
  }
`

export const HalfContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-block: 50px;
`

export const FullContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-block: 50px;
`

export const StyledDiv90 = styled.div`
  width: 90%;
`

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  width: 80%;
  margin: 30px auto;

  span {
    font-size: 16px;
    font-weight: bold;
  }

  a {
    color: #333;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
`;
