import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import ReactGA from "react-ga4";

function StripeRedirection(props: any) {
  const user = useSelector((st: RootState) => st?.user)
  ReactGA.event({
    category: 'User',
    action: 'Clicked Button',
    label: 'PaymentInfoCollected',
  });

  useEffect(() => {
    if (user) {
      window.location.href = props?.history?.location?.state?.url || ''
    }
  }, [user])
  return <h1 className='m-auto'>Redirecting To Stripe...</h1>
}

export { StripeRedirection }
