import { useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { debounce } from 'lodash'
import {activeStoreSelector} from "../../../modules/selectors/reduxSelectors";
import {StoreType} from "../../../../setup/types/response-data-types/ResponseDataTypes";
import useWhiteBlackListSelect from "../../../hooks/useWhiteBlackListSelect";
import {FilterTypes, SelectEventKeys} from "../../filters/importProductsFilters";
import {importProductsApis} from "../../../../setup/apis/product/importProductsApis";
import {categoryApis} from "../../../../setup/apis/category/categoryApis";
import {CustomHeader} from "../../../components/CustomStyledComponents";
import StoreSelect from "../../../components/StoreSelect";
import SearchBar from "../../../components/SearchBar";
import View from "../../../components/View";
import Accordion from "../../../components/Accordion";



interface Item {
    categoryId: string
    categoryName: string
    parentCategoryName: string
    parentCategoryId: string
    childCategoryCount: number
    productCount: number
    totalProductCount: number
    value?: string
}
export interface ICategories extends Item {
    children: ICategories[]
}

const OnboardingCategoriesPage = () => {
    const storeOptions = useSelector(activeStoreSelector)
    const [searchValue, setSearchValue] = useState<string>('')
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
    const [categoryWhiteList, setCategoryWhiteList] = useState<string[]>([])
    const [categoryBlackList, setCategoryBlackList] = useState<string[]>([])
    const [categoryList, setCategoryList] = useState<ICategories[]>([])
    const [filterIsLoading, setFilterIsLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!!selectedStore) {
            getFilters(selectedStore.storeId)
        }
    }, [selectedStore?.storeId])

    useEffect(() => {
        getCategories(0)
    }, [])

    const { manageList, manageDefaultList } = useWhiteBlackListSelect(
        categoryWhiteList,
        categoryBlackList,
        selectedStore?.storeId,
        FilterTypes.Category,
        setCategoryWhiteList,
        setCategoryBlackList
    )

    async function getFilters(storeId: number) {
        try {
            setFilterIsLoading(true)
            const response = await importProductsApis.getFilter(storeId)
            setCategoryWhiteList(response.categotyWhiteList?.map((item: Item) => item.value))
            setCategoryBlackList(response.categotyBlackList?.map((item: Item) => item.value))
        } catch (err) {
            console.log('error', err)
        } finally {
            setFilterIsLoading(false)
        }
    }

    async function getCategories(page: number) {
        try {
            setIsLoading(true)
            const res = await categoryApis.getAllTree({ page, pageSize: 50 })
            setCategoryList(res.data)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    function handleStoreSelect(option: StoreType) {
        setSelectedStore(option)
    }

    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        setIsSearching(value.length >= 3)
        setSearchValue(value)
    }

    const debouncedSearchChangeHandler = useCallback(debounce(handleSearchValueChange, 600), [])

    function handleWhiteBlackSelect(eventKey: string | null, id: string, selectedValue: string) {
        if (eventKey !== selectedValue) {
            if (eventKey === SelectEventKeys.Default) {
                manageDefaultList(id)
            } else {
                manageList(eventKey, id)
            }
        }
    }

    const categoriesList = useMemo(
        () =>
            searchCategories(categoryList, ({ categoryName }: { categoryName: string }) =>
                categoryName.toLowerCase().includes(searchValue)
            ),
        [categoryList, searchValue]
    )

    return (
        <Container>
            <CustomHeader marginl='0'>Discover Categories</CustomHeader>

            <hr />

            <SearchBar handleChange={debouncedSearchChangeHandler} page='Categories' />

            <div className='accordion-title'>CATEGORY NAME</div>
            <View isLoading={isLoading}>
                <Accordion
                    isSearching={isSearching}
                    accordionData={categoriesList}
                    categoryWhiteList={categoryWhiteList}
                    categoryBlackList={categoryBlackList}
                    handleSelect={handleWhiteBlackSelect}
                />
            </View>
        </Container>
    )
}

function searchCategories(
    categories: ICategories[],
    func: ({ categoryName }: { categoryName: string }) => boolean
) {
    return categories.reduce((acc: any, cur: any) => {
        let children = searchCategories(cur.children || [], func)
        if (func(cur) || children.length)
            acc.push(Object.assign({}, cur, children.length && { children }))
        return acc
    }, [])
}

const Container = styled.div`
  .accordion-title {
    color: #95aac9;
    background: #f9fbfd;
    border: 1px solid #edf2f9;
    padding: 12px 15px;
    width: 100%;
  }

  .loading-info {
    font-size: 18px;
    font-weight: 500;
    margin-right: 0.75rem;
  }
`

export default OnboardingCategoriesPage
