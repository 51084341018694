import React, { SetStateAction } from 'react'

import useRemoveFilter from '../hooks/useRemoveFilter'
import { ButtonDanger } from './CustomStyledComponents'

interface IProps<T> {
  storeId: number
  selectedId: string
  value: string
  listType: string
  filterType: string
  setSelectedId: (val: string) => void
  setWhiteList: (val: SetStateAction<T[]>) => void
  setBlackList: (val: SetStateAction<T[]>) => void
  filterCallback?: (list: T[], value: string) => T[]
}

function RemoveButton<T>({
  storeId,
  selectedId,
  value,
  listType,
  filterType,
  setSelectedId,
  setWhiteList,
  setBlackList,
  filterCallback,
}: IProps<T>) {
  const { remove, isLoading } = useRemoveFilter({
    storeId,
    setWhiteList,
    setBlackList,
    filterCallback,
  })

  return (
    <ButtonDanger
      fontSize={10}
      paddingx={18}
      paddingy={5}
      bordertr={8}
      bordertl={8}
      borderbr={8}
      borderbl={8}
      minWidth={77}
      disabled={isLoading && selectedId === value}
      onClick={() => {
        setSelectedId(value)
        remove(listType, filterType, value)
      }}
    >
      {isLoading && selectedId === value ? (
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      ) : (
        <>Remove</>
      )}
    </ButtonDanger>
  )
}

export default RemoveButton
