import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

type Props = {
  title?: string;
  content?: string;
  faqHandler: () => void;
}

export const Footer: FC<Props> = ({ title, content, faqHandler }) => {
  const history = useHistory()
  const currentYear = new Date().getFullYear();

  return (
    // <!-- Footer -->
        <footer className="mt-[120px] pb-8 w-5/6 mx-auto flex flex-col gap-[42px]">
           
            {/* <!-- divider --> */}
            <div className="h-px opacity-80 bg-zinc-400"></div>

            {/* <!-- Footer menu – v.2.0 --> */}
            <div>
                <div className="flex justify-between items-start flex-wrap gap-y-[50px]">
                    {/* <!-- Menu --> */}

                     {/* <!-- Logo --> */}
                    <div className="shrink-0 flex justify-start items-center gap-[8.59px]">
                    {/* <p className="text-zinc-900 text-[25px] font-bold font-['Open_Sans'] leading-loose">Market Gap</p> */}
                        <span className='sr-only'>MarketGap</span>
                        <img className='h-8 w-auto' src='/assets/img/MarketGap-logo.png' alt='MarketGap' />
                    </div>
                    
                    
                    
                    <div className="flex justify-start items-start gap-[50px] flex-wrap">
                        {/* <!-- Email --> */}
                        <div className="flex justify-start items-center gap-4 tw-order-2 md:order-1">
                            <div>
                                <img className="w-[24px] h-[24px]" src="./assets/icons/Message.svg" />
                            </div>
                            <div>
                                <div className="text-slate-900 text-base font-medium font-['Inter'] tracking-tight">Email</div>
                                <div className="mt-[9px] text-sky-500 text-base font-medium font-['Inter'] tracking-tight"><a href="mailto:#">support@marketgap.com</a></div>
                            </div>

                        </div>
                        {/* <!-- Menu items --> */}
                        <div className="flex flex-col justify-start items-start gap-[19px] tw-order-1 md:order-2">
                            <p className="text-slate-900 text-base font-bold font-['Inter'] leading-tight">Help</p>
                            <p className="text-gray-600 text-base font-medium font-['Inter'] leading-normal"><a className="cursor-pointer" onClick={faqHandler}>FAQs</a></p>
                            <p className="text-gray-600 text-base font-medium font-['Inter'] leading-normal"><a className="cursor-pointer" onClick={() => history.push('/terms-conditions')}>Terms & Conditions</a></p>
                            <p className="text-gray-600 text-base font-medium font-['Inter'] leading-normal"><a className="cursor-pointer" onClick={() => history.push('/privacy-policy')}>Privacy Policy</a></p>
                            <p className="text-gray-600 text-base font-medium font-['Inter'] leading-normal"><a className="cursor-pointer" onClick={() => history.push('/contact-us')}>Contact Us</a></p>
                        </div>
                    </div>
                    {/* <!-- Subscription form --> */}
                    <div className="flex flex-col justify-end items-start gap-[29px] w-full md:max-w-full lg:max-w-fit">
                         {/* <!-- Join now CTA --> */}
                        <div className="w-full min-w-0 flex flex-col px-px justify-end items-center gap-2">
                            <div className="w-full sm:w-fit mt-3 sm:mt-0 text-gray-600 text-lg font-medium font-['Inter'] leading-6 lg:leading-7">Ready to get started?</div>
                            <div onClick={() => history.push('/registration/create-account')} className="cursor-pointer w-full sm:w-fit mt-4 sm:mt-0 sm:w-[173px] px-[46px] shrink-0 py-3.5 bg-sky-500 rounded-[90px] flex justify-center items-center gap-2.5">
                                <div className="text-white text-lg font-bold font-['Inter'] leading-tight">Join Now For Free</div>
                            </div>
                        </div>
                        {/*<p className="self-stretch text-blue-950 text-xl font-semibold font-['Inter'] leading-[30px]">Subscribe to our newsletter</p>*/}

                        {/*<div className="self-stretch h-[50px] flex flex-col justify-between items-start">*/}
                        {/*    <div className="self-stretch justify-between items-center inline-flex">*/}
                        {/*        <div className="relative w-full">*/}
                        {/*            <input type="email" name="email" id="email" className="p-0 peer block w-full border-0 focus:ring-0 h-[50px] text-gray-600 placeholder:text-gray-600 placeholder:opacity-70 placeholder:text-base text-base placeholder:font-['Inter'] font-['Inter'] placeholder:font-medium font-medium placeholder:leading-relaxed leading-relaxed" placeholder="Email address" />*/}
                        {/*            <div className="absolute inset-x-0 bottom-0 border-t border-stone-300 peer-focus:border-t-2 peer-focus:border-sky-500" aria-hidden="true"></div>*/}
                        {/*        </div>*/}
                        {/*        <div className="p-[15px] bg-sky-500 rounded-tl-lg rounded-tr-lg justify-start items-start gap-2.5 flex">*/}
                        {/*            <button type="submit" className="w-5 h-5">*/}
                        {/*                <img className="w-full h-full" src="./assets/icons/keyboard-arrow-right.svg" />*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>

                </div>
            </div>
            {/* <!-- divider --> */}
            <div className="h-px opacity-80 bg-zinc-400"></div>
            <div className="mx-auto sm:w-full h-6 sm:flex sm:justify-between sm:items-center">
                {/* <!-- Social media icons --> */}
                <div className="sm:order-2 flex justify-start items-center gap-10 lg:gap-10">
                    {/* <img className="block shrink w-[10px] h-[20px]" src="./assets/icons/facebook.svg" /> */}
                    {/* <img className="block shrink w-[22px] h-[18px]" src="./assets/icons/twitter.svg" /> */}
                    {/* <img className="block shrink w-[22px] h-[22px]" src="./assets/icons/instagram.svg" /> */}
                </div>
                
                {/* <!-- Text items --> */}
                <div className="mt-4 sm:mt-0 sm:order-1 sm:flex justify-start items-start gap-2 lg:gap-6 grow-0">
                        {/* <p className="text-center grow-0 text-gray-600 text-xs sm:text-sm lg:text-base font-medium font-['Inter'] leading-4 lg:leading-normal"><button className="cursor-pointer" onClick={() => history.push('/terms-conditions')}>Terms & Conditions</button></p> */}
                        {/* <p className="text-center grow-0 text-gray-600 text-xs sm:text-sm lg:text-base font-medium font-['Inter'] leading-4 lg:leading-normal"><button className="cursor-pointer" onClick={() => history.push('/privacy-policy')}>Privacy Policy</button></p> */}
                </div>
                

            </div>
            <div className="text-center text-gray-600 text-xs sm:text-sm lg:text-base">
            © {currentYear} MarketGap, Inc. All Rights Reserved. 
            </div>

        </footer>
  )
};