import { FC, useRef, useState } from 'react'
import * as uuid from 'uuid'
import { MultiColumnSelect, Option } from '../../../components/MultiColumnSelect'
import { MultiRangeSlider } from '../../../components/MultiRangeSlider'
import { ListsTable } from './ListsTable'
import { KeywordTable } from './KeywordTable'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa'
import { Keyword, Range } from './WhiteBlackListing'
import moment from 'moment'
import { RiCloseLine } from 'react-icons/ri'
import { FilterTypes, ListTypes, PropertyTypes } from '../../filters/importProductsFilters'
import { triggerAlert } from '../../../components/Alert'


type InsertItems = {
  categories: string[]
  brands: string[]
  titleWords: string[]
  titlePhrases: string[]
  asins: string[]
}

type Props = {
  optionsc: Option[]
  childOptionsc: Option[]
  optionsb: Option[]
  manageParentLists: (option: Option, filterType: string, listType: string) => void
  manageParentKeywords: (row: Keyword, title: string, remove?: boolean) => void
  manageParentRanges: (range: Range, title: string) => void
  manageChildOptionsc: (parent: string, listType: string, option?: Option) => void
  manageMultipleRows: (items: Option[], info: string) => void
  searchCategories: (name: string, listType: string, pageNumber: number, byScroll?: boolean) => void
  categories: Option[]
  brands: Option[]
  titleWords: Keyword[]
  titlePhrases: Keyword[]
  brandWords: Keyword[]
  asins: Keyword[]
  weightRange: Range
  volumeRange: Range
  priceRange: Range
  totalCount: number
  searchText: string
  manageSearchText: (val: string, listType: string) => void
}

const BlackListing: FC<Props> = ({
  optionsc,
  childOptionsc,
  optionsb,
  manageParentLists,
  manageMultipleRows,
  manageParentRanges,
  manageParentKeywords,
  categories,
  brands,
  brandWords,
  titleWords,
  titlePhrases,
  asins,
  weightRange,
  volumeRange,
  priceRange,
  manageChildOptionsc,
  totalCount,
  searchCategories,
  searchText,
  manageSearchText,
}) => {

  const defaultAddableItems = {
    categories: [],
    brands: [],
    titleWords: [],
    titlePhrases: [],
    asins: [],
  }

  const [titleWord, setTitleWord] = useState<string>('')
  const [titlePhrase, setTitlePhrase] = useState<string>('')
  const [brandWord, setBrandWord] = useState<string>('')
  const [asin, setAsin] = useState<string>('')
  const [addableItems, setAddableItems] = useState<InsertItems>(defaultAddableItems)
  const titleRef = useRef<any>()
  const phraseRef = useRef<any>()
  const brandWordRef = useRef<any>()
  const asinRef = useRef<any>()

  function _removeRow(item: Option, filterType: string) {
    manageParentLists(item, filterType, ListTypes.BlackList)
  }

  function _removeKeyword(row: Keyword, filterType: string) {
    manageParentKeywords(row, filterType, true)
  }


  function _saveItems(filterType: string, inputValue: string) {
    if(!inputValue || !inputValue.length) {
      triggerAlert('No entries found', 'error')
    }

    let allItems = inputValue.split(/\r?\n|\r|\n/g);
    if(allItems.length > 250) {
      triggerAlert('You may only upload 250 items at a time', 'error')
      return;
    }

    const hack = {
      key: 'Enter',
      isBulk: true
    }
    _addRow(hack, filterType, inputValue);
  }

  function _addRow(e: any, filterType: string, inputValue: string) {
    if (filterType !== FilterTypes.TitlePrase && e.key === ' ') e.preventDefault()
    if (e.key === 'Enter' && inputValue.length) {
      switch (filterType) {
        case FilterTypes.TitleWord:
          if (titleWords.some((item) => item.title === inputValue)) {
            triggerAlert('Keyword already added...', 'error')
            return
          }
          setTitleWord('')
          break
        case FilterTypes.TitlePrase:
          if (inputValue.trim().split(' ').length <= 1) {
            triggerAlert('Only phrases are allowed.', 'error')
            return
          }
          if (titlePhrases.some((item) => item.title === inputValue)) {
            triggerAlert('Keyphrase already added...', 'error')
            return
          }
          setTitlePhrase('')
          break
        case FilterTypes.Asin:
          // if (asins.some((item) => item.asin === inputValue)) {
          //   triggerAlert('Asin already added...', 'error')
          //   return
          // }
          setAsin('')
          break
      }
      manageParentKeywords(
        {
          id: uuid.v1().toString(),
          title: inputValue,
          numberOfItems: Math.floor(Math.random() * 1000),
          asin: inputValue,
          updateDate: new Date(),
          brand: inputValue,
        },
        filterType
      )
    }
  }

  return (
    <Container>
      <div className='header-two'>
        <span>BLACKLISTING</span>
      </div>
      {/*<MultiColumnSelect*/}
      {/*  label='CATEGORY BLACKLIST'*/}
      {/*  placeHolder='Select a category'*/}
      {/*  options={optionsc}*/}
      {/*  childOptions={childOptionsc}*/}
      {/*  getChildOptions={manageChildOptionsc}*/}
      {/*  manageTableRow={manageParentLists}*/}
      {/*  manageMultipleRows={manageMultipleRows}*/}
      {/*  tableRows={categories}*/}
      {/*  searchCategories={searchCategories}*/}
      {/*  filterType={FilterTypes.Category}*/}
      {/*  listType={ListTypes.BlackList}*/}
      {/*  searchText={searchText}*/}
      {/*  manageSearchText={manageSearchText}*/}
      {/*/>*/}
      {/*{categories?.length ? (*/}
      {/*  <ListsTable*/}
      {/*    className=''*/}
      {/*    data={categories}*/}
      {/*    title='Category'*/}
      {/*    filterType={FilterTypes.Category}*/}
      {/*    removeRow={_removeRow}*/}
      {/*  />*/}
      {/*) : null}*/}
      {/*<hr className='my-15' />*/}
      {/* {categories?.length ? (
        <div className='brands-select-wrapper'>
          <label className='custom-label'>BRAND BLACKLIST</label>
          <div className='brands-select'>
            <Select
              name='layout-builder[layout][header][width]'
              value={''}
              onChange={(option) => manageParentLists(option, 'BRAND BLACKLIST')}
              options={optionsb.filter(
                (item) => brands.findIndex((it) => item.id === it.id) === -1
              )}
              placeholder='Select a brand'
              withRs
              withSearch
            />
            <div className='search-icon'>
              <FaSearch color='#a1a5bd' />
            </div>
          </div>
        </div>
      ) : null}
      {brands?.length && categories?.length ? (
        <ListsTable className='' data={brands} title='Brand' removeRow={_removeRow} />
      ) : null} 
      {categories?.length ? <hr className='my-15' /> : null} */}
      <InputWrap>
        <div className='label'>
          <label className='custom-label'>BRAND BLACKLIST</label>
        </div>
        <div className='input-icon-wrapper'>
          <textarea
              ref={brandWordRef}
              name='multicolumn'
              className='search-input no-search-icon type_textarea'
              placeholder='Insert brands (use linebreaks to add multiple)'
              value={brandWord}
              onChange={(e) => setBrandWord(e.target.value)}
          />
          <div
              className='clear-icon'
              onClick={() => {
                setBrandWord('')
                brandWordRef.current.focus()
              }}
          >
            <RiCloseLine color={'#a1a5bd'} fontSize={18} />
          </div>
          <SaveButtonContainer>
            <SaveButton
                onClick={(e) => _saveItems(FilterTypes.Brand, brandWord)}
            >
              Save
            </SaveButton>
          </SaveButtonContainer>
        </div>
      </InputWrap>
      {brandWords?.length ? (
          <KeywordTable
              className=''
              data={brandWords}
              title='titleWord'
              removeRow={_removeKeyword}
              filterType={FilterTypes.Brand}
          />
      ) : null}
      <hr className='my-15' />
      <InputWrap>
        <div className='label'>
          <label className='custom-label'>TITLE WORD BLACKLIST</label>
        </div>
        <div className='input-icon-wrapper'>
          <textarea
            ref={titleRef}
            name='multicolumn'
            className='search-input no-search-icon type_textarea'
            placeholder='Insert title words (use linebreaks to add multiple)'
            value={titleWord}
            onChange={(e) => setTitleWord(e.target.value)}
          />
          {/*<div className='icon-wrap'>*/}
          {/*  <FaSearch color={'#a1a5bd'} />*/}
          {/*</div>*/}
          <div
            className='clear-icon'
            onClick={() => {
              setTitleWord('')
              titleRef.current.focus()
            }}
          >
            <RiCloseLine color={'#a1a5bd'} fontSize={18} />
          </div>
          <SaveButtonContainer>
            <SaveButton
                onClick={(e) => _saveItems(FilterTypes.TitleWord, titleWord)}
            >
              Save
            </SaveButton>
          </SaveButtonContainer>
        </div>
      </InputWrap>
      {titleWords?.length ? (
        <KeywordTable
          className=''
          data={titleWords}
          title='titleWord'
          removeRow={_removeKeyword}
          filterType={FilterTypes.TitleWord}
        />
      ) : null}
      <hr className='my-15' />
      <InputWrap>
        <div className='label'>
          <label className='custom-label'>TITLE PHRASE BLACKLIST</label>
        </div>
        <div className='input-icon-wrapper'>
          <textarea
            ref={phraseRef}
            name='multicolumn'
            className='search-input no-search-icon type_textarea'
            placeholder='Insert title phrases (use linebreaks to add multiple)'
            value={titlePhrase}
            onChange={(e) => setTitlePhrase(e.target.value)}
          />
          <div
            className='clear-icon'
            onClick={() => {
              setTitlePhrase('')
              phraseRef.current.focus()
            }}
          >
            <RiCloseLine color={'#a1a5bd'} fontSize={18} />
          </div>
          <SaveButtonContainer>
            <SaveButton
                onClick={(e) => _saveItems(FilterTypes.TitlePrase, titlePhrase)}
            >
              Save
            </SaveButton>
          </SaveButtonContainer>
        </div>
      </InputWrap>
      {titlePhrases?.length ? (
        <KeywordTable
          className=''
          data={titlePhrases}
          title='titlePhrase'
          removeRow={_removeKeyword}
          filterType={FilterTypes.TitlePrase}
        />
      ) : null}
      <hr className='my-15' />
      <InputWrap>
        <div className='label'>
          <label className='custom-label'>UNLISTABLE ASIN BLACKLIST</label>
        </div>
        <div className='input-icon-wrapper'>
          <textarea
            ref={asinRef}
            name='multicolumn'
            className='search-input no-search-icon type_textarea'
            placeholder='Insert ASIN number'
            value={asin}
            onChange={(e) => {
              setAsin(e.target.value)
              asinRef.current.focus()
            }}
          />
          <div className='clear-icon' onClick={() => setAsin('')}>
            <RiCloseLine color={'#a1a5bd'} fontSize={18} />
          </div>
          <SaveButtonContainer>
            <SaveButton
                onClick={(e) => _saveItems(FilterTypes.Asin, asin)}
            >
              Save
            </SaveButton>
          </SaveButtonContainer>
        </div>
      </InputWrap>
      {asins?.length ? (
        <KeywordTable
          className=''
          data={asins}
          title='asin'
          removeRow={_removeKeyword}
          filterType={FilterTypes.Asin}
        />
      ) : null}
      <hr className='my-15' />
      {/*<MultiRangeSlider*/}
      {/*  label='BLACKLIST BASED ON THE WEIGHT'*/}
      {/*  min={0}*/}
      {/*  max={1000}*/}
      {/*  minValue={weightRange?.from || 0}*/}
      {/*  maxValue={weightRange?.to || 0}*/}
      {/*  onChange={(obj: Range) => manageParentRanges(obj, PropertyTypes.Weight)}*/}
      {/*  unit='OUNCES'*/}
      {/*/>*/}
      {/*<div className='d-flex flex-column fs-5 info-message'>*/}
      {/*  <span>{`${weightRange.productCount} items were blacklisted`}</span>*/}
      {/*  <span>{`Updated ${moment(new Date(weightRange?.updateDate || new Date())).format(*/}
      {/*    'l'*/}
      {/*  )} ${moment(new Date(weightRange?.updateDate || new Date())).format('LT')}`}</span>*/}
      {/*</div>*/}
      {/*<MultiRangeSlider*/}
      {/*  label='BLACKLIST BASED ON THE VOLUME'*/}
      {/*  min={0}*/}
      {/*  max={1000}*/}
      {/*  minValue={volumeRange?.from || 0}*/}
      {/*  maxValue={volumeRange?.to || 0}*/}
      {/*  onChange={(obj: Range) => manageParentRanges(obj, PropertyTypes.Volume)}*/}
      {/*  unit='INCHES'*/}
      {/*/>*/}
      {/*<div className='d-flex flex-column fs-5 info-message'>*/}
      {/*  <span>{`${volumeRange.productCount} items were blacklisted`}</span>*/}
      {/*  <span>{`Updated ${moment(new Date(volumeRange?.updateDate || new Date())).format(*/}
      {/*    'l'*/}
      {/*  )} ${moment(new Date(volumeRange?.updateDate || new Date())).format('LT')}`}</span>*/}
      {/*</div>*/}
      {/*<MultiRangeSlider*/}
      {/*  label='BLACKLIST BASED ON THE PRICE'*/}
      {/*  min={0}*/}
      {/*  max={1000}*/}
      {/*  minValue={priceRange?.from || 0}*/}
      {/*  maxValue={priceRange?.to || 0}*/}
      {/*  onChange={(obj: Range) => manageParentRanges(obj, PropertyTypes.Price)}*/}
      {/*  unit='USD'*/}
      {/*/>*/}
      {/*<div className='d-flex flex-column fs-5 info-message'>*/}
      {/*  <span>{`${priceRange.productCount} items were blacklisted`}</span>*/}
      {/*  <span>{`Updated ${moment(new Date(priceRange?.updateDate || new Date())).format(*/}
      {/*    'l'*/}
      {/*  )} ${moment(new Date(priceRange?.updateDate || new Date())).format('LT')}`}</span>*/}
      {/*</div>*/}
      <hr className='my-8' />
      <h2>{`TOTAL AMOUNT OF PRODUCTS:  ${totalCount}`}</h2>
    </Container>
  )
}

export { BlackListing }

const Container = styled.div`
  .header-two {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .info-message {
    color: #a1a5bd;
    margin-top: 16px;
  }

  .brands-select-wrapper {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
  }

  .brands-select {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .search-icon {
    position: absolute;
    top: 16px;
    left: 12px;
    cursor: pointer;
    z-index: 4;
  }

  .custom-label {
    min-width: 270px;
    font-size: 14px;
    font-weight: 600;
  }
`

const InputWrap = styled.div`
  //margin-top: 60px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: top;
  white-space: nowrap;
  width: 80%;

  .label {
    display: flex;
    justify-content: start;
    min-width: 270px;
  }

  .input-icon-wrapper {
    position: relative;
    width: 100%;
  }

  .icon-wrap {
    position: absolute;
    top: 15px;
    left: 11px;
  }

  .search-input {
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #cdcfda;
    padding: 11px 8px;
    outline: none;
    color: #a1a5bd;
    text-indent: 25px;
    width: 100%;
    color: #3f4254;
    &.type_textarea {
      text-indent: 0px;
      min-height: 200px;
    }
  }

  .search-input:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 3px #c0e7fd;
  }

  .clear-icon {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }
`

const SaveButtonContainer = styled.div`
  text-align: right;
  margin-top: 5px;
`
const SaveButton = styled.div`
  cursor: pointer;
  display: inline-block;
  background: #7e7979;
  padding: 8px;
  border-radius: 10px;
  color: #FFF;
`
