import { SetStateAction, useState } from 'react'

import { importProductsApis } from '../../setup/apis/product/importProductsApis'
import { ListTypes } from '../layger/filters/importProductsFilters'
import { filterItems } from './useWhiteBlackListSelect'

function useRemoveFilter<T>({
  storeId,
  setWhiteList,
  setBlackList,
  filterCallback,
}: {
  storeId: number
  setWhiteList: (val: SetStateAction<T[]>) => void
  setBlackList: (val: SetStateAction<T[]>) => void
  filterCallback?: (data: T[], value: string) => T[]
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function remove(listType: string, filterType: string, value: string) {
    try {
      setIsLoading(true)

      await importProductsApis.removeStringFilter([
        {
          storeId,
          listType,
          filterType,
          value,
        },
      ])

      const filterFunc = filterCallback ?? filterItems

      listType === ListTypes.WhiteList
        ? setWhiteList((prevData) => filterFunc(prevData, value))
        : setBlackList((prevData) => filterFunc(prevData, value))
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  return { isLoading, remove }
}

export default useRemoveFilter
