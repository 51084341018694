import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListByTab } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import RemoveButton from '../../../components/RemoveButton'
import AddListModal from '../../../components/AddListModal'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import { StyledDiv90 } from '../Onboarding.styles'

const OnboardingInventoryBrands = () => {
    const storeOptions = useSelector(activeStoreSelector)

    const [show, setShow] = useState(false)
    const [selectedId, setSelectedId] = useState('')
    const [tab, setTab] = useState<'white' | 'black'>('white')
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])

    const { isLoading, getBrands } =
        useGetStoreFilterSettings<string>('brandWhitelist', 'brandBlacklist', selectedStore?.storeId)

    const { isLoading: addIsLoading, addFilter } = useAddFilter(
        selectedStore?.storeId,
        setShow,
        getBrands
    )

    useEffect(() => {
        // if (selectedStore) getBrands()
    }, [selectedStore?.storeId])

    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        setSearchValue(value)
    }

    // const tableData = useMemo(() => {
    //     const list = tab === 'white' ? brands['whiteList']: brands["blackList"]
    //
    //     // return list
    //     //     ?.filter((el) => filterBySearchValue(el, searchValue))
    //     //     .map((item) => ({
    //     //         name: item,
    //     //         button: (
    //     //             <RemoveButton
    //     //                 value={item}
    //     //                 selectedId={selectedId}
    //     //                 listType={ListByTab[tab]}
    //     //                 filterType={FilterTypes.Brand}
    //     //                 storeId={selectedStore?.storeId}
    //     //                 setSelectedId={setSelectedId}
    //     //                 setWhiteList={(val) => {
    //     //                     // @ts-ignore
    //     //                     setBrands(prevCategory => ({
    //     //                         ...prevCategory,
    //     //                         whiteList: val
    //     //                     }));
    //     //                 }}
    //     //                 setBlackList={(val) => {
    //     //                     // @ts-ignore
    //     //                     setBrands(prevCategory => ({
    //     //                         ...prevCategory,
    //     //                         blackList: val
    //     //                     }));
    //     //                 }}
    //     //             />
    //     //         ),
    //     //     }))
    // }, [tab, brands, searchValue])

    return (
        <StyledDiv90>
            <CustomHeader marginl='0'>Brand List</CustomHeader>


            <Tabs
                tabData={[
                    { title: 'Allowed', value: 'white' },
                    { title: 'Blocked', value: 'black' },
                ]}
                tab={tab}
                setTab={setTab}
            />

            <SearchBar handleChange={handleSearchValueChange} page='Brands' />

            <View isLoading={isLoading}>
                {/*<InventoryTable tHeader={['Brand List', '']} data={tableData} />*/}
            </View>

            <AddListModal
                show={show}
                page='brand'
                setShow={setShow}
                addFilter={addFilter}
                isLoading={addIsLoading}
                listType={ListByTab[tab]}
                selectedStore={selectedStore}
                filterType={FilterTypes.Brand}
                handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
            />
        </StyledDiv90>
    )
}

export default OnboardingInventoryBrands
