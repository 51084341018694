import React, {Suspense, useEffect} from 'react'
import LogRocket from 'logrocket';
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {tokenSelector} from '../app/modules/selectors/reduxSelectors'
import {useSelector} from "react-redux";
import {accountApis} from "../setup/apis/account/accountApis";
import {getUserId} from "./utils/authUtils";
import { ProductFruits } from 'react-product-fruits';

type Props = {
  basename: string
}

// type ProductFruitsUserInfo = {
//   username: string,
//   email: string,
//   firstname: string,
//   lastname: string,
// }

interface MyWindow extends Window {
  helpjuice_account_url: any;
  helpjuice_contact_us_url: any;
  helpjuiceSwiftyConfig: any;
  helpjuiceSwiftyUrlMap: any;
}

declare let window: MyWindow;

const App: React.FC<Props> = ({basename}) => {

  const token = useSelector(tokenSelector)

  useEffect(() => {
    LogRocket.init('svcpuc/marketgap');
    if(token && token.length) {

    } else {
    }
  }, [token])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
