import { Modal } from 'react-bootstrap-v5'
import styled from 'styled-components'

import { StoreType } from '../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary } from './CustomStyledComponents'
import { pluralize } from '../utils/helperUtils'
import StoreSelect from './StoreSelect'
import React, { useState } from 'react'

interface IProps {
  show: boolean
  page: string
  isLoading?: boolean
  selectedStore: StoreType
  filterType: string
  listType: string
  setShow: (val: boolean) => void
  handleStoreSelect: (option: StoreType) => void
  addFilter: (listType: string, filterType: string, value: string) => void
}

const AddListModal = ({
  show,
  page,
  setShow,
  isLoading,
  selectedStore,
  handleStoreSelect,
  addFilter,
  filterType,
  listType,
}: IProps) => {
  const [value, setValue] = useState('')
  const [lineCount, setLineCount] = useState(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    const lines = inputValue.split('\n');
    if (lines.length <= 1000) {
      setLineCount(lines.length);
      setValue(inputValue);
    }
  };


    const disableButton = lineCount > 1000;

  return (
    <div>
      <Modal show={show}>
        <Container>
          <div>
            <h5 className='m-0 py-6 padding-x'> Add {pluralize(page)}</h5>
            <hr className='m-0 hr-color' />

            <div className='padding-x'>
              <p className='mt-4 mb-3'>Enter one {page} per a line</p>
              <textarea
                  onChange={handleInputChange}
                  className='textarea'
                  rows={lineCount > 0 ? lineCount : 1}
              ></textarea>
              {lineCount > 1000 && <p className='textarea-info'>More than 1000 rows entered</p>}
              <p className='textarea-info'>You can enter up to 1,000 {pluralize(page)} at a time</p>
            </div>

            <hr />

            <div className='padding-x'>
              <h5 className='mb-3'>Select which marketplace you want to add</h5>
              <StoreSelect
                selectedStore={selectedStore}
                handleSelect={handleStoreSelect}
                width='100%'
              />
            </div>
            <hr />

            <div className='padding-x'>
              <div className='info-alert'>
                Important: If you have duplicate records, only one will be saved.
              </div>
            </div>
            <hr />

            <div className='padding-x buttons'>
              <ButtonPrimary
                paddingx={22}
                paddingy={9}
                fontSize={13}
                bgColor='#037FC5'
                disabled={isLoading || !value || !selectedStore || disableButton}
                onClick={() => addFilter(listType, filterType, value)}
              >
                Add to list
                {isLoading && (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                )}
              </ButtonPrimary>
              <button
                onClick={() => setShow(false)}
                className='btn btn-sm btn-color-primary btn-active-light-primary close-btn'
              >
                Cancel
              </button>
            </div>
          </div>
        </Container>
      </Modal>
    </div>
  )
}

const Container = styled.div`
  .padding-x {
    padding: 0 1.5rem;
  }

  hr {
    height: 2px;
    background-color: #d2ddec;
  }

  .textarea {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #d2ddec;
    min-height: 230px;
    resize: none;
    outline: none;
    padding: 8px;
  }

  .textarea-info {
    font-size: 12px;
    color: #5f708a;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  .info-alert {
    padding: 25px;
    background: #f6c343;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin: 2rem 0;
  }

  .buttons {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .close-btn {
    margin-left: 10px;
    padding: 11px 28px !important;
  }
`

export default AddListModal
