import React, { FC, useState } from 'react'

type Props = {
  title?: string
  content?: string
}

const items = [
  {
    title: 'Does MarketGap handle order fulfillment?',
    details: 'No, MarketGap primarily lists items for you. When a customer places an order in your store, you\'ll need to purchase the item, have it delivered to a US address, and arrange shipping using your chosen carrier.',
  },
  {
    title: 'How quickly are price and stock updates reflected?',
    details: 'MarketGap submits pricing and inventory changes every 20 minutes when detected on Amazon.com. However, Amazon\'s processing times may vary, sometimes taking up to 24 hours, depending on the number of items and file size. This delay is beyond MarketGap\'s control.',
  },
  {
    title: 'Is MarketGap suitable for beginners?',
    details: 'Yes, MarketGap is user-friendly. However, there\'s a learning curve to mastering Amazon selling, adjusting settings, and understanding market nuances, including shipping, policies, product restrictions, legal requirements, and tax regulations unique to each marketplace.',
  },
  {
    title: 'What do I need before using MarketGap?',
    details: 'Before getting started, ensure you have:',
    items: [
        'A chosen Amazon Marketplace.',
        'A US address capable of receiving Amazon.com deliveries.',
        'An active Amazon.com buyer account.',
        'An active Amazon seller account in your target selling country.',
        'A preferred shipping carrier with known shipping costs.',
        'An order management software that integrates with your carrier.',
    ],
    afterDetails: 'With these essentials in place, you\'re ready to harness the power of MarketGap for your online business.'
  },
]

export const FAQ: FC<Props> = ({ title, content }) => {
  const [expanded, setExpanded] = useState<Array<Boolean>>(Array(items.length).fill(false))

  const handleClick = (id: number) => {
    setExpanded((prev) =>
      prev.map((c, i) => {
        if (i !== id) return c
        else return !c
      })
    )
    return 
  }

  return (
    // <!-- FAQ section -->
    <section className='bg-white tw-py-20 lg:py-[107px]'>
      <div className='mx-auto w-5/6 max-w-[1700px]'>
        <h2 className="text-center text-[35px] font-bold font-['Inter'] leading-9 lg:leading-[43.05px]">
          Frequently Asked Questions
        </h2>
        <p className="mt-[15px] text-center text-slate-500 text-lg font-normal font-['Inter'] leading-6 lg:leading-7">
        </p>
        {/* <!-- FAQ grid --> */}
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-[71px] mt-[84px]'>
          {/* <!-- Accordion --> */}
          <div className='flex flex-col gap-[15px]'>
            {items.map((item, index) => (
              <div onClick={() => handleClick(index)} className='cursor-pointer w-full px-[25px] py-[35px] bg-slate-50 rounded-[20px] inline-flex flex-col justify-center items-start gap-[13px]'>
                <div className='cursor-pointer w-full flex gap-[18px] justify-between items-center'>
                  <div className=' flex-none w-[45px] h-[45px] bg-slate-50 rounded-full border-2 border-indigo-300 border-opacity-20 inline-flex justify-center items-center gap-2.5 '>
                    <img className='w-[17px] h-[9px]' src='./assets/icons/vector.svg' />
                  </div>
                  <div className="flex-1 text-slate-800 text-lg font-medium font-['Inter'] cursor-pointer">
                    {item.title}
                  </div>
                  <div
                    onClick={() => handleClick(index)}
                    className='cursor-pointer flex-none w-[14px] h-[10px]'
                  >
                    {expanded[index] && (
                      <img className='w-[14px] h-[10px]' src='./assets/icons/chevron-up.svg' />
                    )}
                    {!expanded[index] && (
                      <img className='w-[10px] h-[15px]' src='./assets/icons/chevron-right.svg' />
                    )}
                  </div>
                </div>
                {/* <!-- Expanded text --> */}
                {expanded[index] && (
                  <div  className="cursor-pointer text-blue-950 text-[15px] font-normal font-['Inter'] leading-5 xl:leading-[25px]">
                    <div>{item.details}</div>
                    {item.items && (
                      <ul className="list-disc list-inside list_style_circle">
                        {item.items.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    )}
                    {item.afterDetails && (
                      <div>{item.afterDetails}</div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* <!-- Image --> */}
          <div className='lg:ml-[46px] lg:mr-[-46px]'>
            <img className='' src='./assets/img/illustrations/FAQ.png' />
          </div>
        </div>
      </div>
    </section>
  )
}
