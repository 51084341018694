import { FC, useEffect } from 'react';
import styled from 'styled-components'
import { PricingSettings } from './PricingSettings'
import { ShippingSettings } from './ShippingSettings'
import { AdditionalSettings } from './AdditionalSettings'
import { useState } from 'react'
import Select from '../../../components/Select'
import { Review } from './Review'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import {
  CurrencyType,
  StoreType,
} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonSecondary } from '../../../components/CustomStyledComponents'
import { FaChevronLeft } from 'react-icons/fa'
import { StoreDetails } from './StoreDetails'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { currencyApis } from '../../../../setup/apis/currency/currencyApis'
import { pricingPointApis } from '../../../../setup/apis/pricing-point/pricingPointApis'
import {useParams} from "react-router-dom";
import {PricingSettingsV2} from "./PricingSettingsV2";
import {PricingMarkupType} from "../../../../setup/types/request-data-types/RequestDataTypes";
import {storeSettingsApis} from "../../../../setup/apis/store/storeSettingsApis";

type Props = {}

export const StoreSettingsTabs = {
  details: 'Store Details',
  pricing: 'Pricing Markup Settings',
  shipping: 'Shipping Pricing Settings',
  additional: 'Additional Settings',
  review: 'Summary Review',
}

const { pricing, shipping, additional, review, details } = StoreSettingsTabs

const StoreSettings: FC<Props> = () => {
  const header = 'Store Settings'
  const info = 'Here user will set his store settings'
  const storeOptions = useSelector(activeStoreSelector)
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyType[]>([])
  const [pricingPointOptions, setPricingPointOptions] = useState<CurrencyType[]>([])
  const [tab, setTab] = useState<string>(details)
  const [pricingMode, setPricingMode] = useState<string>('markup')
  const [pricingMarkup, setPricingMarkup] = useState<PricingMarkupType>()
  const history = useHistory<any>()
  const params: { storeId: string} = useParams();


  useEffect(() => {
    getCurrencies()
  }, [])

  useEffect(() => {
    if (storeOptions.length) {
      let selectedStoreId: number;
      if (params.storeId) {
        selectedStoreId = parseInt(params.storeId);
      } else if (sessionStorage.getItem('storeId')) {
        selectedStoreId = parseInt(sessionStorage.getItem('storeId') ?? '0');
        history.push(`/inventory/brands/${storeOptions.find(item => item.storeId === selectedStoreId)?.storeId}`);
      } else {
        selectedStoreId = storeOptions[0].storeId;
      }
      const selectedStore = storeOptions.find(item => item.storeId === selectedStoreId) || storeOptions[0];
      handleStoreChange(selectedStore);
      setTab(pricing);
    }
  }, [storeOptions]);


  async function getCurrencies() {
    const response: string[] = await currencyApis.getAll()
    setCurrencyOptions(response?.map((item) => ({ value: item, label: item } || [])))
  }

  async function getPricingPoints() {
    const response: string[] = await pricingPointApis.getAll()
    setPricingPointOptions(response?.map((item) => ({ value: item, label: item } || [])))
  }

  function _onTabChanged(tabName: string) {
    setTab(tabName)
  }

  const handleStoreChange = (store: any) => {
    setSelectedStore(store);
    storeSettingsApis.getPricingSettings(store.storeId).then(res => {
      setPricingMarkup(res)
    })

    sessionStorage.setItem('storeId', String(store.storeId));
    history.push(`/stores/store-settings/${store.storeId}`);
  };

  return (
    <Container>
      <SelectContainer>
        <label className='form-label fw-bolder'>Select Store</label>
        <Select
          name='layout-builder[layout][header][width]'
          value={selectedStore}
          onChange={(option) => handleStoreChange(option)}
          options={storeOptions}
          withRs
        />
      </SelectContainer>
      <div className='body-container'>
        <MenuWrapper>
          <div className='d-flex overflow-auto h-45px tabs'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x  fs-5 fw-bolder flex-nowrap justify-content-between'>
              <li className='nav-item' onClick={() => _onTabChanged(details)}>
                <span
                  className={`nav-link text-active-primary me-6 ${tab === details && 'active'}`}
                >
                  {details}
                </span>
              </li>
              <li className='nav-item' onClick={() => _onTabChanged(pricing)}>
                <span
                  className={`nav-link text-active-primary me-6 ${tab === pricing && 'active'}`}
                >
                  {pricing}
                </span>
              </li>
              <li className='nav-item' onClick={() => _onTabChanged(shipping)}>
                <span
                  className={`nav-link text-active-primary me-6 ${tab === shipping && 'active'}`}
                >
                  {shipping}
                </span>
              </li>
              <li className='nav-item' onClick={() => _onTabChanged(additional)}>
                <span
                  className={`nav-link text-active-primary me-6 ${tab === additional && 'active'}`}
                >
                  {additional}
                </span>
              </li>
              <li className='nav-item' onClick={() => _onTabChanged(review)}>
                <span className={`nav-link text-active-primary me-6 ${tab === review && 'active'}`}>
                  {review}
                </span>
              </li>
            </ul>
          </div>
          {history?.location?.state?.from === 'stores' ? (
            <ButtonSecondary
              className='button-wrapper'
              onClick={() => history.push('/stores/store-list')}
            >
              <FaChevronLeft />
              Back to Stores
            </ButtonSecondary>
          ) : null}
        </MenuWrapper>
        <hr className='custom-hr' />
        {tab === details && <StoreDetails storeId={selectedStore?.storeId || 0} />}
        {tab === pricing && (
          <>
            {pricingMarkup?.table?.inputPricingPoint === 'BasePricePlusShipping'
                ?
                (<PricingSettingsV2
                  storeId={selectedStore?.storeId || 0}
                  className='mb-5 mb-xl-8'
                  currencyOptions={currencyOptions}
                  pricingPointOptions={pricingPointOptions}
                  pricingMarkup={pricingMarkup}
                  />)
                :
                (<PricingSettings
                    storeId={selectedStore?.storeId || 0}
                    className='mb-5 mb-xl-8'
                    currencyOptions={currencyOptions}
                    pricingPointOptions={pricingPointOptions}
                    pricingMarkup={pricingMarkup}
                />)
            }
          </>
        )}
        {tab === shipping && (
          <ShippingSettings
            storeId={selectedStore?.storeId || 0}
            selectedStore={selectedStore}
            className='mb-5 mb-xl-8'
            currencyOptions={currencyOptions}
          />
        )}
        {tab === additional && <AdditionalSettings storeId={selectedStore?.storeId || 0} />}
        {tab === review && (
          <Review storeId={selectedStore?.storeId || 0} changeTo={_onTabChanged} storeCurrency={selectedStore?.currencyCode}/>
        )}
      </div>
    </Container>
  )
}

export default StoreSettings

const Container = styled.div`
  margin-bottom:48px;
  .body-container {
    min-width: 1200px;
    // overflow-x: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  }

  .tabs {
    margin: 16px 0 0 16px;
    cursor: pointer;
  }

  .custom-hr {
    margin: 0;
  }
`

export const SelectContainer = styled.div`
  margin: 0 0 16px 3px;
  display: flex;
  flex-direction: column;
  label {
    font-size: 14px;
    white-space: nowrap;
    margin-right: 30px;
    margin-top: 6px;
  }
  
  &.align-bottom {
    align-self: self-end; 
  }
  
  .store-select:focus {
    outline: none;
    border: 1px solid #009ef7;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }
  
  &.currency-select-additional-settings {
    input {
      font-size: 12px;
      padding-block: 8px;
      width: 150px;
      margin-inline-end: 16px;
    }
  }
  
  &.currency-select-additional-settings-dropdown {
    width: 25%;
    margin-inline-end: 16px;
  }
`

const MenuWrapper = styled.div`
  display: flex;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #ffffff;
  padding: 10px 10px 0 10px;
  align-items: flex-end;
  justify-content: space-between;

  .button-wrapper {
    margin: 16px 24px 24px 24px;
  }
`
