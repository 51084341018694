import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { twj } from 'tw-to-css';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

type Props = {
    title?: string;
    content?: string;
    theme?: 'light' | 'dark';
    homeHandler: () => void;
    featuresHandler: () => void;
    pricingHandler: () => void;
};



export const MobileMenu: FC<Props> = ({ title, content, theme, homeHandler, featuresHandler, pricingHandler }) => {
    const history = useHistory()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const navigation = [
        { name: 'Home', onclick: () => { homeHandler(); setMobileMenuOpen(false) } },
        { name: 'Features', onclick: () => { featuresHandler(); setMobileMenuOpen(false)} },
        { name: 'Pricing',  onclick: () => { pricingHandler(); setMobileMenuOpen(false)}},
        { name: 'Get started', onclick: () => history.push('/registration/create-account') },
      ]

    return (<>
        {/* <!-- Mobile menu prototype --> */}
        <div className='flex lg:hidden'>
        <button
          type='button'
          onClick={() => setMobileMenuOpen(true)}
          className={'cursor-pointer -m-2.5 p-2.5 inline-flex items-center justify-center rounded-m text-gray-700'}
          aria-controls='mobile-menu'
          aria-expanded='false'
        >
          <span className='sr-only'>Open menu</span>
          {/* <!-- Heroicon name: outline/menu --> */}
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu open state. --> */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div style={twj("fixed inset-0 z-50")} />
          <Dialog.Panel style={twj("fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10")}>
            <div className="flex items-center justify-between">
              <a onClick={navigation[0].onclick} className="-m-1.5 p-1.5">
                <span className="sr-only">MarketGap</span>
                <img
                  className="h-8 w-auto"
                  src="/assets/img/MarketGap-logo.png"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div style={twj("mt-6 flow-root")}>
              <div style={twj("-my-6")}>
                <div style={twj("space-y-2 py-6")}>
                  {navigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={item.onclick}
                      style={twj("-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900")}
                      className='hover:bg-gray-50'
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
                <div style={twj("py-6 divide-y divide-gray-500/10")}>
                  <button
                    onClick={() => history.push('/auth/login')}
                    style={twj("-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900")}
                    className='hover:bg-gray-50'
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
   
      </>
    )
}