import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { StoreType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import useGetStoreFilterSettings from '../../../hooks/useGetStoreFilterSettings'
import { activeStoreSelector } from '../../../modules/selectors/reduxSelectors'
import { FilterTypes, ListByTab } from '../../filters/importProductsFilters'
import { filterBySearchValue } from '../../../utils/helperUtils'
import InventoryTable from '../../../components/InventoryTable'
import RemoveButton from '../../../components/RemoveButton'
import AddListModal from '../../../components/AddListModal'
import StoreSelect from '../../../components/StoreSelect'
import useAddFilter from '../../../hooks/useAddFilter'
import SearchBar from '../../../components/SearchBar'
import { TabTypes } from '../../../utils/types'
import Tabs from '../../../components/Tabs'
import View from '../../../components/View'
import {StyledDiv, StyledDiv90} from "../Onboarding.styles";

export interface Item {
    categoryId: string
    categoryName: string
    parentCategoryName: string
    parentCategoryId: string
    childCategoryCount: number
    productCount: number
    totalProductCount: number
    value: string
}

const OnboardingInventoryCategories = () => {
    const storeOptions = useSelector(activeStoreSelector)

    const [show, setShow] = useState(false)
    const [tab, setTab] = useState<TabTypes>('white')
    const [selectedId, setSelectedId] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])

    const { isLoading, getCategories, category, setCategory } =
        useGetStoreFilterSettings<Item>(
            'categotyWhiteList',
            'categotyBlackList',
            selectedStore?.storeId,
            true
        )

    const { isLoading: addIsLoading, addFilter } = useAddFilter(
        selectedStore?.storeId,
        setShow,
        getCategories
    )

    useEffect(() => {
        if (!!selectedStore) {
            getCategories()
        }
    }, [selectedStore?.storeId])

    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        setSearchValue(value)
    }

    const tableData = useMemo(() => {
        const list = tab === 'white' ? category["whiteList"] : category["blackList"]

        return list
            ?.filter((el) => filterBySearchValue(el.categoryName, searchValue))
            .map((item) => ({
                name: item.categoryName,
                path: item.parentCategoryName,
                button: (
                    <RemoveButton<Item>
                        selectedId={selectedId}
                        value={item.categoryName}
                        setWhiteList={(val) => {
                            // @ts-ignore
                            setCategory(prevCategory => ({
                                ...prevCategory,
                                whiteList: val
                            }));
                        }}
                        setBlackList={(val) => {
                            // @ts-ignore
                            setCategory(prevCategory => ({
                                ...prevCategory,
                                blackList: val
                            }));
                        }}
                        setSelectedId={setSelectedId}
                        listType={ListByTab[tab]}
                        storeId={selectedStore?.storeId}
                        filterType={FilterTypes.Category}
                        filterCallback={(list: Item[], value: string) =>
                            list.filter((item) => item.categoryName !== value)
                        }
                    />
                ),
            }))
    }, [tab, category, searchValue])

    return (
        <StyledDiv90>
            <CustomHeader marginl='0'>Category List</CustomHeader>

            <Tabs
                tab={tab}
                setTab={setTab}
                tabData={[
                    { title: 'Allowed', value: 'white' },
                    { title: 'Blocked', value: 'black' },
                ]}
            />

            <SearchBar handleChange={handleSearchValueChange} page='Categories' />

            <InventoryTable tHeader={['Category Lists', 'Path', '']} data={tableData} />

            <View isLoading={isLoading}>
                <AddListModal
                    show={show}
                    page='category'
                    setShow={setShow}
                    addFilter={addFilter}
                    isLoading={addIsLoading}
                    listType={ListByTab[tab]}
                    selectedStore={selectedStore}
                    filterType={FilterTypes.Category}
                    handleStoreSelect={(option: StoreType) => setSelectedStore(option)}
                />
            </View>
        </StyledDiv90>
    )
}

export default OnboardingInventoryCategories
