import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {activeStoreSelector} from "../../../modules/selectors/reduxSelectors";
import {StoreType} from "../../../../setup/types/response-data-types/ResponseDataTypes";
import {CustomHeader} from "../../../components/CustomStyledComponents";
import StoreSelect from "../../../components/StoreSelect";
import SearchBar from "../../../components/SearchBar";
import BrandsList from "../../discover/brands/BrandsList";


export interface Brand {
    runId: number
    name: string
    categoryName: string | null
    itemCount: number
    qualifiedCount: number
    disqualifiedCount: number
    executed: boolean
}

const OnboardingBrands = () => {
    const storeOptions = useSelector(activeStoreSelector)
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])

    function handleSearchValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        setSearchValue(value)
    }

    return (
        <div>
            <CustomHeader marginl='0'>Discover Brands</CustomHeader>
            <hr />

            <SearchBar handleChange={handleSearchValueChange} page='Brands' />
            <BrandsList searchValue={searchValue} selectedStore={selectedStore} />
        </div>
    )
}

export default OnboardingBrands
