/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {SubscriptionPlan} from '../../layger/registration/SubscriptionPlan'
import {ForgotPassword} from './components/ForgotPassword'
import {ResetPassword} from './components/ResetPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import styled from 'styled-components'
import {EmailConfirmation} from '../../layger/account-creation/EmailConfirmation'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <Container
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/progress-hd.png')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-5 p-md-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/assets/img/MarketGap-logo.png')} className='h-35px' />
          {/* <h1 style={{fontSize: '30px'}}>Market Gap</h1> */}
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-full w-sm-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/confirm-email' component={EmailConfirmation} />
            <Route path='/auth/reset-password' component={ResetPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <Link to='/public' className='custom-link text-hover-primary px-2'>
            Home
          </Link>
          <Link to='/contact-us' className='custom-link text-hover-primary px-2'>
            Contact Us
          </Link>
        </div>
      </div>
      {/* end::Footer */}
    </Container>
  )
}

const Container = styled.div`
  min-width: 300px;
  background-color: #f3faff;
  .custom-link {
    font-weight: 500;
    color: #cdcfda;
  }
`
