export enum OnboardingEnum {
    STORES_LIST = 1,
    STORE_DETAILS = 2,
    PRICING_MARKUP = 3,
    SHIPPING_PRICING = 4,
    ADDITIONAL_SETTINGS = 5,
    CATEGORIES = 6,
    BRANDS = 7,
    INVENTORY_BRANDS = 8,
    INVENTORY_CATEGORIES = 9,
    INVENTORY_ASINS = 10,
    INVENTORY_RESTRICTED_KEYWORDS = 11,
    INVENTORY_PHRASES = 12,
    ADDED_STOP = 13
}
