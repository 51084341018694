import {useEffect, useState} from "react";
import {CurrencyType, StoreType} from "../../../../setup/types/response-data-types/ResponseDataTypes";
import {ShippingItemType, ShippingType} from "../../../../setup/types/request-data-types/RequestDataTypes";
import {storeSettingsApis} from "../../../../setup/apis/store/storeSettingsApis";
import Input from "../../../components/Input";
import {FaPlus, FaTrash} from "react-icons/fa";
import {triggerAlert} from "../../../components/Alert";
import {ButtonPrimary, CustomHeader} from "../../../components/CustomStyledComponents";
import {SelectContainer} from "../../stores/store-settings/StoreSettings";
import Select from "../../../components/Select";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import styled from "styled-components";

type Props = {
    storeId: number
    className: string
    data?: Array<object>
    currencyOptions: CurrencyType[]
    selectedStore: StoreType
}

export type ShippingRow = {
    fromVal: {
        val: number | ''
    }
    toVal: {
        val: number | ''
        invalid: boolean
    }
    fromWeight: {
        val: number | ''
    }
    toWeight: {
        val: number | ''
        invalid: boolean
    }
    perItem: {
        val: number | ''
        currency: string
    }
    perWeight: {
        val: number | ''
        currency: string
    }
}

type DefaultRow = {
    perItem: {
        val: number | ''
        currency: string
    }
    perWeight: {
        val: number | ''
        currency: string
    }
}

const blankMarkup: ShippingRow = {
    fromVal: { val: '' },
    toVal: { val: '', invalid: true },
    fromWeight: { val: '' },
    toWeight: { val: '', invalid: true },
    perItem: { val: '', currency: 'USD' },
    perWeight: { val: '', currency: 'USD' },
}

const defRow: DefaultRow = {
    perItem: { val: '', currency: 'USD' },
    perWeight: { val: '', currency: 'USD' },
}

const OnboardingShippingSettings: React.FC<Props> = ({ selectedStore, className, storeId, currencyOptions }) => {
    const [shippingRows, setShippingRows] = useState<ShippingRow[]>([blankMarkup])
    const [defaultRow, setDefaultRow] = useState<DefaultRow>(defRow)
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyType>(currencyOptions[0])

    useEffect(() => {
        getData()
    }, [storeId])

    async function getData() {
        const response: ShippingType = await storeSettingsApis.getShippingSettings(storeId)
        const markups = response?.storeShippingStepItems?.map((item: ShippingItemType) => ({
            fromVal: { val: item.purchasePriceFrom },
            toVal: { val: item.purchasePriceTo, invalid: false },
            fromWeight: { val: item.weightFrom },
            toWeight: { val: item.weightTo, invalid: false },
            perItem: { val: item.shippingPerItem, currency: response?.currency },
            perWeight: { val: item.shippingPerOunce, currency: response?.currency },
        }))

        setShippingRows((prev) => (markups?.length ? markups : [blankMarkup]))
        setSelectedCurrency(
            response?.currency
                ? { value: response?.currency, label: response?.currency }
                : { value: selectedStore.currencyCode, label: selectedStore.currencyCode }
        )
        setDefaultRow((prev) => ({
            perItem: {
                val: response?.defaultShippingPerItem,
                currency: response?.currency,
            },
            perWeight: {
                val: response?.defaultShippingPerOunce,
                currency: response?.currency,
            },
        }))
    }

    function _onChange({ target }: any, which: string, index: number) {
        setShippingRows((prev) =>
            prev.map((item: ShippingRow, i: number) => {
                if (
                    i === index &&
                    (which === 'fromVal' ||
                        which === 'toVal' ||
                        which === 'fromWeight' ||
                        which === 'toWeight' ||
                        which === 'perItem' ||
                        which === 'perWeight')
                ) {
                    if (target.name === 'currency') {
                        return { ...item, [which]: { ...item[which], [target.name]: target.value } }
                    }
                    if (which === 'toVal' || which === 'toWeight') {
                        const key = which === 'toVal' ? 'fromVal' : 'fromWeight'
                        return {
                            ...item,
                            [which]: {
                                ...item[which],
                                [target.name]: target.value,
                                invalid: +item[key].val >= +target.value,
                            },
                        }
                    }
                    if (which === 'fromVal' || which === 'fromWeight') {
                        const key = which === 'fromVal' ? 'toVal' : 'toWeight'
                        return {
                            ...item,
                            [which]: {
                                ...item[which],
                                [target.name]: target.value,
                            },
                            [key]: {
                                ...item[key],
                                invalid: target.value >= +item[key].val,
                            },
                        }
                    }
                    return {
                        ...item,
                        [which]: { ...item[which], [target.name]: target.value },
                    }
                }
                return item
            })
        )
    }

    function _onChangeDefault({ target }: any, which: string) {
        if (which === 'perItem' || which === 'perWeight') {
            setDefaultRow((prev) => ({
                ...prev,
                [which]: {
                    ...prev[which],
                    [target.name]: target.name === 'currency' ? target.value : target.value,
                },
            }))
        }
    }

    function onRowAdded(index: number) {
        setShippingRows((prev) => [...prev.slice(0, index + 1), blankMarkup, ...prev.slice(index + 1)])
    }

    function onRowDeleted(index: number) {
        setShippingRows((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
    }

    function getRows() {
        return shippingRows?.map((item, index) => (
            <tr key={index}>
                <td>
                    <div className='d-flex align-items-center'>
                        <Strong>FROM</Strong>
                        <TdContent className='d-flex justify-content-start input-group'>
                            <Input
                                id={`val${index}`}
                                type='number'
                                name='val'
                                value={item?.fromVal?.val}
                                placeholder='0.00'
                                onChange={(e) => {
                                    _onChange(e, 'fromVal', index)
                                }}
                                className=' form-control'
                                min={0}
                            />
                        </TdContent>
                        <Strong>TO</Strong>
                        <TdContent className='d-flex justify-content-start input-group'>
                            <Input
                                id={`val${index}`}
                                type='number'
                                name='val'
                                value={item?.toVal?.val}
                                placeholder='0.00'
                                onChange={(e) => {
                                    _onChange(e, 'toVal', index)
                                }}
                                className={` form-control ${item?.toVal?.invalid && `invalid`}`}
                                min={0}
                            />
                        </TdContent>
                    </div>
                </td>
                <td>
                    <div className='d-flex align-items-center'>
                        <Strong>FROM</Strong>
                        <TdContent className='d-flex justify-content-start input-group'>
                            <Input
                                id={`val${index}`}
                                type='number'
                                name='val'
                                value={item?.fromWeight?.val}
                                placeholder='0.00'
                                onChange={(e) => {
                                    _onChange(e, 'fromWeight', index)
                                }}
                                className=' form-control'
                                min={0}
                            />
                        </TdContent>
                        <Strong>TO</Strong>
                        <TdContent className='d-flex justify-content-start input-group'>
                            <Input
                                id={`val${index}`}
                                type='number'
                                name='val'
                                value={item?.toWeight?.val}
                                placeholder='0.00'
                                onChange={(e) => {
                                    _onChange(e, 'toWeight', index)
                                }}
                                className={` form-control ${item?.toWeight?.invalid && `invalid`}`}
                                min={0}
                            />
                        </TdContent>
                    </div>
                </td>
                <td>
                    <div className='d-flex align-items-center'>
                        <TdContent className='d-flex justify-content-start input-group'>
                            <Input
                                id={`val${index}`}
                                type='number'
                                name='val'
                                value={item?.perItem?.val}
                                placeholder='0.00'
                                onChange={(e) => {
                                    _onChange(e, 'perItem', index)
                                }}
                                className={` form-control`}
                                min={0}
                            />
                        </TdContent>
                    </div>
                </td>
                <td>
                    <div className='d-flex align-items-center'>
                        <TdContent className='d-flex justify-content-start input-group'>
                            <Input
                                id={`val${index}`}
                                type='number'
                                name='val'
                                value={item?.perWeight?.val}
                                placeholder='0.00'
                                onChange={(e) => {
                                    _onChange(e, 'perWeight', index)
                                }}
                                className={` form-control`}
                                min={0}
                            />
                        </TdContent>
                    </div>
                </td>
                <td>
                    <div className='d-flex align-items-center'>
                        <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={(e) => onRowAdded(index)}
                        >
                            <FaPlus color='#a1a5b7' />
                        </button>
                        <button
                            className={`btn btn-icon  btn-bg-light btn-active-color-danger btn-sm ${index === 0 && `invisible`
                            }`}
                            onClick={() => onRowDeleted(index)}
                            style={{ marginLeft: '20px' }}
                        >
                            {/* <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' /> */}
                            <FaTrash color='#a1a5b7' />
                        </button>
                    </div>
                </td>
            </tr>
        ))
    }

    async function applyChanges() {
        console.log(defaultRow);
        const markups: ShippingItemType[] = shippingRows.map((item) => ({
            purchasePriceFrom: item.fromVal.val || 0,
            purchasePriceTo: item.toVal.val || 0,
            weightFrom: item.fromWeight.val || 0,
            weightTo: item.toWeight.val || 0,
            shippingPerItem: item.perItem.val || 0,
            shippingPerOunce: item.perWeight.val || 0,
        }))
        const requestData: ShippingType = {
            storeShippingStepItems: markups,
            currency: selectedCurrency.value,
            defaultShippingPerItem: defaultRow.perItem?.val || 0,
            defaultShippingPerOunce: defaultRow.perWeight?.val || 0,
        }


        const response = await storeSettingsApis.updateShippingSettings(storeId, requestData)
        if (response?.status === 200) {
            triggerAlert('Changes saved successfully')
        } else {
            triggerAlert('Something went wrong...', 'error')
        }
    }

    function isInValid() {
        if (!shippingRows.length) return true
        return shippingRows.some((item) => item.toVal.invalid || item.toWeight.invalid)
    }

    return (
        <Container className={`card ${className}`}>
            {/* begin::Header */}
            <CustomHeader>Shipping Pricing Settings</CustomHeader>
            {/* end::Header */}
            {/* begin::Body */}
            <SelectContainer className='currency-select'>
                <label className='form-label fw-bold'>Select Currency</label>
                <Select
                    name='layout-builder[layout][header][width]'
                    value={selectedCurrency}
                    onChange={(option) => setSelectedCurrency(option)}
                    options={currencyOptions}
                    disabled={true}
                    withRs
                    placeholder='Select currency...'
                />
            </SelectContainer>
            <div className='card-body py-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='info-text'>
                        {/* <RiInformationFill fontSize={50} color='#3F4254' className='mb-1 me-3' /> */}
                        <img src={toAbsoluteUrl('/media/svg/misc/info.svg')} className='mb-1 me-3' />
                        <div><b>Pricing Rules:</b><br/>
                            Rules will be evaluated top to bottom and stops upon the first match.<br/>
                            From is <b>Inclusive</b> while To is <b>Exclusive</b>.</div>
                    </div>
                </div>
                {/* begin::Table container */}
                <div className='table-responsive table-wrapper'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead className='sticky-top bg-white'>
                        <tr className='fw-bolder text-muted' id={'ShippingMarkup'}>
                            <th className='max-w-100px'>Purchase Price (USD)</th>
                            <th className='min-w-140px'>Weight (Ounces)</th>
                            <th className='min-w-150px'>Shipping - Per Item</th>
                            <th className='min-w-150px'>Shipping - Per Ounce</th>
                            <th className='min-w-50px'>Actions</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {getRows()}
                        <tr>
                            <td colSpan={2}>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='info-text'>
                                        {/* <RiInformationFill fontSize={50} color='#3F4254' className='mb-1 me-3' /> */}
                                        <img src={toAbsoluteUrl('/media/svg/misc/info.svg')} className='mb-1 me-3' />
                                        <span>
                        These values will be default for items that won't fit the parameters above.
                      </span>
                                    </div>
                                    <span className='default'>DEFAULT</span>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <TdContent className='d-flex justify-content-start input-group'>
                                        <Input
                                            id={`val`}
                                            type='number'
                                            name='val'
                                            value={defaultRow?.perItem?.val}
                                            placeholder='0.00'
                                            onChange={(e) => {
                                                _onChangeDefault(e, 'perItem')
                                            }}
                                            className={` form-control`}
                                            min={0}
                                        />
                                    </TdContent>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex align-items-center default-div'>
                                    <TdContent className='d-flex justify-content-start input-group'>
                                        <Input
                                            id={`val`}
                                            type='number'
                                            name='val'
                                            value={defaultRow?.perWeight?.val}
                                            placeholder='0.00'
                                            onChange={(e) => {
                                                _onChangeDefault(e, 'perWeight')
                                            }}
                                            className={` form-control`}
                                            min={0}
                                        />
                                    </TdContent>
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            <hr className='custom-hr' />
            <div className='apply-button'>
                <ButtonPrimary onClick={applyChanges} disabled={isInValid()}>
                    Apply changes
                </ButtonPrimary>
            </div>
            {/* begin::Body */}
        </Container>
    )
}

export { OnboardingShippingSettings }

const Strong = styled.strong`
  margin-inline: 3px;
  font-size: 14px;
`

const Container = styled.div`
  .custom-hr {
    margin: 0;
  }

  .currency-select {
    margin: 0 0 24px 24px;
  }

  .relative {
    margin-top: 10px;
  }

  .to-left {
    margin-left: 5px;
    font-size: 14px;
  }

  .info-label {
    font-size: 14px;
    font-weight: 500;
    min-width: 400px;
    margin-bottom: 4px;
    color: #3F4254;
  }

  .setting-container {
    padding-block: 12px;
    width: 500px;
  }

  .setting-container {
    display: flex;
    flex-direction: column;
  }

  .input-value {
    border: 2px solid #e3e4ea;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
  }

  .input-value:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }

  .input-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-wrapper {
    // height: 43vh;
    // overflow: auto;
  }

  thead {
    z-index: 1;
  }

  .default {
    font-size: 14px;
    font-weight: 700;
  }

  .apply-button {
    margin: 24px;
  }

  .info-text {
    border-radius: 8px;
    background-color: #eff2f5;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    padding: 12px;
  }
`
const TdContent = styled.div`
  .invalid {
    color: tomato;
  }

  input {
    font-size: 14px;
    padding: 3px;
  }

  select {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    :focus {
      outline: none;
    }

    font-size: 14px;
    border: none;
    background-color: #d9f1fe;
  }
`
